
import React, {useState,useEffect} from 'react';
import axios from 'axios';  
import {Form,Dropdown} from 'semantic-ui-react';

  const AutoCompleteArea = (props) => {
    const [localStrProp] = useState(sessionStorage.getItem('newProperty')?JSON.parse(sessionStorage.getItem('newProperty')):'');
    const [editProperty,setEditProperty] = useState(localStorage.getItem('editProperty') ? JSON.parse(localStorage.getItem('editProperty')):'');
    const [editData] = useState(localStorage.getItem('editData') ? JSON.parse(localStorage.getItem('editData')):'');
  //const [Loader,setLoader] =useState(false);
 // const[autoCompStyle,setAutoCompStyle] = useState({display:'block'});
  const[areaMaster,setAreaMaster] = useState([]);
  const [enteredArea,setEnteredArea] = useState((localStrProp && localStrProp.PropertyDetails.formPropAdrsData.Address.AreaName) || (editData?editData.organizationDetails.address.areaName:''));
  const [enteredAreaCode,setEnteredAreaCode] = useState();
  const [areaTelephoneCode,setAreaTelephoneCode] = useState();
  const[orgDetails] = useState(sessionStorage.getItem('SessionOrgDetails') ? JSON.parse(sessionStorage.getItem('SessionOrgDetails')) : '');
  const [areaOptions, setAreaOptions] = useState([]);

  useState(()=>{
    setEditProperty(props.editProperty)
  },[props.editProperty])
      
  useEffect(()=>{
    props.cityCode &&
    axios.get(`${process.env.REACT_APP_LUCID_API_URL}GetAreaList`,{
      params: {
        'CityCode': props.cityCode, 
      }, 
        headers: { Authorization: `Bearer ${props.APIToken}`}},)
        .then((response)=>{
      //  console.log(response.data);
        setAreaMaster(response.data);   
        }).catch((error)=>{
          console.log(error);
          alert(error)
        });
 },[props.cityCode,props.APIToken]);
 


     useEffect (()=>{
      areaMaster.areas &&
      areaMaster.areas.forEach((item)=>{
     //   console.log(item);
        if(enteredArea === item.areaName) {
      // console.log('area map collecting- with entered area ',enteredArea, item.areaName);
        setEnteredAreaCode(item.areaCode);
        setAreaTelephoneCode(item.telephoneCode);
      }
      })
      areaMaster.areas && setAreaOptions( ()=>  areaMaster.areas.map((area,idx)=>{
        return ({
         key:idx,
         text:area.areaName ,
         value:area.areaCode, 
        }
        )   
      } 
    ) )
     },[enteredArea,areaMaster.areas])

     useEffect(()=>{
      enteredArea && enteredAreaCode &&
      props.areaHandler(enteredArea,enteredAreaCode,areaTelephoneCode);
    //  console.log(enteredArea)
    //  console.log('states area before props passing data - ' + enteredArea, enteredAreaCode, areaTelephoneCode);
     },[enteredAreaCode,enteredArea,areaTelephoneCode, props]);

     useEffect(()=>{
      // alert(editData && editData.organizationDetails.address.areaName)
       //alert(orgDetails && orgDetails.OrganizationDetails.Address.AreaName)
      orgDetails && 
      setEnteredArea((orgDetails && orgDetails.OrganizationDetails.Address.AreaName) || (editData && editData.organizationDetails.address.areaName)|| '')
      if(editProperty){
        setEnteredArea(editProperty.propertyDetails.address.areaName)
        
      }
      if(props.editProperty){
        setEnteredArea(props.editProperty.propertyDetails.address.areaName)
        
      }
      props.area && setEnteredArea(props.area);
    },[orgDetails,editData,editProperty,props.editProperty, props.area]);
     
  return ( 
           <> 
               <div>
               <Form.Group inline>
               <label style={{marginRight:'35px'}}>
                 Area
                </label> 
                <Dropdown style={{color:'black'}}
                placeholder={enteredArea} 
                search selection
                 options={areaOptions}
                 defaultValue={enteredArea} 
                       onChange={(e,data)=>{  
                        setEnteredArea(e.target.textContent); 
                        if(e.code === 'Enter'){ 
                          data.options.forEach(element => {
                              element.value === data.value && setEnteredArea(element.text)
                          });   
                      }   
                           }}
                       />      
      
    </Form.Group> 
            </div>
             
     </>
  )
    // else return <>
    //             <label>Area</label>
    //            </>;

}
   
  
  
export default AutoCompleteArea;