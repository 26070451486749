
import React, {useState,useEffect} from 'react';
import {Form,Input, Dropdown} from 'semantic-ui-react';
const AutoCompleteState = (props) => {
  const [editProperty,setEditProperty] = useState(localStorage.getItem('editProperty') ? JSON.parse(localStorage.getItem('editProperty')):'');
  const[orgDetails] = useState(sessionStorage.getItem('SessionOrgDetails') ? JSON.parse(sessionStorage.getItem('SessionOrgDetails')) : '');
  const [localStrProp] = useState(sessionStorage.getItem('newProperty')?JSON.parse(sessionStorage.getItem('newProperty')):'');
  
  //const [searchTerm, setSearchTerm] = useState("");
 // const[ stateMaster,setMaster] = useState(props.stateMaster);
  //const [Loader,setLoader] =useState(false);
  //const[autoCompStyle] = useState({display:'block'});
  const[cityMaster] = useState();
  const [editData] = useState(localStorage.getItem('editData') ? JSON.parse(localStorage.getItem('editData')):'');
  const[stateCode,setStateCode] = useState('');
  const[enteredstate,setEnteredState] = useState((orgDetails && orgDetails.OrganizationDetails.State )||(localStrProp && localStrProp.PropertyDetails.formPropAdrsData.Address.State)||'');
  const [ propsStateCityHandler] = useState(()=> props.StateCityHandler);
  const [stateOptions, setStateOptions] = useState([]);
   useEffect(()=>{
    if(editData) {
          setEnteredState(editData.organizationDetails.address.stateName);
          setStateCode(editData.organizationDetails.address.stateCode);
         // console.log('from state', editData.organizationDetails.address.stateName,editData.organizationDetails.address.stateCode )
        }
        if(editProperty){
          setEnteredState(editProperty.propertyDetails.address.stateName)
        }
       
   },[editData,editProperty])

   useEffect(()=>{
    setEditProperty(props.editProperty)
   },[props.editProperty])

 // useEffect(()=>{
     // props.stateMaster.states &&
      //setMaster(props.stateMaster);
     // setLoader(true); 
 // },[props.stateMaster]);

  //useEffect(()=>{
  //  props.stateMaster.states &&
  //  props.stateMaster.states.map((item)=>{
  //    enteredstate === item.stateName &&
  //    setStateCode(item.stateCode);
  //  })
  //},[enteredstate])

    useEffect(()=>{
      props.stateMaster.states &&
      props.stateMaster.states.forEach((item)=>{
        (enteredstate === item.stateName) && 
        setStateCode(item.stateCode);
      })
      props.stateMaster.states &&
      setStateOptions( ()=> props.stateMaster.states  
         .map((state,ids) => {    
          return ({
            key:ids,
            text:state.stateName,
            value: state.stateCode, 
           }
           )  
         }))  
  //  propsStateCityHandler(cityMaster,enteredstate,stateCode);
   // console.log('Called from useEffect- enteredstate');
   // console.log(enteredstate,stateCode );
  },[enteredstate, props.stateMaster]);
  useEffect(()=>{
    stateCode &&
    propsStateCityHandler(cityMaster,enteredstate,stateCode);
   // console.log('Called from useEffect- enteredstate');
  },[cityMaster,enteredstate,stateCode, propsStateCityHandler])

  useEffect(()=>{
 props.state &&
   setEnteredState(props.state)
  },[props.state])
   
    return (
            <>   
             <div>
            
             <Form.Group inline  
            > 
            <label style={{marginRight:'30px'}}>State </label>
              <Dropdown 
                placeholder={enteredstate}
                search selection
                 options={stateOptions}
                 defaultValue={enteredstate} 
                  onChange={(e,data)=>{  
                        setEnteredState(e.target.textContent); 
                        if(e.code === 'Enter'){ 
                            data.options.forEach(element => {
                                element.value === data.value && setEnteredState(element.text)
                            });   
                        }   
                           }}
                       /> 
          
           </Form.Group>
             </div>
           
             
     </>
    
  );
 
}  
export default AutoCompleteState;
