import { useState,useContext, useEffect} from "react";
import axios from "axios";
import { Container, Grid, Form, Input, Button, Dropdown } from "semantic-ui-react";
import { Link,useHistory } from "react-router-dom";
import ContextData from '../../store/context-data';

const AddNewCountry = (props) =>{
    const ctx = useContext(ContextData);
    const [propStatus,setPropStatus] = useState(1);
    const [countryName,setCountryName] = useState('');
    const [countryCode, setCountryCode] = useState('')
    const [shortName,setShortName] = useState('');
    const [isoCode,setISOCode] = useState('');
    const [regionId,setRegionId] = useState('');
    const [currencyCode,setCurrencyCode] = useState('');
    const [telephoneCode,setTelephoneCode] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [readOnlyToggle, setReadOnlyToggle] = useState(false);
    const [headerMessage, setHeaderMessage] = useState('Create New Country ');
    const [countryDetails,setCountryDetails] = useState('')
    const [regionData,setRegionData] = useState('');

    let history = useHistory();  
   // console.log(props.regionData)
    //console.log(props.countryData)
    //console.log(props.countryMaster)
    //console.log(readOnlyToggle);
    const regionOptions = regionData && regionData.regionList.map((list, idr)=>{ return {
        key: idr,
        text: list.regionName,
        value:list.regionId,
        disabled: readOnlyToggle
      }
    })
    const currencyOptions = props.countryMaster.currencyList.map((list, idr)=>{ return {
         key: idr,
         text: list.currencyName,
         value:list.currencyCode,
         disabled: readOnlyToggle
        }
      })
    //https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/SaveCountry
 const saveHandler = () =>{
     const upload =   {
      "CountryCode": Number(countryCode),
      "CountryName": countryName,
      "RegionId": Number(regionId),
      "ShortName": shortName,
      "ISOCode": isoCode,
      "CurrencyCode": currencyCode,
      "TelephoneCode": telephoneCode,
      "Latitude": Number(latitude),
      "Longitude": Number(longitude),
      "Status": propStatus,
      };
     // console.log(upload)
     axios.post(`${process.env.REACT_APP_BASE_API_URL}Master/SaveCountry`,
      {
     "CountryCode": Number(countryCode),
     "CountryName": countryName,
     "RegionId": Number(regionId),
     "ShortName": shortName,
     "ISOCode": isoCode,
     "CurrencyCode": currencyCode,
     "TelephoneCode": telephoneCode,
     "Latitude": Number(latitude),
     "Longitude": Number(longitude),
     "Status": propStatus,
     },
       {headers: { Authorization: `Bearer ${ctx.APIToken}`}},  
     )
    .then((response)=>{
      if(response.data.message === 'Success')
     history.goBack();
        else alert(response.data.message)
      //  console.log(response.data);
          const data = response.data.response; 
         // history.push('/NewProperty');
    })  

     
 }
   useEffect(()=>{
     
     if(countryDetails){ 
      // console.log('country details ',countryDetails)
       const data = countryDetails.countryDetails
    setPropStatus(()=> data.status && data.status)
    setCountryName(data.countryName);
    setCountryCode(data.countryCode);
    setShortName(data.shortName);
    setISOCode(data.isoCode);
    setRegionId(data.regionId);
    setCurrencyCode(data.currencyCode);
    setTelephoneCode(data.telephoneCode);
    setLatitude(data.latitude);
    setLongitude(data.longitude);
    //const re = regionOptions.filter(item => item.value === data.regionId);
    //setRegionName(re[0].text);
    //console.log(re[0].text)
    //console.log('props.button', props.button)
    props.button === 'view' &&
    setReadOnlyToggle(true);
    props.button === 'edit' &&
    setReadOnlyToggle(false);
    setHeaderMessage(()=> (props.countryCode && props.button === 'view')?'View Country Details':'Edit Country Details')
  }
   },[countryDetails])
   
      useEffect(()=>{
        ctx.APIToken &&
       axios
       .get(
        `${process.env.REACT_APP_BASE_API_URL}Master/GetRegionMasterList`,{ 
         headers: { Authorization: `Bearer ${ctx.APIToken}`}
        }
       )
      .then((response) => {
      //   setLoaderToggle(false);
       const data = response.data.response;
      // console.log(data)
        setRegionData(data);  
      }).catch((error)=>{
        //console.log(error);
      }) 
      },[])

   useEffect(()=>{
    (ctx.APIToken && props.countryCode) && 
    axios
    .get(
     `${process.env.REACT_APP_BASE_API_URL}Master/GetCountryDetails`,{
      params:{CountryCode:props.countryCode},
      headers: { Authorization: `Bearer ${ctx.APIToken}`}
     }
    )
   .then((response) => {
  //   setLoaderToggle(false);
    const data = response.data;
   // console.log('here ', data)
     setCountryDetails(data.response);  
   }).catch((error)=>{
     //console.log(error);
   }) 
  },[props.countryCode])

    return(
        <Container>
            <Grid>
            <Grid.Row columns={1} >
                     <Grid.Column textAlign="center" style={{marginTop:'10%', paddingTop:'10px'}}>
                         <h3>{headerMessage}</h3>
                     </Grid.Column> 
            </Grid.Row>
            <Grid.Row>
                 <Grid.Column width={10}>
                     <Form inline>
                     <Form.Field inline >  
                         <label style={{marginRight:'25px'}}> Country Name *</label>
                        { readOnlyToggle && <Input 
                         style={{width:'510px'}}
                         value={countryName}
                          readOnly
                         /> ||
                         <Input 
                         style={{width:'510px'}}
                         value={countryName}
                          readOnly={readOnlyToggle}
                          onChange={(e,data)=>{
                            setCountryName(data.value)
                            
                          }}
                         />
                         }
                     </Form.Field>

                     </Form>
                 </Grid.Column>
                 <Grid.Column style={{ paddingTop:'5px'}} width={5}>
                     <Form>
                     <Form.Group inline>
                 <label  style={{marginRight:'20px'}}>Status *</label> 
                { readOnlyToggle &&
                   <input type="radio" id="active" name="prop-status" value="1" checked ={propStatus===1}  
                    readOnly/> ||
                   <input type="radio" id="active" name="prop-status" value="1" checked ={propStatus===1}
                   onChange={(e)=>{setPropStatus(Number(e.target.value)); //console.log(e.target.value);
                  }} 
                   />
                   }
                 <label>Active</label>
                 { readOnlyToggle &&
                 <input type="radio" id="passive" name="prop-status" value="0" 
                    checked ={propStatus===0} 
                     readOnly
                 /> ||
                 <input type="radio" id="passive" name="prop-status" value="0" 
                 checked ={propStatus===0}
                 onChange={(e)=>{setPropStatus(Number(e.target.value)); 
                  // console.log(e.target.value)
                  }}
                  readOnly
              />
                 }
                 <label>Passive</label>
                </Form.Group>
                     </Form>
                
                 </Grid.Column>
               </Grid.Row>
               <Grid.Row>
                   <Grid.Column width={5} >
                   <Form inline>
                          <Form.Field inline>  
                              <label style={{marginRight:'48px'}}>Short Name</label>
                             { readOnlyToggle &&
                               <Input 
                              style={{width:'197px'}}
                              value={shortName}
                              readOnly
                              /> ||
                              <Input 
                              style={{width:'197px'}}
                              value={shortName}
                              onChange={(e,data)=>{
                                setShortName(data.value)
                                
                              }}
                              /> 
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={5}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{marginRight:'17px'}}>ISO Code</label>
                             { readOnlyToggle &&<Input 
                              value={isoCode}
                              style={{width:'197px'}}
                              readOnly
                              />
                              ||
                              <Input 
                              value={isoCode}
                              style={{width:'197px'}}
                              readOnly={readOnlyToggle}
                              onChange={(e,data)=>{
                                setISOCode(data.value)
                                
                              }}
                              />
                              
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                 </Grid.Row> 
                 <Grid.Row>
                   <Grid.Column width={5} >
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{marginRight:'70px'}}>Region *</label>
                             { readOnlyToggle &&
                              <Dropdown 
                              placeholder='Region' 
                              search  
                              options={regionOptions}
                              selection 
                              value={regionId}   
                              /> ||
                              <Dropdown 
                              selectOnBlur={false}
                              placeholder='Region' 
                              search  
                              options={regionOptions}
                              selection 
                              value={regionId} 
                              onChange={(e,data)=>setRegionId(data.value)}
                              />   
                             }  

                            
                          </Form.Field>  
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={5}>
                   <Form inline>
                          <Form.Field inline >  
                              <label>Currency *</label> 
                              { readOnlyToggle &&
                                <Dropdown 
                                placeholder='Currency' 
                                search 
                                options={currencyOptions}
                                selection 
                                value={currencyCode}  
                                /> ||
                                <Dropdown 
                                selectOnBlur={false}
                                placeholder='Currency' 
                                search 
                                options={currencyOptions}
                                selection  
                                value={currencyCode} 
                                onChange={(e,data)=>setCurrencyCode(data.value)}
                                />
                                }
                            
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                 </Grid.Row> 
                 <Grid.Row>
                   <Grid.Column width={7}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{marginRight:'17px'}}>Telephone Code *</label>
                              { readOnlyToggle && <Input 
                              readOnly
                              style={{width:'197px'}}
                              value={telephoneCode} 
                              /> ||
                              <Input 
                              style={{width:'197px'}}
                              value={telephoneCode} 
                              onChange={(e,data)=>setTelephoneCode(data.value)}
                              />
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={4}>
                 
                   </Grid.Column>  
                 </Grid.Row> 
                 <Grid.Row>
                   <Grid.Column width={5}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{marginRight:'69px'}}>Latitude </label>
                              {readOnlyToggle && <Input 
                              style={{width:'197px'}}
                              value={latitude}
                              readOnly
                              /> ||
                               <Input 
                              style={{width:'197px'}}
                              value={latitude}
                              onChange={(e,data)=>setLatitude(data.value)}
                              /> 
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={6}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{marginRight:'15px'}}>Longitude</label>
                              {readOnlyToggle &&<Input 
                              style={{width :'197px'}}
                              value={longitude}
                              readOnly={readOnlyToggle}
                              />
                              ||
                              <Input 
                              style={{width:'197px'}}
                              value={longitude} 
                              type="number"
                              onChange={(e,data)=>setLongitude(data.value)}
                              />
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                 </Grid.Row> 
                 <Grid.Row>
                     <Grid.Column width={3} floated="left">
                       <Button onClick={()=>{history.goBack();}}> Back</Button>
                     </Grid.Column>
                     <Grid.Column width={3} floated="right">
                      {!readOnlyToggle && <Button onClick={saveHandler}> Save</Button> }
                     </Grid.Column>
                 </Grid.Row>
            </Grid>
        </Container>
    )
}

export default AddNewCountry;