
import React, {useState,useEffect,useContext} from 'react';
import axios from 'axios';  
import {Form,Input, Dropdown} from 'semantic-ui-react';
import ContextData from '../store/context-data'; 
const AutoCompleteCountry = (props) => {
  const [editProperty, setEditProperty] = useState(localStorage.getItem('editProperty') ? JSON.parse(localStorage.getItem('editProperty')):'');
  const [localStrProp] = useState(sessionStorage.getItem('newProperty')?JSON.parse(sessionStorage.getItem('newProperty')):'');
 // const [searchTerm, setSearchTerm] = useState("");
  const [countryMaster,setCountryMaster] = useState('');
  const [Loader,setLoader] =useState(false);
 // const[autoCompStyle,setAutoCompStyle] = useState();
  const [countryCode,setCountryCode] =useState('');
  const [countryTelephoneCode,setCountryTelephoneCode] = useState();
  const [stateMaster, setStateMaster] = useState([]);
  const [editData] = useState(localStorage.getItem('editData') ? JSON.parse(localStorage.getItem('editData')):'');
  const [enteredCountry,setEnteredCountry] = useState((localStrProp && localStrProp.PropertyDetails.formPropAdrsData.Address.Country)|| (editData && editData.organizationDetails.address.countryName)); 
 // console.log('country name ', enteredCountry);
  //const [stateToggle,setStateToggle] = useState(false);
  const[orgDetails] = useState(sessionStorage.getItem('SessionOrgDetails') ? JSON.parse(sessionStorage.getItem('SessionOrgDetails')) : '');
  const ctx = useContext(ContextData);
  const [propsCountryStateHandler] = useState(()=>props.CountryStateHandler)
  const [countryOptions, setCountryOptios] = useState();
  useEffect (()=>{
     !countryMaster &&         
    axios.get(`${process.env.REACT_APP_LUCID_API_URL}GetCountryList`,{
      headers: { Authorization: `Bearer ${props.APIToken}`}})
      .then((response)=>{
    // console.log(response.data);
      setCountryMaster(response.data);
      setLoader(true);
      }).catch((error)=>{
        console.log(error);
      });
      countryMaster && setCountryOptios( ()=> countryMaster.countries.map((country, idx)=>{
        return ({
         key:idx,
         text:country.countryName ,
         value: country.countryCode,

        }
        )  

    } 
 ) )
     },[countryMaster, props.APIToken])

     useEffect(()=>{
      setEditProperty(props.editProperty)
     },[props.editProperty])

  useEffect(()=>{
    orgDetails &&
    setEnteredCountry((orgDetails && orgDetails.OrganizationDetails.Country) || (editData && editData.organizationDetails.address.countryName)|| '')
    if(editProperty){
      setEnteredCountry(editProperty.propertyDetails.address.countryName)
    }
    props.country &&  setEnteredCountry(props.country);
    
  },[orgDetails,editData, editProperty, props.country])

  

      useEffect( ()=>{
        countryMaster.countries &&
        countryMaster.countries
         .forEach((item)=>{
         if( enteredCountry === item.countryName ){
      setCountryCode(item.countryCode);
      setCountryTelephoneCode(item.telephoneCode);
      //console.log(countryCode);
    } 
    }) 
   if(countryCode)
    countryMaster.countries.find((item)=>item.countryName===enteredCountry) &&
    axios.get(`${process.env.REACT_APP_LUCID_API_URL}GetStateList`,{
       params: {
        'CountryCode': countryCode
       },
      headers: { Authorization: `Bearer ${ctx.APIToken}`}})
      .then((response)=>{
    // console.log(response.data);
      setStateMaster(response.data);
     // console.log('stateMaster updates as showing above');  
      }).catch((error)=>{
        console.log(error);
      }); 
      console.log(enteredCountry)
     },[enteredCountry,countryMaster,ctx.APIToken,countryCode]) 

    useEffect(()=>{
      propsCountryStateHandler(stateMaster,enteredCountry,countryCode,countryTelephoneCode); 
   //console.log('entered Country details -------'+ enteredCountry,countryCode,countryTelephoneCode ); 
   
    },[stateMaster,enteredCountry,countryCode,countryTelephoneCode,propsCountryStateHandler]);
 
      const searchHandler = (e,data) =>{    
              setEnteredCountry(e.target.textContent);
              console.log('text value ',e.target.textContent) 
              if(e.code === 'Enter'){
                console.log('data.....',data);
                  data.options.forEach(element => {
                      element.value === data.value && setEnteredCountry(element.text)
                  });   
              }   
      }

 if (Loader){
 
  return (
           <>   
           
               <Form.Group inline> 
                    <label>Country</label> 
                    <Dropdown
                      placeholder={`${enteredCountry}`} 
                      search selection
                       options={countryOptions}
                       defaultValue={enteredCountry} 
                       onChange =
                      {searchHandler 
                      }
                      
                       
                       /> 
                 </Form.Group> 
             
     </>
  )}
  else{
    return(
    <>
     'Loading...'
    </>
    )
  }
  }
 
export default AutoCompleteCountry;
