
import '../styles/login.css';
import '../styles/select-mdules.css';
import Login from './Login';
import '../styles/NewProperty.css';
import '../styles/dashboard.css';
import  '../styles/sidenavnew.css';
import { useState } from 'react';
import ContextData from '../store/context-data';
import '../styles/auto-comp.css'; 
import '../styles/registr.css';
import '../styles/neworg.css';
import '../styles/form.css';
import '../App.css'
require('dotenv').config();

function App() {
 
   const [status,setStatus] = useState(false);
  const [apikey,setAPIKey] = useState('');

  const LoginStatus = (state,apikey) =>{
    setStatus(state);
      setAPIKey(apikey);
  }
 
  return (
    <>   
       <ContextData.Provider 
        value={{
         LoginStatus: status,
         APIToken:apikey
      }
    }
      >
      <Login LoginStatus = {LoginStatus}/>
      </ContextData.Provider>
      { 
      // console.log(localStorage.getItem('APIKey'))
      }
  
    </>
  );
}

export default App;
