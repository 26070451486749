import { useState, useEffect,useContext, useCallback } from "react";
import axios from "axios";
import { Container, Form, Grid, Pagination,Button, Dropdown,Table } from "semantic-ui-react";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import ContextData from '../../store/context-data';

const OrgListReport = () =>{
    const ctx = useContext(ContextData);
    const [userRightsDashboard, setUserRightsDashboard] = useState(sessionStorage.getItem('userRights')?JSON.parse(sessionStorage.getItem('userRights')):[])
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [orgOption, setOrgOption] = useState(1);
    const [currentpagenumber,setCurrentPageNumber] =useState(1);
    const [noofrows] =useState(50);
    const [orgData, setOrgData] = useState();
    const [pageData, setPageData] = useState();
    const [countryData,setCountryData] = useState();
    const [selectedCountries, setSelectedCountries] = useState('');
    const [statesData, setStatesData] = useState();
    const [selectedStates, setSelectedStates] = useState('');
    const [citiesData, setCitiesData] = useState('');
    const [selectedCities, setSelectedCities] = useState('');
    const [viewReportToggle, setViewReportToggle] = useState(false);
    const [SendEmail,setSendEmail] = useState();
    const [View, setView] = useState();
    const [Export, setExport] = useState();
    const [userType,setUserType] = useState();
 // console.log('page dta from org reports', pageData);
    const pageControlRights = pageData && pageData.response.pageControlRights.map(item=>{
      return {
       ...(item.controlName === 'View' && {View: (item.controlName === 'View' && item.visible) ? true: false}),
        ...(item.controlName === 'Export' && {Export: (item.controlName === 'Export' && item.visible) ? true: false}),
        ...(item.controlName === 'Send Email' && {SendEmail:(item.controlName === 'Send Email' && item.visible) ? true: false}),
      } 
    }).filter((itm)=>itm); 
    useEffect(()=>{
      pageData && pageData.response.pageControlRights.forEach(item =>{
     //    console.log('hey me ', item)
        
         item.controlName === 'Export' && setExport(item.visible)
         item.controlName === 'View' && setView(item.visible)
         item.controlName === 'Send Email' && setSendEmail(item.visible)
      })
      userRightsDashboard && userRightsDashboard.forEach(item=>{
         item.userType && setUserType(item)
      }) 
      
    },[pageData])

     const countryOptions = countryData && countryData.countries.map((item,idr)=>{
       return(
       {
         key: idr,
         text:item.countryName,
         value:item.countryCode,
       }
       )
     })
  
    const stateOptions = statesData && statesData.states.map((item,idr)=>{
      return(
      {
        key: idr,
        text:item.stateName,
        value:item.stateCode,
      }
      )
    })
    
    const cityOptions = citiesData && citiesData.cities.map((item,idr)=>{
      return(
      {
        key: idr,
        text:item.cityName,
        value:item.cityCode,
      }
      )
    })
      useEffect(()=>{ 
       
       selectedCountries && 
        axios.get(  `${process.env.REACT_APP_LUCID_API_URL}GetStateList`,{
        params:{CountryCodes:selectedCountries && selectedCountries.toString()}, 
        headers: { Authorization: `Bearer ${ctx.APIToken}`}, 
        }).then((response) => {
         //   setLoaderToggle(false);
           const data = response.data;
           //console.log(data)
            setStatesData(data);  
          }).catch((error)=>{
            //console.log(error);
          })
          if(!selectedCountries){
            setCitiesData('');
          }
     },[selectedCountries, ctx.APIToken])
    
    useEffect(()=>{
     
      axios.get(  `${process.env.REACT_APP_LUCID_API_URL}GetCountryList`,{
        headers: { Authorization: `Bearer ${ctx.APIToken}`}, 
        }).then((response) => {
         //   setLoaderToggle(false);
           const data = response.data;
           //console.log(data)
            setCountryData(data);  
          }).catch((error)=>{
            //console.log(error);
          })     
    },[ctx.APIToken])

    useEffect(()=>{
           axios.get( `${process.env.REACT_APP_BASE_API_URL}Reports/GetOrganizationListPageDetails`,{
           headers: { Authorization: `Bearer ${ctx.APIToken}`}, 
           }).then((response) => {
            //   setLoaderToggle(false);
              const data = response.data;
             
               setPageData(data);  
             }).catch((error)=>{
               //console.log(error);
             })     
    },[ctx.APIToken])

    useEffect(()=>{ 
       
      selectedStates && 
       axios.get(  `${process.env.REACT_APP_LUCID_API_URL}GetCityList`,{
       params:{StateCodes:selectedStates && selectedStates.toString()}, 
       headers: { Authorization: `Bearer ${ctx.APIToken}`}, 
       }).then((response) => {
        //   setLoaderToggle(false);
          const data = response.data;
         // console.log(data)
           setCitiesData(data);  
         }).catch((error)=>{
           //console.log(error);
         })  
         if(!selectedStates){
          setCitiesData('');
        }

    },[selectedStates, ctx.APIToken])
       
     const clearHandler = () =>{
       setOrgData('');

     }
    const viewReportHandler = () =>{
      
      axios
      .get(
       `${process.env.REACT_APP_BASE_API_URL}Reports/GetOrganizationList`,{ 
        params:{
          ...(startDate && {FromDate: startDate}) ,
          ...(endDate && {ToDate: endDate}),
          Status:orgOption,
          CurrentPageNumber:1,
          NoOfRowsPerPage:noofrows,
          ...(selectedCountries && {CountryCodes:selectedCountries.toString()}), 
          ...(selectedStates && {StateCodes:selectedStates.toString()}),
          ...(selectedCities && {CityCodes:selectedCities.toString()})
        }, 
        headers: { Authorization: `Bearer ${ctx.APIToken}`},
       "Content-Type": "application/json"
       }
      )
     .then((response) => {
    //   setLoaderToggle(false);
      const data = response.data;
    
       setOrgData(data);  
     }).catch((error)=>{
       //console.log(error);
     }) 
    // setCurrentPageNumber(1);
     setViewReportToggle(true);
    }
      const currentpagenumberHandler = ()=>{
        viewReportToggle &&
        axios
        .get(
         `${process.env.REACT_APP_BASE_API_URL}Reports/GetOrganizationList`,{ 
          params:{
            ...(startDate && {FromDate: startDate}) ,
            ...(endDate && {ToDate: endDate}),
            Status:orgOption,
            CurrentPageNumber:currentpagenumber,
            NoOfRowsPerPage:noofrows,
            ...(selectedCountries && {CountryCodes:selectedCountries.toString()}), 
            ...(selectedStates && {StateCodes:selectedStates.toString()}),
            ...(selectedCities && {CityCodes:selectedCities.toString()})
          }, 
          headers: { Authorization: `Bearer ${ctx.APIToken}`},
         "Content-Type": "application/json"
         }
        )
        .then((response) => { 
         const data = response.data;
         //console.log(data)
          setOrgData(data);  
        }).catch((error)=>{
          //console.log(error);
        }) 
      }
      
      
    // const currentpagenumberHandlerCb = useCallback(() =>{
    //   currentpagenumberHandler();

    // },[currentpagenumber])
    
      useEffect (()=>{
        currentpagenumber && currentpagenumberHandler();
      },[currentpagenumber])
//paginationDetail: {noOfRowsPerPage: 24, totalRecords: 190, currentPageNumber: 1, totalPages: 8}
    return (
         <Container>
             <Grid>
                 <Grid.Row columns={1} >
                     <Grid.Column textAlign="center" style={{marginTop:'70px', paddingTop:'10px'}}>
                         <h3>Organization List Report</h3>
                     </Grid.Column> 
                 </Grid.Row>
                 <Grid style={{border:'solid thin #F4EFEE', margin:'5px 0px', paddingBottom:'10px'}} className="reports">
                 <Grid.Row columns={4}>
                    <Grid.Column width={2}>
                        <div  style={{float:'left', marginTop:'10px', paddingRight:'5px' }}>  Created Date  </div>
                    </Grid.Column>
                    <Grid.Column width={5}
                      >
                    <div  style={{ float:'left',width:'110px'}}>
                             <Form>
                             
                             <DatePicker wrapperClassName="datePicker" selected={startDate} onChange={(date) => setStartDate(date)} />
                             </Form>
                        
                        </div>
                         <div  style={{float:'left', marginTop:'10px', paddingRight:'5px' , marginLeft:'5px'}}> To </div>
                         <div  style={{float:'left', width:'110px'}}>
                         <Form>
                         <DatePicker wrapperClassName="datePicker" selected={endDate} onChange={(date) => setEndDate(date)} />
                          </Form>
                        </div>
                    </Grid.Column> 
                    
                    <Grid.Column width={2} style={{paddingTop:'10px',paddingLeft:'50px'}} textAlign="center">
                    <Form.Group inline key='orgstatus'>
                 <label>  Status </label>
                   </Form.Group>
                    </Grid.Column> 
                    <Grid.Column width={5} style={{ paddingTop:'10px'}} textAlign="center" >
                    <Form> 
                <Form.Group inline key='orgstatus'> 
                 <input 
                  type="radio" id="active" name="org-status" value='1' checked ={orgOption===1}
                   onChange={(e)=>{ 
                          setOrgOption(Number(e.target.value));
                         // console.log(e.target.value);  
                        }}
                    />
                 <label>Active</label>
                 <input type="radio" id="passive" name="org-status" value='0'
                    checked ={orgOption===0}
                    onChange={(e)=>{
                      setOrgOption(Number(e.target.value)); 
                        // console.log(e.target.value);  
                        }}
                    />
                   <label >Passive</label>
                   <input type="radio" id="both" name="org-status" value='2'
                    checked ={orgOption===2}
                    onChange={(e)=>{
                      setOrgOption(Number(e.target.value)); 
                        // console.log(e.target.value); 
                        }}
                 />
                 <label >Both</label> 
                </Form.Group> 
              </Form>
                    </Grid.Column>
                 </Grid.Row>
                 <Grid.Row colums={4}>
                   <Grid.Column
                   width={2} 
                   style={{paddingTop:'10px'}}>
                   Country 
                   </Grid.Column>  
                   <Grid.Column  width={5} 
                  >
                   <div>
                   <Dropdown
                       placeholder='Country'
                       fluid
                       multiple
                       search
                       selection
                       options={(countryOptions && countryOptions) || null}
                       onChange={(e,data)=>{  
                          setSelectedCountries(data.value);
                           if(data.value.length===0)
                                  {
                               setSelectedStates('');  
                               setSelectedCities('');
                               //console.log('seleted citi n state ---',data.value); 
                           }
                      }}
                     /> 
                   </div>  
                   </Grid.Column> 
                   <Grid.Column textAlign="center"
                   width={2} 
                   style={{paddingTop:'10px',paddingLeft:'50px'}}>
                   State 
                   </Grid.Column>  
                   <Grid.Column width={6}>
                   <Dropdown
                       placeholder='State'
                       fluid
                       multiple
                       search
                       selection 
                       options={(stateOptions && stateOptions) || null}
                       onChange={(e,data)=> {
                              setSelectedStates(data.value);
                              if(data.value.length===0)
                              { 
                             setSelectedCities(''); 
                          }
                            }}
                     />
                  
                   </Grid.Column>
                    
                 </Grid.Row>
                 <Grid.Row colums={2}> 
                 <Grid.Column
                   width={2} 
                   style={{paddingTop:'10px'}}>
                   City 
                   </Grid.Column> 
                   <Grid.Column textAlign="center"
                    width={5}>
                   <div>
                   <Dropdown
                       placeholder='City'
                       fluid
                       multiple
                       search
                       selection 
                       options={(cityOptions && cityOptions) || null}
                       onChange={(e,data)=> {
                              setSelectedCities(data.value); 
                            }}
                     />
                   </div>    
                    </Grid.Column> 
                 </Grid.Row>
                 <Grid.Row columns={2}>
                        <Grid.Column width={2} style={{ paddingTop:'10px'}}>
                            Count <span style={{padding:'5px'}}> {orgData && orgData.response.paginationDetail.noOfRowsPerPage }</span>
                 </Grid.Column>
                 <Grid.Column width={3}>          
               { orgData && orgData.response &&
            <Pagination 
               size='small' 
               boundaryRange={null}
               //defaultActivePage={1}
               activePage={currentpagenumber}
               color='blue'
               ellipsisItem={null}
               firstItem={null}
               lastItem={orgData.response.paginationDetail.totalPages}
               siblingRange={1}
               totalPages={orgData.response.paginationDetail.totalPages}
               onPageChange={(e,data)=>{ setCurrentPageNumber(data.activePage)
                 
              }}
             >  
             </Pagination>} 
                        </Grid.Column>
                        <Grid.Column width={7}floated="right">
                          <Button
                          color='blue' 
                          onClick={clearHandler}>Clear</Button>
                         {(View || userType === 2) && <Button onClick={viewReportHandler}
                         color='blue'
                         >View Report</Button> ||
                          <Button disabled>View Report</Button>
                         } 
                          
                           <span style={{padding:'5px', fontWeight:'bold'}}> {orgData && `Total Records : ` + orgData.response.paginationDetail.totalRecords }</span>
                  
                         </Grid.Column>
                        
                    </Grid.Row>
                 </Grid>
                 
                    <Grid.Row style={{marginBottom:'50px'}}>
                       { orgData && <Table celled>
                        <Table.Header>
                       <Table.Row textAlign="center">
                         <Table.HeaderCell >Sl.No</Table.HeaderCell>
                         <Table.HeaderCell>Organization Name</Table.HeaderCell> 
                         <Table.HeaderCell>Address</Table.HeaderCell> 
                         <Table.HeaderCell>No of Properties</Table.HeaderCell> 
                         <Table.HeaderCell>Created On</Table.HeaderCell> 
                         <Table.HeaderCell>Created By</Table.HeaderCell> 
                         <Table.HeaderCell>Status</Table.HeaderCell>  
                       </Table.Row>
                       </Table.Header>
                       <Table.Body>
                       { orgData && orgData.response.organizationList.map((item,idr)=>{ 
                            return(
                              <Table.Row key={idr}>
                             <Table.Cell textAlign="center">{item.sNo || null}</Table.Cell>
                             <Table.Cell>{item.organizationName || null }</Table.Cell>
                             <Table.Cell width={5}>{item.addressLine1 || null} {item.addressLine2 || null} {item.addressLine3 || null}</Table.Cell>
                             <Table.Cell textAlign="center">{item.noOfProperties || null}</Table.Cell>
                             <Table.Cell  textAlign="center">{item.createdOn || null}</Table.Cell>
                             <Table.Cell  textAlign="center">{item.createdBy || null}</Table.Cell>
                             <Table.Cell  textAlign="center">{item.status || null}</Table.Cell>
                            </Table.Row>
                            )
                       })
                   } 
 </Table.Body>                               
                        </Table>
                        }
                    </Grid.Row>
             </Grid>
         </Container>
    )

}

export default OrgListReport;