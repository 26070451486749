
import { useState, useEffect, useContext } from "react";
import axios from "axios";
//import Uploady from "@rpldy/uploady";
//import UploadButton from "@rpldy/upload-button";

import { useHistory } from 'react-router-dom';
import { Container, Form, Button, Accordion, Icon, Grid, Input, Image } from 'semantic-ui-react'
//import {decode as base64_decode, encode as base64_encode} from 'base-64'; 
import { Link } from "react-router-dom";
import AutoCompleteCountry from "./Components/AutoCompleteCountry";
import AutoCompleteState from "./Components/AutoCompleteState";
import AutoCompleteCity from "./Components/AutoCompleteCity";
import AutoCompleteArea from "./Components/AutoCompleteArea";
import ContextData from "./store/context-data";
//import save from "save";

//import ContextData from "./store/context-data"; 
//import Accord from "./Components/Accord"; 
const NewOrganization = (props) => {
  const ctx = useContext(ContextData);
  const [orgImgData, setOrgImgData] = useState(localStorage.getItem("orgImgData") ? JSON.parse(localStorage.getItem("orgImgData")) : '');
  const [orgDetails] = useState(sessionStorage.getItem('SessionOrgDetails') ? JSON.parse(sessionStorage.getItem('SessionOrgDetails')) : '');
  const [editData, setEditData] = useState(localStorage.getItem('editData') ? JSON.parse(localStorage.getItem('editData')) : '')
  //  console.log(editData);
  // const [xstyle,setXstyle] = useState({height:'0px'});
  //const [minMaxIcon,setMinMaxIcon] = useState('[+]');
  const [organizationName, setOrganizationName] = useState((editData && editData.organizationDetails.organizationName) || '');
  const [status, setStatus] = useState(editData ? editData.organizationDetails.status : 1);
  // console.log('status from new org state',status);
  const [enteredCountry, setCountry] = useState(editData ? editData.organizationDetails.countryName : '');
  const [city, setCity] = useState(editData ? editData.organizationDetails.address.cityName : '');
  const [cityCode, setCityCode] = useState(editData ? editData.organizationDetails.address.cityCode : '');
  const [enteredstate, setEnteredState] = useState(editData ? editData.organizationDetails.address.stateName : '');
  const [enteredstatecode, setStateCode] = useState(editData ? editData.organizationDetails.address.stateCode : '');
  const [area, setArea] = useState(editData ? editData.organizationDetails.address.areaName : '');
  const [areaCode, setAreaCode] = useState(editData ? editData.organizationDetails.address.areaCode : '');
  const [zipcode, setZipCode] = useState((orgDetails && orgDetails.OrganizationDetails.Address.ZipCode) || (editData && editData.organizationDetails.address.zipCode) || '');
  const [contactmobile, setContactMobile] = useState((orgDetails && orgDetails.OrganizationDetails.ContactDetails.MobileNo) || (editData && editData.organizationDetails.contactDetails.mobileNo) || '');
  const [contacttelephone, setTelphone] = useState((orgDetails && orgDetails.OrganizationDetails.ContactDetails.TelephoneNo) || (editData && editData.organizationDetails.contactDetails.telephoneNo) || '');
  const [countryCode, setCountryCode] = useState(editData ? editData.organizationDetails.address.countryCode : '');
  const [countryTelephoneCode, setCountryTelephoneCode] = useState((orgDetails && orgDetails.OrganizationDetails.ContactDetails.TelephoneCountryCode) || (editData && editData.organizationDetails.contactDetails.telephoneCountryCode) || '');
  const [telephoneAreaCode, setTelephoneAreaCode] = useState((orgDetails && orgDetails.OrganizationDetails.ContactDetails.TelephoneAreaCode) || '');
  const [emailid, setEmailID] = useState((orgDetails && orgDetails.OrganizationDetails.ContactDetails.EmailId) || (editData && editData.organizationDetails.contactDetails.emailId) || '');
  const [website, setWebsite] = useState((orgDetails && orgDetails.OrganizationDetails.ContactDetails.Website) || (editData && editData.organizationDetails.contactDetails.website) || '');
  const [AddressLine1, setAddressLine1] = useState((orgDetails && orgDetails.OrganizationDetails.Address.AddressLine1) || (editData && editData.organizationDetails.address.addressLine1) || '');
  const [AddressLine2, setAddressLine2] = useState((orgDetails && orgDetails.OrganizationDetails.Address.AddressLine2) || (editData && editData.organizationDetails.address.addressLine2) || '');
  const [AddressLine3, setAddressLine3] = useState((orgDetails && orgDetails.OrganizationDetails.Address.AddressLine3) || (editData && editData.organizationDetails.address.addressLine3) || '');
  const [Landmark, setLandmark] = useState((orgDetails && orgDetails.OrganizationDetails.Address.Landmark) || (editData && editData.organizationDetails.address.landmark) || '');
  //const [countryMaster,setCountryMaster] = useState([]);
  const [stateMaster, setStateMaster] = useState([]);
  const [cityMaster, setCityMaster] = useState();
  const [mobileCountryCode, setMobileCountryCode] = useState((orgDetails && orgDetails.OrganizationDetails.ContactDetails.MobileCountryCode) || (editData && editData.organizationDetails.contactDetails.mobileCountryCode) || '');
  const [activeIndex, setActiveIndex] = useState(0);
  const [preview, setPreview] = useState((editData && editData.organizationDetails.imageUrl) || '')
  let history = useHistory();
  const [saveToggle, setSaveToggle] = useState((orgDetails || editData) ? true : false);
  const [imgButtonToggle, setImageButtonToggle] = useState(false);
  const [imgButtonTouched, setImageButtonTouched] = useState(false);
  const [pageHeader] = useState((editData && 'Edit Organization') || 'Create New Organization');
  const [waitUpload, setWaitUpload] = useState(true);
  const [imgURLServer, setImageURLServer] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  //const [enteredEmailTouched, setEnteredEmailTouched] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('')


  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const goBack = () => {
    history.goBack();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []
  )

  const fileHandler = async (e) => {
    imgRemoveHandler();
    setImageButtonToggle(true);
    const file = e.target.files[0];
    const name = file.name;
    const lastDot = name.lastIndexOf('.');
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    // setFileName(fileName);
    // setFileExt(ext);
    const base64 = await convertBase64(file);
    if (!e.target.files || e.target.files.length === 0) {
      //  setOrganizationPhoto(undefined)
      return
    } else {
      setImageButtonToggle(true);
      //  setOrganizationPhoto(file);  
      const objectUrl = URL.createObjectURL(file)
      setPreview(() => objectUrl);
    }
    //  let encoded = base64_encode(file);
    // let decoded = base64_decode(encoded);
    let formatBase64 = base64.replace("data:", "")
      .replace(/^.+,/, "");
    // let decoded = base64_decode(formatBase64); 
    // setImageString(formatBase64); 
    const imgDataObj = {
      fileName: fileName,
      FileExtension: ext,
      ImageString: formatBase64,
      File: preview
    }
    localStorage.setItem('orgImgData', JSON.stringify(imgDataObj));
    setOrgImgData(imgDataObj);
    e.target.value = '';
  }

  const imgRemoveHandler = () => {
    setPreview("");
    // setImageURLServer('');
    setImageButtonTouched(true);
    setImageButtonToggle(false);
  }

  useEffect(() => {

    if (editData) {
      setCountry(editData.organizationDetails.address.countryName)
      setCity(editData.organizationDetails.address.cityName)
      setCityCode(editData.organizationDetails.address.cityCode)
      setEnteredState(editData.organizationDetails.address.stateName)
      setStateCode(editData.organizationDetails.address.stateCode)
      setArea(editData.organizationDetails.address.areaName)
      setAreaCode(editData.organizationDetails.address.areaCode);
      setZipCode(editData.organizationDetails.address.zipCode);
      setContactMobile(editData.organizationDetails.contactDetails.mobileNo)
      setTelphone(editData.organizationDetails.contactDetails.mobileNo.telephoneNo)
      setCountryCode(editData.organizationDetails.contactDetails.mobileNo)
      setCountryTelephoneCode(editData.organizationDetails.contactDetails.telephoneCountryCode)
      setTelephoneAreaCode(editData.organizationDetails.contactDetails.telephoneAreaCode)
      setEmailID(editData.organizationDetails.contactDetails.emailId)
      setWebsite(editData.organizationDetails.contactDetails.website)
      setAddressLine1(editData.organizationDetails.address.addressLine1)
      setAddressLine2(editData.organizationDetails.address.addressLine2)
      setAddressLine3(editData.organizationDetails.address.addressLine3)
      setLandmark(editData.organizationDetails.address.landmark);
      setOrganizationName(editData.organizationDetails.organizationName);
    }

    orgDetails && setStatus(orgDetails.OrganizationDetails.Status);

  }, [editData, orgDetails])


  const uploadHandler = () => {

    // const OrganizationDetails = {"OrganizationDetails":{
    //   "OrganizationId":(editData && editData.organizationDetails.organizationId) || 0, 
    //    "OrganizationName": (organizationName && organizationName) || orgDetails.OrganizationDetails.OrganizationName,
    //    "ImageUrl": (imgURLServer && imgURLServer) || (editData && editData.organizationDetails.imageUrl),
    //    "Address":{
    //        "AddressLine1": (AddressLine1 && AddressLine1) || (orgDetails && orgDetails.OrganizationDetails.Address.AddressLine1) || '',
    //        "AddressLine2":(AddressLine2 && AddressLine2) || (orgDetails && orgDetails.OrganizationDetails.Address.AddressLine2) || '',
    //        "AddressLine3":  (AddressLine3 && AddressLine3) || (orgDetails && orgDetails.OrganizationDetails.Address.AddressLine3) || '',
    //        "AreaCode":  (areaCode && Number(areaCode)) || (orgDetails && Number(orgDetails.OrganizationDetails.Address.AreaCode)) || '',
    //        "AreaName": (area && area) || (orgDetails && orgDetails.OrganizationDetails.Address.AreaName) || '',
    //        "CityCode": (cityCode && Number(cityCode)) || (orgDetails && Number(orgDetails.OrganizationDetails.Address.CityCode)) || '',
    //        "CityName": (city && city) || (orgDetails && orgDetails.OrganizationDetails.Address.CityName) || '',
    //        "StateCode":(enteredstatecode && Number(enteredstatecode)) || (orgDetails && Number(orgDetails.OrganizationDetails.Address.StateCode)) ||'',
    //        "CountryCode": (countryCode && Number(countryCode)) || (orgDetails && Number(orgDetails.OrganizationDetails.Address.CountryCode)) ||'',
    //        "ZipCode": (zipcode && zipcode) || (orgDetails && orgDetails.OrganizationDetails.Address.ZipCode) ||'',
    //        "Landmark":  (Landmark && Landmark) || (orgDetails && orgDetails.OrganizationDetails.Address.Landmark) || ''
    //    },
    //    "ContactDetails":{     
    //        "MobileCountryCode": (mobileCountryCode && mobileCountryCode) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.MobileCountryCode) || '',
    //        "MobileNo":(contactmobile && contactmobile) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.MobileNo) || '',
    //        "TelephoneCountryCode":(countryTelephoneCode && countryTelephoneCode) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.TelephoneCountryCode) || '',
    //        "TelephoneAreaCode":(telephoneAreaCode && telephoneAreaCode) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.TelephoneAreaCode) || '',
    //        "TelephoneNo":(contacttelephone && contacttelephone) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.TelephoneNo) || '',
    //        "EmailId":  (emailid && emailid) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.EmailId) || '',
    //        "Website": (website && website) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.Website) || ''
    //    }, 
    //    "Status": (status=== 0? 0:1),
    //  } 
    // };
    // console.log(OrganizationDetails);

    axios.post(`${process.env.REACT_APP_BASE_API_URL}EditOrganization`,
      {
        "OrganizationDetails": {
          "OrganizationId": (editData && editData.organizationDetails.organizationId) || 0,
          "OrganizationName": (organizationName && organizationName) || orgDetails.OrganizationDetails.OrganizationName,
          "ImageUrl": (imgURLServer && imgURLServer) || (editData && editData.organizationDetails.imageUrl),
          "Address": {
            "AddressLine1": (AddressLine1 && AddressLine1) || (orgDetails && orgDetails.OrganizationDetails.Address.AddressLine1) || '',
            "AddressLine2": (AddressLine2 && AddressLine2) || (orgDetails && orgDetails.OrganizationDetails.Address.AddressLine2) || '',
            "AddressLine3": (AddressLine3 && AddressLine3) || (orgDetails && orgDetails.OrganizationDetails.Address.AddressLine3) || '',
            "AreaCode": (areaCode && Number(areaCode)) || (orgDetails && Number(orgDetails.OrganizationDetails.Address.AreaCode)) || '',
            "AreaName": (area && area) || (orgDetails && orgDetails.OrganizationDetails.Address.AreaName) || '',
            "CityCode": (cityCode && Number(cityCode)) || (orgDetails && Number(orgDetails.OrganizationDetails.Address.CityCode)) || '',
            "CityName": (city && city) || (orgDetails && orgDetails.OrganizationDetails.Address.CityName) || '',
            "StateCode": (enteredstatecode && Number(enteredstatecode)) || (orgDetails && Number(orgDetails.OrganizationDetails.Address.StateCode)) || '',
            "CountryCode": (countryCode && Number(countryCode)) || (orgDetails && Number(orgDetails.OrganizationDetails.Address.CountryCode)) || '',
            "ZipCode": (zipcode && zipcode) || (orgDetails && orgDetails.OrganizationDetails.Address.ZipCode) || '',
            "Landmark": (Landmark && Landmark) || (orgDetails && orgDetails.OrganizationDetails.Address.Landmark) || ''
          },
          "ContactDetails": {
            "MobileCountryCode": (countryTelephoneCode && countryTelephoneCode) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.MobileCountryCode) || '',
            "MobileNo": (contactmobile && contactmobile) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.MobileNo) || '',
            "TelephoneCountryCode": (countryTelephoneCode && countryTelephoneCode) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.TelephoneCountryCode) || '',
            "TelephoneAreaCode": (telephoneAreaCode && telephoneAreaCode) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.TelephoneAreaCode) || '',
            "TelephoneNo": (contacttelephone && contacttelephone) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.TelephoneNo) || '',
            "EmailId": (emailid && emailid) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.EmailId) || '',
            "Website": (website && website) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.Website) || ''
          },

          "Status": (status === 0 ? 0 : 1),
        }
      }, {
        headers: { Authorization: `Bearer ${ctx.APIToken}` },
      "Content-Type": "application/json"
    }

    ).then((response) => {
      //console.log(response.data);
      const resData = response.data;
      if (resData.errorCode === 1) {
        alert(resData.message)

        //  setResponseData(resData.message);
      } else {
        // alert(resData.message)
        // console.log('uploaded the data');
        //console.log(response.data);
        setWaitUpload(true);
        //  <Redirect to='/Response' /> 
        localStorage.removeItem("orgImgData");
        localStorage.removeItem('editData')
        history.push('/')
      }

    }).catch((error) => {
      console.log(error);
    })
    setWaitUpload(true);

  }
  useEffect(() => {
    if (waitUpload === false && editData) {
      uploadHandler();
    }
  }, [waitUpload, editData])

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    // const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex);
  }

  //   const countryEventHandler = (e) =>{
  //             setCountry( e.target.value);

  //   }

  //    useEffect (()=>{
  //  console.log(stateMaster); 
  //      },[stateMaster,setCityMaster])

  useEffect(() => {
    // console.log(cityMaster);

  }, [cityMaster])

  const stateHandler = (statesData, country, code, tcode) => {

    setStateMaster(statesData);
    setCountry(country);
    setCountryCode(code);
    setCountryTelephoneCode(tcode);
    setMobileCountryCode(tcode);
    //  console.log('Country passed data to New Org'); 
  }

  const cityHandler = (citiesData, state, stcode) => {

    setCityMaster(citiesData);
    setEnteredState(state);
    setStateCode(stcode);
    // console.log('Passed Cities Data to New Org ')
    // console.log(state);
  }
  const enteredCityHandler = (city, code) => {
    // console.log('Hre te city handler')
    setCity(city);
    setCityCode(code);
  }

  const enteredAreaHandler = (area, code, areatelecode) => {
    setArea(area);
    setAreaCode(code);
    setTelephoneAreaCode(areatelecode);

    // alert(telephoneAreaCode)
  }
  // const formData={};
  const clearInputFieldStates = () => {

    setOrganizationName();
    setStatus('');
    // setImageURLServer('');
    setCountry('');
    setCity('');
    setCityCode('');
    setEnteredState();
    setStateCode();
    setArea();
    setAreaCode();
    setZipCode();
    setContactMobile();
    setTelphone();
    setCountryCode();
    setCountryTelephoneCode();
    setTelephoneAreaCode();
    setEmailID();
    setWebsite();
    setAddressLine1();
    setAddressLine2();
    setAddressLine3();
    setLandmark();
    setMobileCountryCode();
    //  setOrganizationPhoto();

  }

  const formHandlerEdit = () => {
    //  alert(telephoneAreaCode);
    if (editData) {
      if (!orgImgData) setWaitUpload(false);

      orgImgData &&
        axios.post(`${process.env.REACT_APP_LUCID_API_URL}UploadImage`, {
          "FileName": orgImgData.fileName,
          "FileExtension": orgImgData.FileExtension,
          "FileData": null,
          "ImageString": orgImgData.ImageString
        }, {
          headers: { Authorization: `Bearer ${ctx.APIToken}` },
          "Content-Type": "application/json"
        }
        ).then((response) => {
          //console.log('uploaded the image');
          // console.log(response.data)
          const imageUrlServer = response.data;
          setImageURLServer(imageUrlServer.response.imageUrl);
          setWaitUpload(false);
        }).catch((error) => {
          console.log(error);
          alert(error);
        })
    }

  }

  const formHandler = () => {

    // console.log('Image URL from the server' + imageURLServer.response.imageUrl);
    orgDetails &&
      setImageButtonToggle(true);
    if ((!organizationName && !orgDetails) && !editData) {
      //   alert(' Kindly enter Organization Name');
      setSaveToggle(false);
      return;
    } else { setSaveToggle(true); }
    if (!orgDetails && !editData && (!enteredCountry || !enteredstate || !city)) {
      //  alert ('kindly enter Country, State and City');
      return;
    }

    const OrganizationDetails = {
      OrganizationDetails: {
        OrganizationName: (organizationName && organizationName) || orgDetails.OrganizationDetails.OrganizationName,
        ImageUrl: (preview && preview) || (orgDetails && orgDetails.OrganizationDetails.ImageUrl) || '',
        Country: (enteredCountry && enteredCountry) || (orgDetails && orgDetails.OrganizationDetails.Country) || '',
        State: (enteredstate && enteredstate) || (orgDetails && orgDetails.OrganizationDetails.State) || '',
        Address: {
          AddressLine1: (AddressLine1 && AddressLine1) || (orgDetails && orgDetails.OrganizationDetails.Address.AddressLine1) || '',
          AddressLine2: (AddressLine2 && AddressLine2) || (orgDetails && orgDetails.OrganizationDetails.Address.AddressLine2) || '',
          AddressLine3: (AddressLine3 && AddressLine3) || (orgDetails && orgDetails.OrganizationDetails.Address.AddressLine3) || '',
          AreaCode: (areaCode && areaCode) || (orgDetails && orgDetails.OrganizationDetails.Address.AreaCode) || '',
          AreaName: (area && area) || (orgDetails && orgDetails.OrganizationDetails.Address.AreaName) || '',
          CityCode: (cityCode && cityCode) || (orgDetails && orgDetails.OrganizationDetails.Address.CityCode) || '',
          CityName: (city && city) || (orgDetails && orgDetails.OrganizationDetails.Address.CityName) || '',
          StateCode: (enteredstatecode && enteredstatecode) || (orgDetails && orgDetails.OrganizationDetails.Address.StateCode) || '',
          CountryCode: (countryCode && countryCode) || (orgDetails && orgDetails.OrganizationDetails.Address.CountryCode) || '',
          ZipCode: (zipcode && zipcode) || (orgDetails && orgDetails.OrganizationDetails.Address.ZipCode) || '',
          Landmark: (Landmark && Landmark) || (orgDetails && orgDetails.OrganizationDetails.Address.Landmark) || ''
        },
        ContactDetails: {
          MobileCountryCode: (countryTelephoneCode && countryTelephoneCode) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.MobileCountryCode) || '',
          MobileNo: (contactmobile && contactmobile) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.MobileNo) || '',
          TelephoneCountryCode: (countryTelephoneCode && countryTelephoneCode) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.TelephoneCountryCode) || '',
          TelephoneAreaCode: (telephoneAreaCode && telephoneAreaCode) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.TelephoneAreaCode) || '',
          TelephoneNo: (contacttelephone && contacttelephone) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.TelephoneNo) || '',
          EmailId: (emailid && emailid) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.EmailId) || '',
          Website: (website && website) || (orgDetails && orgDetails.OrganizationDetails.ContactDetails.Website) || ''
        },

        Status: (status === 0 ? 0 : 1)
      }
    };

    // console.log(OrganizationDetails);
    props.formPullData(organizationName, enteredCountry, countryCode, enteredstate, enteredstatecode, OrganizationDetails);
    sessionStorage.setItem('SessionOrgDetails', JSON.stringify(OrganizationDetails));
    setSaveToggle(true);
    setTimeout(clearInputFieldStates(), 200);
    setImageButtonToggle(false);
  }
  // const expand = () => {
  //    if (minMaxIcon === '[+]'){
  //      //  setXstyle({ height:'100%', opacity:1})
  //        setMinMaxIcon('[-]')
  //    }
  //    else{
  //       setMinMaxIcon( '[+]')
  //     //  setXstyle({ height:'0px', opacity:0})
  //    }
  //  }; 
  const setOrgHandler = (e) => {
    setOrganizationName(e.target.value);
    setErrorMessage('');
    //  console.log(organizationName); 
    if (e.target.value.length === 0 || !organizationName) {
      setSaveToggle(false);
      setErrorMessage('Invalid Entry')
    } else {
      setSaveToggle(true);
    }

  }
  const emailHandler = (e) => {
    //var validRegex = /@^[a-zA-Z0-9][\w\.-]+[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]+[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]+[a-zA-Z]$/
    //var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.{3,}[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{3,4}\.[0-9]{3,4}\.[0-9]{3,4}\.[0-9]{3,4}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // var validRegex =  /^(?=[^@]{4,}@)([\w\.-]*[a-zA-Z0-9_]@(?=.{4,}\.[^.]*$)[\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z])$/  
    if (!e.target.value.match(validRegex)) {
      setEmailErrorMessage('Invalid Email')
      setSaveToggle(false);
    } else {
      setEmailErrorMessage('')
      setSaveToggle(true);
    }
  }


  useEffect(() => {
    props.ViewOrg &&
      axios.get(`${process.env.REACT_APP_BASE_API_URL}GetOrganizationDetails`,
        {
          params: {
            'OrganizationId': props.OrganizationId
          }, headers: { Authorization: `Bearer ${ctx.APIToken}` }
        },
      )
        .then((response) => {
          console.log(response.data);
          const data = response.data.response;
          setEditData(data);
          localStorage.setItem('editData', JSON.stringify(data));

          //  history.push('/NewOrganization');
        })
  }, [])

  return (
    <>
      <Container>
        <Grid>
          <Grid.Row columns={1} style={{ marginTop: '100px' }}>
            <Grid.Column width={16} textAlign='center'><h3>{pageHeader}</h3></Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} >
            <Grid.Column width={1}>
            </Grid.Column>
            <Grid.Column width={9}>
              <Form>
                <Form.Group inline key='orgname'>
                  <label>
                    Organization Name *
                  </label>
                  <Input
                    required
                    placeholder={errorMessage}
                    style={{ width: "75%", color: 'red' }}
                    defaultValue={(organizationName && organizationName) || (orgDetails ? orgDetails.OrganizationDetails.OrganizationName : '')}
                    onChange={setOrgHandler}
                    autoComplete="off"
                  />
                </Form.Group>
                <p className="err"> {errorMessage} </p>
                <Form.Group inline key='orgstatus'>
                  <label className='org-status'>  Status* </label>
                  <input
                    type="radio" id="active" name="org-status" value='1' checked={status === 1}
                    onChange={(e) => {
                      setStatus(Number(e.target.value));
                      // console.log(e.target.value);   
                    }}
                  />
                  <label>Active</label>
                  <input type="radio" id="passive" name="org-status" value='0'
                    checked={status === 0}
                    onChange={(e) => {
                      setStatus(Number(e.target.value));
                      // console.log(e.target.value);

                    }}
                  />
                  <label >Passive</label>
                </Form.Group>

              </Form>
            </Grid.Column>

            <Grid.Column width={4}>
              {(preview && <Image rounded size='small' src={preview} alt='organization pic' />) || (orgDetails && !imgButtonTouched && <Image rounded size='small' src={orgDetails.OrganizationDetails.ImageUrl} alt='organization pic' />) ||
                <i className="fa fa-picture-o" aria-hidden="true" style={{ fontSize: '100px' }}></i>
              }
              <Grid stackable>
                <Grid.Row columns={2}>
                  <Grid.Column width={5}>
                    <input
                      key='imgfile'
                      type="file"
                      style={{ color: 'transparent', marginTop: '5px' }}
                      placeholder=''
                      label=''
                      onChange={fileHandler}
                    />
                  </Grid.Column>
                  <Grid.Column width={5} style={{ margin: '5px' }}>
                    {imgButtonToggle && <button onClick={imgRemoveHandler}>Remove </button>}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row >
            <Grid.Column width={1}>
            </Grid.Column>
            <Grid.Column width={15} >

              {/*Accordion starts here */
              }
              <Accordion >
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleClick}
                >
                  <Icon name='dropdown' />
                  <b> Address & Contact Details </b>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <div >
                    <Grid>
                      <Grid.Row columns={2}>
                        {/* Column left starts*/}
                        <Grid.Column width={5}>
                          <Form>
                            <Grid.Row style={{ marginBottom: '10px' }}> <h5> Address Details</h5></Grid.Row>

                            <Grid.Row>
                              <AutoCompleteCountry
                                APIToken={props.APIToken}
                                CountryStateHandler={stateHandler}
                                country={enteredCountry}
                              />

                            </Grid.Row>
                            <AutoCompleteState
                              APIToken={props.APIToken}
                              stateMaster={stateMaster}
                              StateCityHandler={cityHandler}
                              state={enteredstate}
                            />
                            <Grid.Row>

                              <AutoCompleteCity
                                APIToken={props.APIToken}
                                cityHandler={enteredCityHandler}
                                stateCode={enteredstatecode}
                                city={city}
                              />
                            </Grid.Row>

                            <Grid.Row>
                              <AutoCompleteArea
                                APIToken={props.APIToken}
                                areaHandler={enteredAreaHandler}
                                cityCode={cityCode}
                                area={area}
                              />
                            </Grid.Row>
                            <Form.Field style={{ width: '197px' }}
                              inline
                              autoComplete="off"
                              control={Input}
                              defaultValue={zipcode}
                              label=' Zip Code'
                              placeholder='Zip Code'
                              onChange={(e) => { setZipCode(e.target.value); }}
                              key='zipcode'
                            />
                          </Form>
                        </Grid.Column>
                        {/* Column left ends*/}

                        {/* Column right starts*/}
                        <Grid.Column width={6}>
                          <Form className="margin">

                            <Grid.Row style={{ marginTop: '50px' }}>
                              <h5>Outlet Address</h5>

                            </Grid.Row>
                            <Grid.Row style={{ marginTop: '10px' }}>
                              <Form.Group >
                                <label></label>
                                <input
                                  autoComplete="off"
                                  defaultValue={AddressLine1}
                                  placeholder='Address Line1'
                                  onChange={(e) => {
                                    setAddressLine1(e.target.value);
                                  }}
                                  key='ad1'
                                />
                              </Form.Group>
                            </Grid.Row>

                            <Grid.Row>

                              <Form.Group >
                                <label></label>
                                <input
                                  autoComplete="off"
                                  label=""
                                  defaultValue={AddressLine2}
                                  placeholder='Address Line2'
                                  onChange={(e) => {
                                    setAddressLine2(e.target.value);
                                  }}
                                  key='ad2'
                                />
                              </Form.Group>

                            </Grid.Row>

                            <Grid.Row>
                              <Form.Group >
                                <label></label>
                                <input
                                  autoComplete="off"
                                  label=""
                                  placeholder='Address Line3'
                                  defaultValue={AddressLine3}
                                  onChange={(e) => {
                                    setAddressLine3(e.target.value);
                                  }}
                                  key='ad3'
                                />
                              </Form.Group>
                            </Grid.Row>
                            <Grid.Row>
                              <Form.Group >
                                <label></label>
                                <input
                                  autoComplete="off"
                                  label=""
                                  defaultValue={Landmark}
                                  placeholder='Land Mark'
                                  onChange={(e) => {
                                    setLandmark(e.target.value);
                                  }}
                                  key='lndm'
                                />
                              </Form.Group>
                            </Grid.Row>



                          </Form>
                        </Grid.Column>
                        {/* Column right ends*/}

                      </Grid.Row>
                    </Grid>
                    <Grid.Row>
                      <Grid.Column> </Grid.Column>
                      <Grid.Column>
                        <Grid.Row>
                          <Grid.Column> <h5>Contact Details</h5></Grid.Column>
                          <Form>
                            <Form.Group inline >
                              <label style={{ marginRight: '32px' }}>Contact Mobile No</label>
                              <Form.Field width={2}>
                                <Input
                                  autoComplete="off"
                                  readOnly
                                  defaultValue={mobileCountryCode}
                                  onChange={(e) => { setMobileCountryCode(e.target.value) }}
                                  key='mcode'
                                />
                              </Form.Field>
                              <Form.Field width={5}>
                                <Input placeholder='Mobile No'
                                  autoComplete="off"
                                  defaultValue={contactmobile}
                                  onChange={(e) => { setContactMobile(e.target.value) }}
                                  key='cmbl'
                                />
                              </Form.Field>
                            </Form.Group>
                            <Grid.Row>
                              <Form.Group inline>
                                <label>Contact Telephone No</label>
                                <Form.Field width={2}>
                                  <Input
                                    autoComplete="off"
                                    readOnly
                                    defaultValue={countryTelephoneCode}
                                    onChange={(e) => setCountryTelephoneCode(e.target.value)}
                                    key='ctlph'
                                  />
                                </Form.Field>
                                <Form.Field width={2}>
                                  <Input
                                    autoComplete="off"
                                    readOnly
                                    onChange={(e) => {
                                      setTelephoneAreaCode(e.target.value);
                                      console.log(e.target.value)
                                    }}
                                    defaultValue={telephoneAreaCode}
                                    key='tac'
                                  />
                                </Form.Field>
                                <Form.Field
                                  width={3}>
                                  <Input
                                    autoComplete="off"
                                    onChange={(e) => {
                                      setTelphone(e.target.value);
                                      // console.log(e.target.value);
                                    }}
                                    defaultValue={contacttelephone}
                                    key='tphn'
                                  />  </Form.Field>
                              </Form.Group>
                            </Grid.Row>
                            <Form.Group inline >
                              <label style={{ marginRight: '93px' }}> Email ID</label>
                              <Input
                                autoComplete="off"
                                defaultValue={emailid}
                                onChange={(e) => { setEmailID(e.target.value) }}
                                onBlur={emailHandler}
                                key='emaili'
                              />
                              <p className="err"> {emailErrorMessage} </p>
                            </Form.Group>

                            <Form.Group inline >
                              <label style={{ marginRight: '93px' }}> Website</label>
                              <Input
                                autoComplete="off"
                                defaultValue={website}
                                onChange={(e) => { setWebsite(e.target.value); }}
                                key='webs'
                              />
                            </Form.Group>
                          </Form>
                        </Grid.Row>
                      </Grid.Column>


                    </Grid.Row>

                  </div>

                </Accordion.Content>

              </Accordion>

            </Grid.Column>
          </Grid.Row>
          <Grid.Row >
            <Grid.Column width={4}>
              <Button
                color='blue'
                onClick={goBack}
              > Go Back</Button>
            </Grid.Column>
            <Grid.Column width={4} floated='right'>

              {(saveToggle && ((editData && saveToggle)) && <Link to='#' style={{ color: 'white' }} onClick={formHandlerEdit}><Button color='blue'> Update </Button></Link>) || (saveToggle ? <Link to='/NewProperty' style={{ color: 'white' }} onClick={formHandler}><Button color='blue'> Next </Button></Link> :
                <Button color='blue'>  Next </Button>)
              }
              {//  <Link to='/NewProperty' style={{color:'white'}}><Button color='blue'  onClick={formHandler}> Next </Button></Link> 
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div style={{ height: '100px' }}>
        </div>
      </Container>
    </>
  )
}
export default NewOrganization;
