import { useState } from "react";
//import ContextData from "../store/context-data";
import Upload from "./Upload";
import AddProperty from "./AddProperty";
import EditProperty from "./EditProperty";
const LicensePreview = (props) =>{
  const [editProperty] = useState(localStorage.getItem('editProperty') ? JSON.parse(localStorage.getItem('editProperty')):'');
  const [orgAddProperty] = useState(localStorage.getItem('orgAddProperty') ? JSON.parse(localStorage.getItem('orgAddProperty')):''); 
   // const ctx = useContext(ContextData);   
   // const [ObjFormProp,setObjFormProp]=useState({
   //   PropertyName:'',
   //   ShortName:'',
   //   ImageUrl:'',
   // })
       
   // props.formPropHandler(ObjFormProp);
   // console.log(ObjFormProp);
   
    return(
      <>    
           { !orgAddProperty &&
            <Upload/>
            }
            {
               orgAddProperty &&
              <AddProperty /> 
            }
            {
              editProperty &&
              <EditProperty />
            } 
      </>
    )
   }

   export default LicensePreview;