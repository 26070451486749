import { useState, useContext, useEffect } from "react";
import { Grid, Form, Accordion, Icon, Input } from 'semantic-ui-react';
import ContextData from "./store/context-data";
import AutoCompleteCountry from "./Components/AutoCompleteCountry";
import AutoCompleteState from "./Components/AutoCompleteState";
import AutoCompleteCity from "./Components/AutoCompleteCity";
import AutoCompleteArea from "./Components/AutoCompleteArea";

const FormPropertyAddress = (props) => {
  const ctx = useContext(ContextData);
  const [editProperty] = useState(localStorage.getItem('editProperty') ? JSON.parse(localStorage.getItem('editProperty')) : '');
  const [editData] = useState(localStorage.getItem('editData') ? JSON.parse(localStorage.getItem('editData')) : '');
  const [localStrProp] = useState(sessionStorage.getItem('newProperty') ? JSON.parse(sessionStorage.getItem('newProperty')) : '');
  const sessionOrgDetails = sessionStorage.getItem('SessionOrgDetails') ? JSON.parse(sessionStorage.getItem('SessionOrgDetails')) : '';
  // const sessionProperty = sessionStorage.getItem('Sessionproperty')? sessionStorage.getItem('Sessionproperty'):[];
  //  console.log('local store prop',localStrProp);
  // const [propCountry,setPropCountry] = useState((sessionOrgDetails && sessionOrgDetails.OrganizationDetails.Country) || '')
  // console.log('local strp ----',localStrProp);
  const [AddressLine1, setAddressLine1] = useState((sessionOrgDetails && sessionOrgDetails.OrganizationDetails.Address.AddressLine1) || '');
  const [AddressLine2, setAddressLine2] = useState((sessionOrgDetails && sessionOrgDetails.OrganizationDetails.Address.AddressLine2) || '');
  const [AddressLine3, setAddressLine3] = useState((sessionOrgDetails && sessionOrgDetails.OrganizationDetails.Address.AddressLine3) || '');
  const [AreaCode, setAreaCode] = useState((sessionOrgDetails ? Number(sessionOrgDetails.OrganizationDetails.Address.AreaCode) : ''));
  const [AreaName, setAreaName] = useState((sessionOrgDetails ? sessionOrgDetails.OrganizationDetails.Address.AreaName : ''));
  const [CityCode, setCityCode] = useState(sessionOrgDetails ? Number(sessionOrgDetails.OrganizationDetails.Address.CityCode) : '');
  const [CityName, setCityName] = useState(sessionOrgDetails ? sessionOrgDetails.OrganizationDetails.Address.CityName : '');
  const [StateCode, setStateCode] = useState(sessionOrgDetails ? Number(sessionOrgDetails.OrganizationDetails.Address.StateCode) : '');
  const [CountryCode, setCountryCode] = useState((sessionOrgDetails && Number(sessionOrgDetails.OrganizationDetails.Address.CountryCode)) || '');
  const [Landmark, setLandmark] = useState((sessionOrgDetails ? sessionOrgDetails.OrganizationDetails.Address.Landmark : ''));
  const [ZipCode, setZipCode] = useState((sessionOrgDetails ? sessionOrgDetails.OrganizationDetails.Address.ZipCode : ''));
  const [Latitude, setLatitude] = useState((sessionStorage.getItem('newProperty') && localStrProp.PropertyDetails.formPropAdrsData.Address.Latitude) || '');
  const [Longitude, setLongitude] = useState((sessionStorage.getItem('newProperty') && localStrProp.PropertyDetails.formPropAdrsData.Address.Longitude) || '');
  const [MobileNo, setMobileNo] = useState((sessionOrgDetails ? sessionOrgDetails.OrganizationDetails.ContactDetails.MobileNo : ''));
  const [MobileCountryCode, setMobileCountryCode] = useState((sessionOrgDetails && sessionOrgDetails.OrganizationDetails.ContactDetails.MobileCountryCode) || '');
  const [TelephoneCountryCode, setTelephoneCountryCode] = useState((sessionOrgDetails && sessionOrgDetails.OrganizationDetails.ContactDetails.MobileCountryCode) || '');
  const [TelephoneAreaCode, setTelephoneAreaCode] = useState((sessionOrgDetails && sessionOrgDetails.OrganizationDetails.ContactDetails.TelephoneAreaCode) || '');
  const [TelephoneNo, setTelphoneNo] = useState((sessionOrgDetails && sessionOrgDetails.OrganizationDetails.ContactDetails.TelephoneNo) || '');
  const [EmailId, setEmailID] = useState((sessionOrgDetails && sessionOrgDetails.OrganizationDetails.ContactDetails.EmailId) || '');
  const [Website, setWebsite] = useState((sessionOrgDetails && sessionOrgDetails.OrganizationDetails.ContactDetails.Website) || '');
  const [stateMaster, setStateMaster] = useState([]);
  const [enteredCountry, setCountry] = useState();
  //const [cityMaster,setCityMaster] = useState(); 
  const [enteredState, setEnteredState] = useState();
  const [ObjPropertyAdrs, setObjPropertyAdrs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);


  useEffect(() => {
    if (editData && !localStrProp) {
      console.log("con1", editData)
      setAddressLine1(editData.propertyDetails.address.addressLine2);
      setAddressLine2(editData.propertyDetails.address.addressLine2);
      setAddressLine3(editData.propertyDetails.address.addressLine3);
      setLatitude(editData.propertyDetails.address.latitude);
      setLongitude(editData.propertyDetails.address.longitude);
      setMobileCountryCode(editData.propertyDetails.contactDetails.mobileCountryCode);
      setTelephoneCountryCode(editData.propertyDetails.contactDetails.telephoneCountryCode);
      setTelephoneAreaCode(editData.propertyDetails.contactDetails.telephoneAreaCode);
      setTelphoneNo(editData.propertyDetails.contactDetails.telephoneNo);
      setEmailID(editData && editData.propertyDetails.contactDetails.emailId);
      setWebsite(editData.propertyDetails.contactDetails.website);

    }
    if (localStrProp) {
      console.log("con2", localStrProp)
      //   setPropCountry(localStrProp.PropertyDetails.formPropAdrsData.Address.Country );
      setAddressLine1(localStrProp.PropertyDetails.formPropAdrsData.Address.AddressLine1);
      setAddressLine2(localStrProp.PropertyDetails.formPropAdrsData.Address.AddressLine2);
      setAddressLine3(localStrProp.PropertyDetails.formPropAdrsData.Address.AddressLine3);
      setAreaCode(localStrProp.PropertyDetails.formPropAdrsData.Address.AreaCode);
      setAreaName(localStrProp.PropertyDetails.formPropAdrsData.Address.AreaName);
      setCityCode(localStrProp.PropertyDetails.formPropAdrsData.Address.CityCode);
      setCityName(localStrProp.PropertyDetails.formPropAdrsData.Address.CityName);
      setStateCode(localStrProp.PropertyDetails.formPropAdrsData.Address.StateCode)
      setCountryCode(localStrProp.PropertyDetails.formPropAdrsData.Address.CountryCode);
      setLandmark(localStrProp.PropertyDetails.formPropAdrsData.Address.Landmark);
      setZipCode(localStrProp.PropertyDetails.formPropAdrsData.Address.ZipCode);
      setLatitude(localStrProp.PropertyDetails.formPropAdrsData.Address.Latitude);
      setLongitude(localStrProp.PropertyDetails.formPropAdrsData.Address.Longitude);
      setMobileNo(localStrProp.PropertyDetails.formPropAdrsData.ContactDetails.MobileNo);
      setMobileCountryCode(localStrProp.PropertyDetails.formPropAdrsData.ContactDetails.MobileCountryCode);
      setTelephoneCountryCode(localStrProp.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneCountryCode);
      setTelephoneAreaCode(localStrProp.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneAreaCode);
      setTelphoneNo(localStrProp.PropertyDetails.formPropAdrsData.ContactDetails.TelephoneNo)
      setEmailID(localStrProp.PropertyDetails.formPropAdrsData.ContactDetails.EmailId);
      setWebsite(localStrProp.PropertyDetails.formPropAdrsData.ContactDetails.Website);
      setEnteredState(localStrProp.PropertyDetails.formPropAdrsData.Address.State);

    }

    if (editProperty && !localStrProp) {
      console.log("con3", editProperty)
      setStateCode(editProperty.propertyDetails.address.stateCode);
      setZipCode(editProperty.propertyDetails.address.zipCode);
      setLatitude(editProperty.propertyDetails.address.latitude);
      setAddressLine1(editProperty.propertyDetails.address.addressLine2);
      setAddressLine2(editProperty.propertyDetails.address.addressLine2);
      setAddressLine3(editProperty.propertyDetails.address.addressLine3);
      setLandmark(editProperty.propertyDetails.address.landmark);
      setLongitude(editProperty.propertyDetails.address.longitude);
      setMobileCountryCode(editProperty.propertyDetails.contactDetails.mobileCountryCode);
      setMobileNo(editProperty.propertyDetails.contactDetails.mobileNo);
      setTelephoneCountryCode(editProperty.propertyDetails.contactDetails.telephoneCountryCode);
      setTelephoneAreaCode(editProperty.propertyDetails.contactDetails.telephoneAreaCode);
      setTelphoneNo(editProperty.propertyDetails.contactDetails.telephoneNo);
      setEmailID(editProperty.propertyDetails.contactDetails.emailId);
      setWebsite(editProperty.propertyDetails.contactDetails.website);

    }
    if (props.editProperty && !localStrProp) {
      console.log("con4", props.editProperty)
      setStateCode(props.editProperty.propertyDetails.address.stateCode);
      setZipCode(props.editProperty.propertyDetails.address.zipCode);
      setLatitude(props.editProperty.propertyDetails.address.latitude);
      setAddressLine1(props.editProperty.propertyDetails.address.addressLine2);
      setAddressLine2(props.editProperty.propertyDetails.address.addressLine2);
      setAddressLine3(props.editProperty.propertyDetails.address.addressLine3);
      setLandmark(props.editProperty.propertyDetails.address.landmark);
      setLongitude(props.editProperty.propertyDetails.address.longitude);
      setMobileCountryCode(props.editProperty.propertyDetails.contactDetails.mobileCountryCode);
      setMobileNo(props.editProperty.propertyDetails.contactDetails.mobileNo);
      setTelephoneCountryCode(props.editProperty.propertyDetails.contactDetails.telephoneCountryCode);
      setTelephoneAreaCode(props.editProperty.propertyDetails.contactDetails.telephoneAreaCode);
      setTelphoneNo(props.editProperty.propertyDetails.contactDetails.telephoneNo);
      setEmailID(props.editProperty.propertyDetails.contactDetails.emailId);
      setWebsite(props.editProperty.propertyDetails.contactDetails.website);

    }

  }, [editData, localStrProp, editProperty, props.editProperty])

  const stateHandler = (statesData, country, code, tcode) => {

    setStateMaster(statesData);
    setCountry(country);
    setCountryCode(code);
    setTelephoneCountryCode(tcode);
    setMobileCountryCode(tcode);
    // console.log('Country passed data to New Property',country,code, tcode ); 

  }
  const cityHandler = (citiesData, state, stcode) => {

    // setCityMaster(citiesData);
    setEnteredState(state);
    setStateCode(stcode);
    // console.log('Passed Cities Data to New Property ')
    // console.log(state);
  }
  const enteredCityHandler = (city, code) => {

    setCityName(city);
    setCityCode(code);
  }

  const enteredAreaHandler = (area, code, areatelecode) => {
    //  console.log('area , area code, tele area code',area,code, areatelecode);
    setAreaName(area);
    setAreaCode(code);
    setTelephoneAreaCode(areatelecode);
  }


  const handleClick = (e, titleProps) => {
    const { index } = titleProps;

    // const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex);
  }

  useEffect(() => {
    setObjPropertyAdrs(

      {
        Address: {
          AddressLine1: AddressLine1,
          AddressLine2: AddressLine2,
          AddressLine3: AddressLine3,
          AreaCode: AreaCode,
          AreaName: AreaName,
          CityCode: CityCode,
          CityName: CityName,
          StateCode: StateCode,
          CountryCode: CountryCode,
          ZipCode: ZipCode,
          Landmark: Landmark,
          Latitude: Latitude,
          Longitude: Longitude,
          Country: enteredCountry,
          State: enteredState
        },
        ContactDetails: {
          MobileCountryCode: MobileCountryCode,
          MobileNo: MobileNo,
          TelephoneCountryCode: TelephoneCountryCode,
          TelephoneAreaCode: TelephoneAreaCode,
          TelephoneNo: TelephoneNo,
          EmailId: EmailId,
          Website: Website
        },

      }
    )
  }, [CountryCode, AreaName, AreaCode,
    CityCode, CityName, StateCode, ZipCode,
    AddressLine1, AddressLine2, AddressLine3,
    TelephoneNo, EmailId, MobileNo, Latitude,
    Longitude, Website, localStrProp,
    Landmark,
    MobileCountryCode,
    TelephoneAreaCode,
    TelephoneCountryCode,
    enteredCountry,
    enteredState,
  ])

  useEffect(() => {
    props.formPropertyAdrsHandler(ObjPropertyAdrs);
    //console.log('Property Address');
    //console.log(ObjPropertyAdrs);
  }, [ObjPropertyAdrs, props])



  return (
    <>
      {
        /*Accordion starts here */
      }
      <Accordion>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
        >
          <Icon name='dropdown' />
          <b> Address & Contact Details </b>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <Grid>
            <Grid.Row columns={2} style={{ paddingLeft: '50px' }}>
              {/* Column left starts*/}
              <Grid.Column width={6}>
                <Form>
                  <Grid.Row style={{ marginBottom: '10px' }}> <h5>Address Details</h5></Grid.Row>
                  <AutoCompleteCountry
                    APIToken={ctx.APIToken}
                    CountryStateHandler={stateHandler}
                    country={enteredCountry}
                    editProperty={props.editProperty}
                  />




                  <Grid.Row>

                    <AutoCompleteState
                      APIToken={ctx.APIToken}
                      stateMaster={stateMaster}
                      StateCityHandler={cityHandler}
                      editProperty={props.editProperty}
                    />

                  </Grid.Row>
                  <Grid.Row>

                    <AutoCompleteCity
                      APIToken={ctx.APIToken}
                      cityHandler={enteredCityHandler}
                      stateCode={StateCode}
                      editProperty={props.editProperty}
                    />
                  </Grid.Row>

                  <Grid.Row>

                    <AutoCompleteArea
                      APIToken={ctx.APIToken}
                      areaHandler={enteredAreaHandler}
                      cityCode={CityCode}
                      editProperty={props.editProperty}
                    />

                  </Grid.Row>
                  <Form.Group inline key='zip'>
                    <label style={{ marginRight: '11px' }}>Zip Code</label>
                    <Input
                      defaultValue={ZipCode}
                      onChange={(e) => { setZipCode(e.target.value) }}
                    />
                  </Form.Group>
                  <Form.Group inline key='lat'>
                    <label style={{ marginRight: '15px' }}> Latitude</label>
                    <Input
                      defaultValue={Latitude}
                      onChange={(e) => {
                        setLatitude(e.target.value);
                        // console.log(e.target.value);
                      }}
                    />
                  </Form.Group>

                </Form>
              </Grid.Column>
              {/* Column left ends*/}
              {/* Column right starts*/}
              <Grid.Column width={7}>
                <Form>

                  <Grid.Row style={{ marginTop: '50px' }}>
                    <h5>Outlet Address</h5>

                  </Grid.Row>
                  <Grid.Row style={{ marginTop: '10px' }}>
                    <Form.Group >
                      <Input style={{ width: '250px' }}
                        defaultValue={AddressLine1}
                        onChange={(e) => { setAddressLine1(e.target.value); }}
                        key='add1'
                      />
                    </Form.Group>
                  </Grid.Row>

                  <Grid.Row>
                    <Form.Group>
                      <Input style={{ width: '250px' }}
                        defaultValue={AddressLine2}
                        onChange={(e) => { setAddressLine2(e.target.value); }}
                        key='add2'
                      />
                    </Form.Group>
                  </Grid.Row>

                  <Grid.Row>
                    <Form.Group>
                      <Input style={{ width: '250px' }}
                        defaultValue={AddressLine3}
                        onChange={(e) => { setAddressLine3(e.target.value); }}
                        key='add3'
                      />
                    </Form.Group>

                  </Grid.Row>
                  <Grid.Row>
                    <Form.Group>
                      <Input style={{ width: '250px' }}
                        placeholder='Land Mark'
                        defaultValue={Landmark}
                        onChange={(e) => { setLandmark(e.target.value); }}
                        key='propland'
                      />
                    </Form.Group>
                  </Grid.Row>
                  <Form.Group inline >
                    <label>Longitude</label>
                    <Input style={{ width: '170px' }}
                      defaultValue={Longitude}
                      placeholder=''
                      onChange={(e) => {
                        setLongitude(e.target.value);
                      }}
                      key='longit'
                    />
                  </Form.Group>
                </Form>
              </Grid.Column>
              {/* Column right ends*/}
              <Grid.Row>
                <Grid.Column>


                </Grid.Column>
              </Grid.Row>

            </Grid.Row>
            <h4>Contact Details</h4>
            <Grid.Row style={{ paddingLeft: '50px' }}>

              <Form>
                <Form.Group inline >
                  <label style={{ width: '140px' }}> Contact Mobile No</label>
                  <Form.Input
                    width={3}
                    defaultValue={MobileCountryCode}
                    id='mcountrycode'
                    onChange={(e) => { setMobileCountryCode(e.target.value) }}
                    key='mcc'
                  />
                  <Form.Input placeholder='Mobile No'
                    width={9}
                    defaultValue={MobileNo}
                    onChange={(e) => {
                      setMobileNo(e.target.value);
                      setMobileCountryCode(document.getElementById('mcountrycode').value);
                      //  console.log(document.getElementById('mcountrycode').value);
                    }}
                    key='mbn'
                  />
                </Form.Group>
                <Form.Group inline>

                  <label style={{ width: '200px' }}>Contact Telephone No</label>
                  <Form.Input width={3}
                    defaultValue={TelephoneCountryCode}
                    onChange={(e) => { setTelephoneCountryCode(e.target.value) }}
                    key='tphncc'
                  />
                  <Form.Input
                    width={4}
                    defaultValue={TelephoneAreaCode}
                    onChange={(e) => { setTelephoneAreaCode(e.target.value) }}
                    key='tacc'
                  />
                  <Form.Input
                    defaultValue={TelephoneNo}
                    onChange={(e) => {
                      setTelphoneNo(e.target.value);
                      setTelephoneCountryCode(document.getElementById('mcountrycode').value);
                    }}
                    key='tcco'
                  />
                </Form.Group>
                <Form.Group inline key='emailprop'>
                  <label style={{ marginRight: '95px' }}> Email ID  </label>
                  <Form.Input
                    defaultValue={EmailId}
                    onChange={(e) => { setEmailID(e.target.value) }}
                  />
                </Form.Group>

                <Form.Group inline key='webprop'>
                  <label style={{ marginRight: '95px' }}>
                    Website
                  </label>
                  <Form.Input
                    value={Website}  // Use 'value' instead of 'defaultValue'
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </Form.Group>

              </Form>
            </Grid.Row>
          </Grid>
        </Accordion.Content>
      </Accordion>
    </>
  )
}

export default FormPropertyAddress;
