import { useState,useEffect } from "react";
import { Container,Button,Grid,Icon } from 'semantic-ui-react';
import { Link } from "react-router-dom";

const Result = (props) => {
 const [resultData] = useState(props.data);
 const [message,setMessage] = useState("Organization Created Successfully");

 useEffect(()=>{
    
        resultData.response.details.forEach((detail,id) => {
           ((detail.info==='Organization Modified - Success') &&  (detail.info!=='Create Property Success') && setMessage('Edited Organization Successfully')) ||
           ((detail.info==='Create Organization Success') && (id===0) &&  setMessage('Created Organization Successfully')) ||
           ((detail.info===('Create Property Success') && (id === 1)  && setMessage('Created Property Successfully')))
           if(detail.info==='Property Modified Success'){
            setMessage('Updated Property Successfully');
           }
        }
     )

 },[resultData.response.details])
 if( props.data.message==="Success" ) {
    return(
        <>
        <Container>
             <Grid>
             <Grid.Row columns ={1} style={{marginTop:'100px'}}>
                
                <Grid.Column width={16} textAlign='center'>  
                        { 
                         <h3> <span style={{color:'green'}}>{message}</span></h3>   

                        } </Grid.Column>
                
              </Grid.Row>
                 <Grid.Row>
                     <Grid.Column>
                         {
                              resultData.response.details.map((detail,idd) => {
                                    return( 
                                            
                                            <h4 key={idd}>   
                                              {detail.status === true &&   <Icon name='check circle' color='green' size='large'/>} 
                                              {detail.status === false &&   <Icon name='close icon' color='red' size='large'/>} 
                                              {detail.info}
                                           </h4> 
                                        
                                        )
                              })
                         } 
                     </Grid.Column>
                 </Grid.Row>
                 <Grid.Row style={{marginBottom:'40px'}}>
               
                   <Grid.Column width={4} floated='right'> 
                   <Link  to='/'> <Button color='blue' onClick={localStorage.removeItem('orgDetails')}>Finish </Button></Link>
                   </Grid.Column>
                  
               </Grid.Row>
             </Grid>
        </Container>
        </>
    )}else{

        return(
            <>
            
                <h4>{props.data.response.message}</h4>
            
            </>
        )
    }


}

export default Result;
