import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Form, Input } from 'semantic-ui-react';
import ContextData from "../store/context-data";
const AutoCompletePropReg = (props) => {
  // console.log("daspraks", props.selectedRegistrationId)
  // console.log("daspraks", props.selectedRegistrationTypeName)
  const ctx = useContext(ContextData);
  const [propertyRegMaster, setPropertyRegMaster] = useState('');
  const [Loader, setLoader] = useState(false);
  // const[autoCompStyle,setAutoCompStyle] = useState({display:'block'});
  const [propRegID, setEnteredPropRegCode] = useState('');
  const [enteredPropType, setEnteredPropType] = useState('');

  // const [searchTerm,setSearchTerm] = useState(''); 
  const [cardReg] = useState([{
    enteredPropRegCode: '',
    enteredPropType: ''
  }]);
  //const [dfValue,setDfValue] = useState(''); 
  //  const [propsclearInput,setClearInput]=useState(props.clearInput);
  const [propsformDataHandler] = useState(() => props.formDataHandler);
  useEffect(() => {
    setEnteredPropType(props.selectedRegistrationTypeName || '');
  }, [props.selectedRegistrationTypeName]);
  useEffect(() => {
    setEnteredPropRegCode(props.selectedRegistrationId || '');
  }, [props.selectedRegistrationId]);
  

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetRegistrationTypeList`, {
      headers: { Authorization: `Bearer ${ctx.APIToken}` }
    })
      .then((response) => {
        //console.log(response.data);
        setPropertyRegMaster(response.data);
        setLoader(true);
      }).catch((error) => {
        console.log(error);
      });
  }, [setPropertyRegMaster, ctx.APIToken]);

  useEffect(() => {
    //  setDfValue(enteredPropType);
    propertyRegMaster &&
      propertyRegMaster.response.registrationTypes.forEach((item) => {
        // console.log(enteredPropType);
        // console.log(item.registrationTypeId); 
        enteredPropType === item.registrationTypeName &&
          setEnteredPropRegCode(item.registrationTypeId);
      })

  }, [enteredPropType, propertyRegMaster])


  useEffect(() => {
    if (propRegID && enteredPropType) {
      propsformDataHandler(propRegID, enteredPropType, cardReg);
    }
    //console.log('Called Property Reg Data handler .. see below ');  
    // console.log(props.clearInput)
    // setClearInput(props.clearInput)

  }, [propRegID, enteredPropType, cardReg, propsformDataHandler])

  useEffect(() => {
    if (props.clearInput) setEnteredPropType('');
    console.log(props.clearInput)
  }, [props.clearInput])

  if (Loader) {
    return (
      <>
        <Form.Group>
          <Input list="reg" name="register" id="register"
            autoComplete='off'
            value={enteredPropType}
            placeholder={'select type'}
            onChange={(e) => {
              console.log(e.target.value);
              setEnteredPropType(e.target.value);
            }}
          // onBlur={(e)=>{props.clearInput && (e.target.value = '')}}
          />
          <datalist id="reg">
            {propertyRegMaster.response.registrationTypes.map((reg, idx) => {
              return (
                <option key={idx} value={reg.registrationTypeName} />
              )
            }
            )
            }
          </datalist>
        </Form.Group>
      </>

    )
  }
  else {
    return (<>
      Loading...
    </>
    );
  }
}

export default AutoCompletePropReg;