import { useState, useEffect,useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Container, Form, Grid, 
  Pagination,Button, Dropdown,Table, 
  TableHeader, TableHeaderCell, Input,Card, Icon,Image } from "semantic-ui-react";
import ContextData from '../../store/context-data';

const CityMaster = () => {
  const ctx = useContext(ContextData);
  const [stateMaster,setStateMaster] = useState('');
  const [userRightsDashboard, setUserRightsDashboard] = useState(sessionStorage.getItem('userRights')?JSON.parse(sessionStorage.getItem('userRights')):[])
  const [countryMaster,setCountryMaster] = useState();
  const [countryOption, setCountryOption] = useState(1); 
  const [currentPageNumber,setCurrentPageNumber] = useState(1);
  const [cityMasterList,setCityMasterList] = useState('');
  const [cityMasterPageDetails,setCityMasterPageDetails] = useState(''); 
  const [countryDetails,setCountryDetails] = useState('');
  const [countryName, setCountryName] = useState('');
  const [countryCode,setCountryCode] = useState('');
  const [stateName,setStateName] = useState('');
  const [stateCode,setStateCode] = useState('')
  const [cityName,setCityName] = useState('');
  const [cityCode,setCityCode] = useState('');
  const [noOfRows, setNoOfRows] = useState(24);
  const [searchTerm,setSearchTerm] = useState('');
  const [Edit, setEdit] = useState();
  const [Add,setAdd] = useState();
  const [View, setView] = useState();
  const [AddArea, setAddArea] = useState();
  const [userType,setUserType] = useState();
  
  const countryList= cityMasterPageDetails && cityMasterPageDetails.countryList.map((cn,idc)=> { 
    return  {
    key: idc,
    text: cn.countryName,
    value: cn.countryCode,
  }})
  
  
  const stateOptions = stateMaster && stateMaster.stateList.map((state,idc)=> { 
    return  {
    key: idc,
    text: state.stateName,
    value: state.stateCode,
  }})
  



  //let stateOptions = [...new Set(stateOptionsArr)];
  const selectOptions= [
    {
    key: 1,
    text: '24',
    value:24,
  }, 
  {
    key: 2,
    text: 48,
    value: 48,
  },
  {
    key: 3,
    text: 96,
    value: 96,
  }
  ]
  useEffect(()=>{
    cityMasterPageDetails && cityMasterPageDetails.pageControlRights.forEach(item =>{
      //console.log('city master ',item)
       item.controlName === 'Add' && setAdd(item.visible)
       item.controlName === 'Edit' && setEdit(item.visible)
       item.controlName === 'View' && setView(item.visible)
       item.controlName === 'Add Area' && setAddArea(item.visible) 
    })
    userRightsDashboard && userRightsDashboard.forEach(item=>{
       item.userType && setUserType(item)
    })
    
  },[cityMasterPageDetails])

  //useEffect(()=>{
  //  ctx.APIToken &&
  //  axios
  //  .get(
  //   `${process.env.REACT_APP_BASE_API_URL}Master/GetCountryMasterList`,{
  //     params:{
  //      Status:countryOption,
  //      CurrentPageNumber:currentPageNumber,
  //      NoOfRowsPerPage:noOfRows,
  //    //  CountryCode:countryCode,
  //      CountryName:countryName
  //     }, 
  //    headers: { Authorization: `Bearer ${ctx.APIToken}`}
  //   }
  //  )
  // .then((response) => {
  ////   setLoaderToggle(false);
  //  const data = response.data.response;
  //  //console.log(data)
  //   setCountryMaster(data);
  // }).catch((error)=>{
  //   //console.log(error);
  // }) 
  //},[])

  useEffect(()=>{
    ctx.APIToken &&
    axios
    .get(
     `${process.env.REACT_APP_BASE_API_URL}Master/GetStateMasterList`,{
       params:{
        Status:countryOption,
        CurrentPageNumber:currentPageNumber,
        NoOfRowsPerPage:noOfRows,
        ...(countryCode && { CountryCode:countryCode}),  
        ...(stateName && { StateName:stateName}), 
       }, 
      headers: { Authorization: `Bearer ${ctx.APIToken}`}
     }
    )
   .then((response) => {
  //   setLoaderToggle(false);
    const data = response.data.response;
    //console.log(data)
     setStateMaster(data);
   }).catch((error)=>{
     //console.log(error);
   }) 
  },[countryCode])
  
//GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/'
   // countryList - cn code , cn name, languageList 
      useEffect(()=>{
         ctx.APIToken &&
         axios
         .get(
          `${process.env.REACT_APP_BASE_API_URL}Master/GetCityMasterPageDetails`,{
           headers: { Authorization: `Bearer ${ctx.APIToken}`}
          }
         )
         .then((response) => {
         //   setLoaderToggle(false);
           const data = response.data.response;
         //console.log('city master oage details ',data)
          setCityMasterPageDetails(data);  
         }).catch((error)=>{
          //console.log(error);
         }) 
        },[])
//curl --location --request GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/GetCityMasterList?'
   //Status=1&CurrentPageNumber=1&NoOfRowsPerPage=20&CountryCode=1&StateCode=1&CityName=Bang
    useEffect(()=>{
     ctx.APIToken &&
    axios
    .get(
     `${process.env.REACT_APP_BASE_API_URL}Master/GetCityMasterList`,{ 
        params:{
          Status:countryOption,
          CurrentPageNumber:currentPageNumber,
          NoOfRowsPerPage:noOfRows,
          ...(countryCode && {CountryCode:countryCode}),
          ...(stateCode && {StateCode:stateCode}),
          ...(cityName && {CityName: cityName})
        },
      headers: { Authorization: `Bearer ${ctx.APIToken}`}
     }
    )
   .then((response) => {
   //   setLoaderToggle(false);
    const data = response.data.response;
    //console.log('city master list ',data)
     setCityMasterList(data);  
   }).catch((error)=>{
     //console.log(error);
   }) 
   },[currentPageNumber, noOfRows])

   const searchHandler = () =>{ 
    ctx.APIToken &&
    axios
    .get(
     `${process.env.REACT_APP_BASE_API_URL}Master/GetCityMasterList`,{ 
      params:{
        Status:countryOption,
        CurrentPageNumber:currentPageNumber,
        NoOfRowsPerPage:noOfRows, 
        ...(countryCode && {CountryCode: countryCode}),
        ...(stateCode && {StateCode:stateCode}),
        ...(cityName && {CityName:cityName}),
      },
      headers: { Authorization: `Bearer ${ctx.APIToken}`}
     }
    )
   .then((response) => {
   //   setLoaderToggle(false);
    const data = response.data.response;
   
     setCityMasterList(data);  
   }).catch((error)=>{
     //console.log(error);
   }) 
   }

   const searchClearHandler = (buttonname) =>{
    ctx.APIToken &&
    axios
    .get(
     `${process.env.REACT_APP_BASE_API_URL}Master/GetCityMasterList`,{ 
      params:{
        Status:countryOption,
        CurrentPageNumber:currentPageNumber,
        NoOfRowsPerPage:noOfRows, 
        ...(countryCode && {CountryCode: countryCode}),
        ...(stateCode && {StateCode:stateCode}), 
        ...((buttonname === 'cityName') && {CityName:''} ||
        cityName && {CityName:cityName}
        ),
      },
      headers: { Authorization: `Bearer ${ctx.APIToken}`}
     }
    )
   .then((response) => {
   //   setLoaderToggle(false);
    const data = response.data.response;
   
     setCityMasterList(data);  
   }).catch((error)=>{
     //console.log(error);
   }) 
   }
  
     
 
  //GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/GetCountryDetails'
//   useEffect(()=>{
//    ctx.APIToken &&
//    axios
//    .get(
//     `${process.env.REACT_APP_BASE_API_URL}Master/GetCountryDetails`,{
//      params:{CountryCode:countryCode},
//      headers: { Authorization: `Bearer ${ctx.APIToken}`}
//     }
//    )
//   .then((response) => {
//  //   setLoaderToggle(false);
//    const data = response.data;
//    console.log(data)
//     setCountryDetails(data);  
//   }).catch((error)=>{
//     //console.log(error);
//   }) 
//  },[]) 
    return(
      <Container >
        <Grid>
                <Grid.Row columns={1} >
                     <Grid.Column textAlign="center" style={{marginTop:'10%', paddingTop:'10px'}}>
                         <h3>City Master</h3>
                     </Grid.Column> 
                 </Grid.Row>
                 <Grid.Row columns={4} >
                   <Grid.Column width={9}> 
                     <Form inline>
                       <Form.Group>
                      
                      <Form.Field inline> 
                      <Dropdown  
                              selectOnBlur={false} 
                              placeholder='Country' 
                              search  
                              options={countryList}
                              selection 
                              clearable
                              value={countryCode}
                              onChange={(e,data)=>{
                                setCountryCode(data.value); 
                              }}   
                              /> 
                      </Form.Field> 
                      <Form.Field inline> 
                      <Dropdown 
                              selectOnBlur={false}   
                              placeholder='State' 
                              search  
                              options={stateOptions}
                              selection
                              clearable 
                              value={stateCode}
                              onChange={(e,data)=>{
                                setStateCode(data.value); 
                              }}   
                              /> 
                         
                      </Form.Field> 
                      <Form.Field inline> 
                         <Input
                             value={cityName}
                             icon={cityName && <Icon name='delete' link onClick={(e,data)=>{ 
                              setCityName(''); 
                              searchClearHandler('cityName');
                            }} />} 
                         placeholder='Search by City Name'
                         onChange={(e,data)=>{setCityName(data.value)}}
                         /> 
                      </Form.Field> 
                     
                       </Form.Group>
                    
                     </Form> 
                      </Grid.Column> 
                      <Grid.Column width={5} style={{paddingTop:'10px' }}>
                   <Form> 
                <Form.Group inline key='orgstatus'>  
                <label>Status</label>
                 <Form.Field>
                
                 <Input 
                  type="radio" id="active" name="org-status" value='1' checked ={countryOption===1}
                      onChange={(e)=>{ 
                          setCountryOption(Number(e.target.value));
                          setCurrentPageNumber(1);  
                         // console.log(e.target.value);      
                     }}
                    />
                 <label>Active</label>
                 </Form.Field>
                 
                 <Form.Field>
                 <Input type="radio" id="passive" name="org-status" value='0'
                    checked ={countryOption===0}
                    onChange={(e)=>{
                      setCountryOption(Number(e.target.value)); 
                      setCurrentPageNumber(1);  
                        // console.log(e.target.value); 
                        }}
                 />
                  <label >Passive</label>
                 </Form.Field>
                    <Form.Field> 
                   <Input type="radio" id="both" name="org-status" value='2'
                    checked ={countryOption===2} 
                    onChange={(e)=>{
                      setCountryOption(Number(e.target.value)); 
                      setCurrentPageNumber(1);  
                        // console.log(e.target.value);   
                        }}
                     />
                     <label >Both</label> 
                   </Form.Field>
               
                 
                </Form.Group> 
              </Form>
                   </Grid.Column> 
                   <Grid.Column width={2} floated="right" style={{ position:'absolute', right:'40px'}}>
                 <Button onClick={searchHandler} 
                 color='blue'
                 style={{width:'110px'}}>
                       Search
                     </Button> 
                   </Grid.Column> 
                 </Grid.Row>
                 <Grid.Row>
                 <Grid.Column width={2} style={{paddingRight:'0px',marginRight:'0px'}}>
                   <Form inline>
                     <Form.Field inline>  
                         <label> Select</label>
                         <Dropdown
                           selectOnBlur={false} 
                       compact
                       placeholder="24"
                       defaultValue={10} 
                       selection 
                       options={selectOptions} 
                       onChange={(e, data)=>{
                        setNoOfRows(data.value);
                        setCurrentPageNumber(1);
                        window.scrollTo(0, 0);
                       }} 
                      /> 
                      </Form.Field>  
                     </Form>  
                  </Grid.Column> 
                 <Grid.Column width={3} floated="left" style={{paddingLeft:'0px'}}>
                       
                       <Pagination 
                   size='small' 
                   boundaryRange={null}
                   //defaultActivePage={1}
                   activePage={currentPageNumber}
                   color='blue'
                   ellipsisItem={null}
                   firstItem={null}
                   lastItem={cityMasterList && cityMasterList.paginationDetail.totalPages}
                   siblingRange={1}
                   totalPages={cityMasterList && cityMasterList.paginationDetail.totalPages}
                   onPageChange={ (e,data)=>{ setCurrentPageNumber(data.activePage);
  
                  }}
                 >  
             
                 </Pagination>
                       </Grid.Column> 
                  
                   <Grid.Column floated='right' textAlign="right" width={3} style={{ position:'absolute', right:'40px'}} >
                   { (Add || (userType && userType.userType === 2)) && <Link 
                    to={ {
                      pathname: "/AddNewCity", 
                      state: '',
                      countryData:cityMasterList,
                      button:'newCity'
                    }}
                   >   
                   <Button  
                    color='blue'
                   >
                       + New City
                     </Button> </Link>}
                   </Grid.Column> 
                 </Grid.Row>
                 <Grid.Row className="vcard-section" >
                  <Card.Group itemsPerRow={5} className='dash-card-links' 
                  style={{paddingTop:'2%'}}> 
                {     
                cityMasterList && 
                cityMasterList.cityList.map((field)=>{  
                      if(searchTerm===""){
                        return field} else if (
                    field.cityName.toLowerCase().includes(searchTerm.toLowerCase())
                   ) { 
                    return field; 
                   }
                 }).map((field, kid) => { 
                 
                      if(field)
                   return(
                     
                    <Card color='grey' key={field.countryCode} style={{marginLeft:'20px', width:'250px'}} 
                    >
                         <Card.Content>
                        { field.imageUrl && <Image
                             floated='left'
                             style={{width:'50px'}}
                             src={field.imageUrl}
                           />   || <Icon name='picture' color='grey' size='huge' style={{ float:'left'}}/>
                        }
                           <Card.Meta 
                           style={ field.status === 1?{color:'green',position:'absolute',right:'5px',top:'0'}:{color:'red', position:'absolute',right:'5px', top:'0'}}
                            floated='right'
                           >{field.status===1?'Active':'Passive'}
                           </Card.Meta>
                           <div 
                           >
                           <div style={{fontSize:'2vh'}}><b>{field.cityName}</b></div>
                          
                           </div>
                           <div style={{fontSize:'2vh'}}>
                           {field.stateName}-{field.countryName}
                           </div>       
                         </Card.Content>
                         <Card.Content extra> 
                           <div>  
                              { (Edit || (userType && userType.userType === 2)) &&  
                             <Link to={{
                              pathname: "/AddNewCity", 
                              state: field,
                              button:'edit'
                            }} 
                            // onClick={editPropertyHandler.bind(this,field.propertyId)}
                             style={{marginRight:'5%'}}
                             key='Edit'
                             > 
                              <Icon  name='edit' color='red' />
                             <span>  Edit </span>
                             </Link>
                             } 
                             { (View || (userType && userType.userType === 2)) &&  
                             <Link to={{
                              pathname: "/AddNewCity", 
                              state: field,
                              button:'view'
                            }} 
                              color='green'
                             style={{marginRight:'5%'}}
                             key='View'
                             >
                              <Icon  name='eye' color='green' />
                              <span> View </span> 
                             </Link>
                             } 
                              { (AddArea || (userType && userType.userType === 2)) &&  
                             <Link to={{
                               pathname: "/AddNewArea", 
                               state: field,
                               countryData:cityMasterPageDetails,
                               button:'add'
                              }}
                              color='green'
                             style={{marginRight:'5%'}}
                             key='add'
                             >
                              <Icon  name='plus' color='yellow' />
                              <span> Add Area </span> 
                             </Link>
                             } 
                           </div>
                          
                         </Card.Content>
                       </Card>    
                   ) 
                })
               }         
              </Card.Group> 
                  </Grid.Row>
               
        </Grid>
      </Container>
    )
    
}

export default CityMaster;