
import React, {useState,useEffect} from 'react';
import axios from 'axios';  
import {Form,Dropdown} from 'semantic-ui-react';

  const AutoCompleteCity = (props) => {
  const [editProperty,setEditProperty] = useState(localStorage.getItem('editProperty') ? JSON.parse(localStorage.getItem('editProperty')):'');
  const [localStrProp] = useState(sessionStorage.getItem('newProperty')?JSON.parse(sessionStorage.getItem('newProperty')):'');
  const [editData] = useState(localStorage.getItem('editData') ? JSON.parse(localStorage.getItem('editData')):'');
 // const [Loader,setLoader] =useState(false);
  //const[autoCompStyle,setAutoCompStyle] = useState({display:'block'});
  const[cityMaster,setCityMaster] = useState([]);
  const[cityCode,setCityCode] = useState();
  const [enteredCity,setEnteredCity] = useState((localStrProp && localStrProp.PropertyDetails.formPropAdrsData.Address.CityName) || (editData?editData.organizationDetails.address.cityName:''));
  const[orgDetails] = useState(sessionStorage.getItem('SessionOrgDetails') ? JSON.parse(sessionStorage.getItem('SessionOrgDetails')) : '');
  const [propsCityHandler] = useState(()=>props.cityHandler);
  const [cityOptions, setCityOptions] = useState([]);
  
  useState(()=>{ 
    setEditProperty(props.editProperty)
  },[ props.editProperty])
  
  useEffect(()=>{
    //console.log('State Code ===='+ props.stateCode); 
props.stateCode &&
    axios.get(`${process.env.REACT_APP_LUCID_API_URL}GetCityList`,{
      params: {
        'StateCode': props.stateCode, 
      }, 
      
        headers: { Authorization: `Bearer ${props.APIToken}`}},)
        .then((response)=>{
        setCityMaster(response.data);  
     
        }).catch((error)=>{
          console.log(error);
          alert(error)
        });
 },[props.stateCode]);

 

  useEffect(()=>{
    cityMaster.cities &&
    cityMaster.cities.forEach((item)=>{
      enteredCity === item.cityName &&
      setCityCode(item.cityCode);  })
     // propsCityHandler(enteredCity,cityCode);
   // console.log('Passing entered city details ---'+ enteredCity, cityCode);
   cityMaster.cities && setCityOptions( ()=> cityMaster.cities.map((city, idx)=>{
         return ({
          key:idx,
          text:city.cityName ,
          value: city.cityCode, 
         }
         )   
       } 
     ) )
  },[enteredCity,cityCode,cityMaster.cities]);

  useEffect(()=>{
    cityCode && 
    propsCityHandler(enteredCity,cityCode);
  },[enteredCity, cityCode,propsCityHandler])

  useEffect(()=>{
    orgDetails &&
    setEnteredCity((orgDetails && orgDetails.OrganizationDetails.Address.CityName) || (editData && editData.organizationDetails.address.cityName) || '');
    orgDetails &&
    setCityCode((orgDetails && orgDetails.OrganizationDetails.Address.CityCode) || '');
    if(editProperty){
      setEnteredCity(editProperty.propertyDetails.address.cityName);
      setCityCode(editProperty.propertyDetails.address.cityCode);
    }
    if(props.editProperty){
      setEnteredCity(props.editProperty.propertyDetails.address.cityName);
      setCityCode(props.editProperty.propertyDetails.address.cityCode);
    }
    props.city && setEnteredCity(props.city);
    
  },[orgDetails, editData, editProperty,props.editProperty, props.city]);
    
  return (
           <>  
               <Form.Group inline>
               <label style={{marginRight:'37px'}}> 
                City
              </label> 
              <Dropdown style={{color:'black'}}
                placeholder={enteredCity} 
                search selection
                 options={cityOptions}
                 defaultValue={enteredCity} 
                       onChange={(e,data)=>{  
                        setEnteredCity(e.target.textContent); 
                          if(e.code === 'Enter'){ 
                            data.options.forEach(element => {
                                element.value === data.value && setEnteredCity(element.text)
                            });   
                        }   
                        
                           }}
                       />  
    </Form.Group>
            
             
     </>
  )}
   
  
  
export default AutoCompleteCity;
