import { Grid } from 'semantic-ui-react';

const Footer = () => {

    return (

        <Grid>
            <Grid.Row centered style={
                {
                    backgroundColor: '#51504F',
                    marginTop: '10px',
                    height: '25px',
                    fontSize: '12px',
                    padding: '3px',
                    color: 'white',
                    position: 'fixed',
                    right: '0',
                    bottom: '0',
                    width: '100%'   
                }
            }
            >
                <a style={{ textDecoration: "none" }} href="https://lucidpos.com" target="_blank">
                    <small style={{ color: "#FFFFFF", fontSize: "12px", fontWeight: "500" }}>Powered By <span style={{ color: "red" }}>LUCID</span></small>
                </a>
            </Grid.Row>
        </Grid>

    )

}

export default Footer;
