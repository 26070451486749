import { useState, useEffect,useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Container, Form, Grid, 
  Pagination,Button, Dropdown,Table, 
  TableHeader, TableHeaderCell, Input,Card, Icon,Image } from "semantic-ui-react";
import ContextData from '../../store/context-data';

const CountryMaster = () => {
  const ctx = useContext(ContextData);
  const [countryOption, setCountryOption] = useState(1);
  const [pageData,setPageData] = useState('');
  const [userRightsDashboard, setUserRightsDashboard] = useState(sessionStorage.getItem('userRights')?JSON.parse(sessionStorage.getItem('userRights')):[])
  const [countryMasterPageData,setCountryMasterPageData] = useState('');
  const [countryMaster,setCountryMaster] = useState('');
  const [countryDetails,setCountryDetails] = useState('');
  const [countryCode,setCountryCode] = useState('');
  const [searchTerm,setSearchTerm] = useState('');
  const [countryName, setCountryName] = useState('')
  const [currentPageNumber,setCurrentPageNumber] = useState(1)
  const [noOfRows, setNoOfRows] = useState(24);
  const [pageControlRights, setPageControlRights] = useState();
  const [Edit, setEdit] = useState();
  const [Add,setAdd] = useState();
  const [View, setView] = useState();
  const [AddState, setAddState] = useState();
  const [userType,setUserType] = useState();

  //console.log('user rights ', userRightsDashboard);
  const selectOptions= [
    {
    key: 1,
    text: 24,
    value: 24,
  }, 
  {
    key: 2,
    text: 48,
    value: 48,
  },
  {
    key: 3,
    text: 96,
    value: 96,
  }
  ]

  useEffect(()=>{
    pageControlRights && pageControlRights.forEach(item =>{
        // console.log(item)
       item.controlName === 'Add' && setAdd(item.visible)
       item.controlName === 'Edit' && setEdit(item.visible)
       item.controlName === 'View' && setView(item.visible)
       item.controlName === 'Add State' && setAddState(item.visible)
    })
    userRightsDashboard && userRightsDashboard.forEach(item=>{
       item.userType && setUserType(item)
    }) 
    
  },[pageControlRights])
 

//GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/'
 // check error with db admin
 // useEffect(()=>{
 //    ctx.APIToken &&
 //    axios
 //    .get(
 //     `${process.env.REACT_APP_BASE_API_URL}Master/GetRegionMasterPageDetails`,{

 //      headers: { Authorization: `Bearer ${ctx.APIToken}`}
 //     }
 //    )
 //    .then((response) => {
 //    //   setLoaderToggle(false);
 //      const data = response.data;
 //     console.log(data)
 //     setPageData(data);  
 //    }).catch((error)=>{
 //     //console.log(error);
 //    }) 
 //   },[])

  
    useEffect(()=>{
        ctx.APIToken &&
        axios
        .get(
         `${process.env.REACT_APP_BASE_API_URL}Master/GetCountryMasterPageDetails`,{      
          headers: { Authorization: `Bearer ${ctx.APIToken}`}
         }
        )
        .then((response) => {
        //   setLoaderToggle(false);
          const data = response.data;
        // console.log('Page country details',data)
         setCountryMasterPageData(data.response);
         const Rights = data.response.pageControlRights.map( item => {
        //  console.log(item.controlName)
          return(
                 item
          )
         })
         setPageControlRights(Rights);

        }).catch((error)=>{
         //console.log(error);
        }) 
       },[])
   //console.log(pageControlRights && pageControlRights)
//'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/GetCountryMasterList'
//Status=1&CurrentPageNumber=1&NoOfRowsPerPage=20&&CountryName=India'
  useEffect(()=>{
    ctx.APIToken &&
    axios
    .get(
     `${process.env.REACT_APP_BASE_API_URL}Master/GetCountryMasterList`,{
       params:{
        Status:countryOption,
        CurrentPageNumber:currentPageNumber,
        NoOfRowsPerPage:noOfRows,
        CountryName:countryName, 
       }, 
      headers: { Authorization: `Bearer ${ctx.APIToken}`}
     }
    )
   .then((response) => {
  //   setLoaderToggle(false);
    const data = response.data.response;
    //console.log(data)
     setCountryMaster(data);
   }).catch((error)=>{
     //console.log(error);
   }) 
  },[currentPageNumber,noOfRows])

  const searchHandler = () =>{
    axios
    .get(
     `${process.env.REACT_APP_BASE_API_URL}Master/GetCountryMasterList`,{
       params:{
        Status:countryOption,
        CurrentPageNumber:currentPageNumber,
        NoOfRowsPerPage:noOfRows,
        CountryName:countryName, 
       }, 
      headers: { Authorization: `Bearer ${ctx.APIToken}`}
     }
    )
   .then((response) => {
  //   setLoaderToggle(false);
    const data = response.data.response;
    //console.log(data)
     setCountryMaster(data);
   }).catch((error)=>{
     //console.log(error);
   }) 
  }

  const searchClear = () =>{
    axios
    .get(
     `${process.env.REACT_APP_BASE_API_URL}Master/GetCountryMasterList`,{
       params:{
        Status:countryOption,
        CurrentPageNumber:currentPageNumber,
        NoOfRowsPerPage:noOfRows,
        CountryName:'', 
       }, 
      headers: { Authorization: `Bearer ${ctx.APIToken}`}
     }
    )
   .then((response) => {
  //   setLoaderToggle(false);
    const data = response.data.response;
    //console.log(data)
     setCountryMaster(data);
   }).catch((error)=>{
     //console.log(error);
   }) 
  };

  //GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/GetCountryDetails'
  //  useEffect(()=>{
  //   ctx.APIToken &&
  //   axios
  //   .get(
  //    `${process.env.REACT_APP_BASE_API_URL}Master/GetCountryDetails`,{
  //     params:{CountryCode:countryCode},
  //     headers: { Authorization: `Bearer ${ctx.APIToken}`}
  //    }
  //   )
  //  .then((response) => {
  // //   setLoaderToggle(false);
  //   const data = response.data;
  //   console.log(data)
  //    setCountryDetails(data);  
  //  }).catch((error)=>{
  //    //console.log(error);
  //  }) 
  // },[])
 
   //console.log(
   // countryMasterPageData.pageControlRights.map(item =>{ 
   //   if(item.controlName === 'Add'){
   //    return item.enable 
   //    }}).filter(item => item)[0]
   //)
 
    return(
      <Container >
        <Grid>
                <Grid.Row columns={1} >
                     <Grid.Column textAlign="center" style={{marginTop:'10%', paddingTop:'10px'}}>
                         <h3>Country Master</h3>
                     </Grid.Column> 
                 </Grid.Row>
                 <Grid.Row columns={4} >
                   <Grid.Column width={5}> 
                     <Form inline>
                       <Form.Field inline> 
                         <Input 
                            icon={countryName && <Icon name='delete' link onClick={()=>{ 
                              setCountryName('');
                              searchClear();
                            }} />} 
                          value={countryName}      
                          placeholder='Country Name'
                          onChange={(e,data)=>{
                           setCountryName(data.value)
                         }} 
                         />
                         <Button color='blue'
                         onClick={searchHandler}
                         >
                            Search
                          </Button> 
                      </Form.Field> 
                     </Form> 
                      </Grid.Column> 
                     
                   <Grid.Column width={2} style={{paddingRight:'0px',marginRight:'0px'}}>
                   <Form inline>
                     <Form.Field inline>  
                         <label> Select</label>
                         <Dropdown
                       compact
                       placeholder="24"
                       defaultValue='24' 
                       selection 
                       options={selectOptions} 
                       onChange={(e, data)=>{
                        setNoOfRows(data.value);
                        setCurrentPageNumber(1); 
                        window.scrollTo(0, 0);
                       }} 
                      /> 
                      </Form.Field>  
                     </Form>  
                  </Grid.Column> 
                   <Grid.Column width={3} floated="left" style={{paddingLeft:'0px'}}>
                       
                   <Pagination 
               size='small' 
               boundaryRange={null}
               //defaultActivePage={1}
               activePage={currentPageNumber}
               color='blue'
               ellipsisItem={null}
               firstItem={1}
               lastItem={null}
               siblingRange={1}
               totalPages={countryMaster && countryMaster.paginationDetail.totalPages}
               onPageChange={ (e,data)=>{ setCurrentPageNumber(data.activePage);
              }}
             >  
             </Pagination>
                   </Grid.Column>
                   <Grid.Column width={3}>
                   { 
                    (Add || (userType && userType.userType === 2)) &&  
                     <Link 
                    to={ {
                      pathname: "/AddNewCountry", 
                     // state: regionData,
                      countryMaster:countryMasterPageData,
                    }}
                   > <Button color='blue'>
                       + New Country
                     </Button>
                     </Link>}
                   </Grid.Column> 
                 </Grid.Row>
                 <Grid.Row>
                   <Grid.Column>
                   <Form> 
                <Form.Group inline key='orgstatus'>  
                <label>Status</label>
                 <Form.Input 
                  type="radio" id="active" name="org-status" value='1' checked ={countryOption===1}
                      onChange={(e)=>{ 
                          setCountryOption(Number(e.target.value));
                          setCurrentPageNumber(1);  
                         // console.log(e.target.value);      
                     }}
                    />
                 <label>Active</label>
                 <Form.Input type="radio" id="passive" name="org-status" value='0'
                    checked ={countryOption===0}
                    onChange={(e)=>{
                      setCountryOption(Number(e.target.value)); 
                      setCurrentPageNumber(1);  
                      // console.log(e.target.value); 
                        }}
                 />
                   <label >Passive</label>
                   <Form.Input type="radio" id="both" name="org-status" value='2'
                    checked ={countryOption===2}
                    onChange={(e)=>{
                      setCountryOption(Number(e.target.value)); 
                        // console.log(e.target.value);   
                        setCurrentPageNumber(1);  
                        }}
                   />
                 <label >Both</label> 
                </Form.Group> 
              </Form>
                   </Grid.Column>
                 </Grid.Row>
                 <Grid.Row className="vcard-section">
                  <Card.Group itemsPerRow={5} className='dash-card-links' 
                  style={{paddingTop:'2%'}}> 
                {     
                countryMaster && 
                countryMaster.countryList.map((field)=>{  
                      if(searchTerm===""){
                        return field} else if (
                    field.countryName.toLowerCase().includes(searchTerm.toLowerCase())
                   ) { 
                    return field; 
                   }
                 }).map((field, kid) => { 
                 
                      if(field)
                   return(
                     
                    <Card color='grey' key={field.countryCode} style={{marginLeft:'20px', width:'250px'}} 
                    >
                         <Card.Content>
                        { field.imageUrl && <Image
                             floated='left'
                             style={{width:'50px'}}
                             src={field.imageUrl}
                           />   || <Icon name='picture' color='grey' size='huge' style={{ float:'left'}}/>
                        }
                           <Card.Meta 
                           style={ field.status === 1?{color:'green',position:'absolute',right:'5px',top:'0'}:{color:'red', position:'absolute',right:'5px', top:'0'}}
                            floated='right'
                           >{field.status===1?'Active':'Passive'}
                           </Card.Meta>
                           <div 
                           >
                           <div style={{fontSize:'2vh'}}><b>{field.countryName}</b></div>
                          
                           </div>
                           <div style={{fontSize:'2vh'}}>
                           {field.regionName}
                           </div>       
                         </Card.Content>
                         <Card.Content extra> 
                           <div>  
                              {(Edit || (userType && userType.userType === 2)) &&  
                             <Link 
                             to={ {
                              pathname: "/AddNewCountry", 
                             // state: regionData,
                              countryMaster:countryMasterPageData,
                              //countryData:countryDetails.response,
                              countryCode:field.countryCode,
                              button:'edit'
                            }} 
                            // onClick={editPropertyHandler.bind(this,field.propertyId)}
                             style={{marginRight:'5%'}}
                             key='Edit'
                             > 
                              <Icon  name='edit' color='red' />
                             <span>  Edit </span>
                             </Link>
                             } 
                             {(View ||  (userType && userType.userType === 2)) &&  
                             <Link 
                                to={ {
                                    pathname: "/AddNewCountry", 
                                    //state: regionData,
                                    countryMaster:countryMasterPageData,
                                   // countryData:countryDetails.response,
                                    countryCode:field.countryCode,
                                    button:'view'
                                }} 
                              color='green'
                             style={{marginRight:'5%'}}
                             key='View'
                             >
                              <Icon  name='eye' color='green' />
                              <span> View </span> 
                             </Link>
                             } 
                              { (AddState ||  (userType && userType.userType === 2)) &&  
                             <Link  to={ {
                              pathname: "/AddNewState",  
                              countryData:{'countryName':field.countryName, 'countryCode':field.countryCode},
                              countryDetails:countryDetails.response,
                              button:'add'
                            }}
                              color='green'
                             style={{marginRight:'5%'}}
                             key='add'
                             >
                              <Icon  name='plus' color='yellow' />
                              <span> Add State </span> 
                             </Link>
                             } 
                           </div>
                         
                         </Card.Content>
                       </Card>    
                   ) 
                })
               }         
              </Card.Group> 
                  </Grid.Row>
               
        </Grid>
      </Container>
    )
    
}

export default CountryMaster;