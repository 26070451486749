import { useState,useContext, useEffect} from "react";
import axios from "axios";
import { Container, Grid, Form, Input, Button, Dropdown } from "semantic-ui-react";
import { Link,useHistory } from "react-router-dom";
import ContextData from '../../store/context-data';

const AddNewState = (props) =>{
    const ctx = useContext(ContextData);
    const [stateMasterPageDetails, setStateMasterPageDetails] = useState('');
    //const [stateMasterList,setStateMasterList] = useState('');
    const [propStatus,setPropStatus] = useState(1);
    const [countryName,setCountryName] = useState('');
    const [countryCode,setCountryCode] = useState('');
    const [stateName,setStateName] = useState('');
    const [stateCode,setStateCode] = useState(0);
    const [shortName,setShortName] = useState('');
    const [spokenLanguageId, setSpokenLanguageId] = useState('');
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [stateMasterDetails, setStateMasterDetails] = useState()
    const [readOnlyToggle, setReadOnlyToggle] = useState(false);
    const [headerMessage, setHeaderMessage] = useState('Create New State');

    const add = (props.button==='add') ? true:false;
   //console.log(props.countryDetails);
    const countryOptionsState= stateMasterPageDetails && stateMasterPageDetails.countryList.map((country, id)=>{  return {
      key: id,
      text: country.countryName,
      value: country.countryCode,
    }
  })
 // const spokenLanOptions = stateMasterList && stateMasterList;
 const spokenLanOptions= stateMasterPageDetails && stateMasterPageDetails.languageList.map((language, id)=>{  return {
  key: id,
  text: language.languageName,
  value: language.languageId,
  disabled: (readOnlyToggle ?true:false)
  
}
})
    let history = useHistory();  


    useEffect(()=>{
      if(props.countryData){
              setCountryName(props.countryData.countryName);
              setCountryCode(props.countryData.countryCode)
      } 
    },[props.countryData])

   useEffect(()=>{
     !stateMasterPageDetails &&
    axios.get(`${process.env.REACT_APP_BASE_API_URL}Master/GetStateMasterPageDetails`,{ 
      headers: { Authorization: `Bearer ${ctx.APIToken}`}}
   ).then((response)=>{
   const data = response.data.response;
   //console.log('page state', data)
   setStateMasterPageDetails(data);
   })
   },[])
//
  // useEffect(()=>{
  //  countryCode &&
  //  axios.get(`${process.env.REACT_APP_BASE_API_URL}Master/GetStateMasterList`,{ 
  //    params:{
  //      CountryCode:countryCode
  //    },
  //    headers: { Authorization: `Bearer ${ctx.APIToken}`}}
  // ).then((response)=>{
  // const data = response.data;
  // //console.log('master state ',data)
  // setStateMasterList(data);
  // })
  // },[countryCode])

   //GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/GetStateMasterDetails?StateCode=1'
   useEffect(()=>{
    if(props.state){
      setCountryName(props.state.countryName);
      setStateCode(props.state.stateCode);
    axios.get(`${process.env.REACT_APP_BASE_API_URL}Master/GetStateMasterDetails`,{ 
      params:{
        StateCode:props.state.stateCode
      },
      headers: { Authorization: `Bearer ${ctx.APIToken}`}}
   ).then((response)=>{
   const data = response.data.response;
   //console.log('master state ',data)
   setStateMasterDetails(data);
   setPropStatus(data.stateDetails.status);
   //setCountryName(data.stateDetails.countryName);
    setCountryCode(data.stateDetails.countryCode);
    setStateName(data.stateDetails.stateName);
    setShortName(data.stateDetails.shortName);
    setSpokenLanguageId(data.stateDetails.spokenLanguageId);
    setLatitude(data.stateDetails.latitude);
    setLongitude(data.stateDetails.longitude); 
    if(props.button === 'view') {
       setReadOnlyToggle(true);
       setHeaderMessage('View State');
  }
    (props.button === 'edit' ||  props.button === 'add') &&
    setReadOnlyToggle(false);
    if(props.button === 'edit') setHeaderMessage('Edit State');
  
   })
  }
   },[props.state])
   // console.log(props.regionData)
    //console.log(props.countryData)
//  const regionOptions = props.regionData.regionList.map((list, idr)=>{ return {
//      key: idr,
//      text: list.regionName,
//      value:list.regionId
//  }
//   })
//  const currencyOptions = props.countryData.countryList.map((list, idr)=>{ return {
//      key: 1,
//      text: 'currency',
//      value:'s'
//  }
//  })
    //https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/SaveCountry
  const saveHandler = () =>{
     const upload =  { 
     "StateCode": add ? 0:stateCode,
     "StateName": stateName,
     "CountryCode": Number(countryCode),
     "ShortName": shortName,
     "SpokenLanguageId": spokenLanguageId,
     "Latitude": latitude,
     "Longitude": longitude,
     "Status":propStatus,
    }
    //console.log(upload);

     axios.post(`${process.env.REACT_APP_BASE_API_URL}Master/SaveState`,
      { "StateCode": add ? 0:stateCode,
      "StateName": stateName,
      "CountryCode": Number(countryCode),
      "ShortName": shortName,
      "SpokenLanguageId": spokenLanguageId,
      "Latitude": latitude,
      "Longitude": longitude,
      "Status":propStatus,
     },
       {headers: { Authorization: `Bearer ${ctx.APIToken}`}},  
     )
    .then((response)=>{
      if(response.data.message === 'Success')
      history.goBack();
         else alert(response.data.message)
    })  

     
 }
    return(
        <Container>
            <Grid>
            <Grid.Row columns={1} >
                     <Grid.Column textAlign="center" style={{marginTop:'10%', paddingTop:'10px'}}>
                         <h3>{headerMessage}</h3>
                     </Grid.Column> 
            </Grid.Row>
            <Grid.Row>
                 <Grid.Column width={10}>
                     <Form inline>
                     <Form.Field inline >  
                         <label style={{marginRight:'25px'}}> Country Name *</label>
                        { readOnlyToggle && <Input 
                         value={countryName}  
                         style={{width:'550px'}}
                         readOnly
                         /> ||
                            <Dropdown 
                              selectOnBlur={false}
                              clearable  
                              placeholder='Choose Country' 
                              search  
                              options={countryOptionsState}
                              selection 
                              value={countryCode}
                              onChange={(e,data)=>{
                                setCountryCode(data.value); 
                              }}   
                              /> 
                              }

                     </Form.Field>

                     </Form>
                 </Grid.Column>
                 <Grid.Column style={{ paddingTop:'5px'}} width={5}>
                     <Form>
                     <Form.Group inline>
                 <label  style={{marginRight:'20px'}}>Status *</label> 
                 { readOnlyToggle &&
                 <input type="radio" id="active" name="prop-status" value="1" checked ={propStatus===1}
                   readOnly  
                   /> ||
                   <input type="radio" id="active" name="prop-status" value="1" checked ={propStatus===1}
                   onChange={(e)=>{setPropStatus(Number(e.target.value)); //console.log(e.target.value);
                  }}
                   /> 

                   }
                 <label>Active</label>
                  { readOnlyToggle &&
                    <input type="radio" id="passive" name="prop-status" value="0" 
                    checked ={propStatus===0}  
                    readOnly   
                 /> ||
                 <input type="radio" id="passive" name="prop-status" value="0" 
                 checked ={propStatus===0}
                 onChange={(e)=>{setPropStatus(Number(e.target.value)); 
                  // console.log(e.target.value)
                  }} 
              /> 

                 }
                 <label>Passive</label>
                </Form.Group>
                     </Form>
                
                 </Grid.Column>
               </Grid.Row>
               <Grid.Row>
                   <Grid.Column width={5} >
                   <Form inline>
                          <Form.Field inline>  
                              <label style={{width:'105px'}}>State Name *</label>
                             {readOnlyToggle && <Input 
                              value={stateName}
                              style={{width:'197px'}} 
                              readOnly
                              />
                              ||
                              <Input 
                              value={stateName}
                              style={{width:'197px'}}
                              onChange={(e,data)=>setStateName(data.value)}
                              />
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={5}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{width:'100px'}}>Short Name</label>
                             { readOnlyToggle &&
                               <Input
                              value={shortName} 
                              style={{width:'197px'}}
                              readOnly
                              /> ||
                              <Input
                              value={shortName}
                              onChange={(e,data)=>setShortName(data.value)} 
                              style={{width:'197px'}}
                              />
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                 </Grid.Row> 
                 
                 <Grid.Row>
                   <Grid.Column width={7}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{width:'105px'}}>Spoken Language</label> 
                             { readOnlyToggle && <Dropdown  
                               selectOnBlur={false}
                              placeholder='Choose Spoken Language' 
                              search  
                              options={spokenLanOptions}
                              selection 
                              value={spokenLanguageId}
                              onChange={(e,data)=> {
                                setSpokenLanguageId(data.value);
                              }}
                              
                              /> ||
                              <Dropdown  
                               selectOnBlur={false}
                              placeholder='Choose Spoken Language' 
                              search  
                              options={spokenLanOptions}
                              selection
                              clearable 
                              value={spokenLanguageId}
                              onChange={(e,data)=> {
                                setSpokenLanguageId(data.value);
                              }}
                              />
                              }
                            
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={4}>
                 
                   </Grid.Column>  
                 </Grid.Row> 
                 <Grid.Row>
                   <Grid.Column width={5}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{width:'105px'}}>Latitude </label>
                              {readOnlyToggle && <Input  
                              value={latitude}
                              style={{width:'197px'}} 
                              readOnly
                              /> ||
                              <Input  
                              value={latitude}
                              style={{width:'197px'}}
                              onChange={(e,data)=> setLatitude(data.value)}
                              /> 
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={6}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{width:'100px'}}>Longitude</label>
                              {readOnlyToggle && <Input
                               value={longitude} 
                               style={{width:'197px'}}
                               readOnly
                              /> ||
                              <Input
                               value={longitude}
                               onChange={(e,data)=> setLongitude(data.value)}
                               style={{width:'197px'}}
                              />
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                 </Grid.Row> 
                 <Grid.Row>
                     <Grid.Column width={3} floated="left">
                       <Button onClick={()=>{history.goBack();}}> Back</Button>
                     </Grid.Column>
                     <Grid.Column width={3} floated="right">
                       {!readOnlyToggle && <Button onClick={saveHandler}> Save</Button> }
                     </Grid.Column>
                 </Grid.Row>
            </Grid>
        </Container>
    )
}

export default AddNewState;