import { useState,useEffect } from "react";
import { Form,Button,Grid, Segment,Loader, Dimmer} from "semantic-ui-react";
//import Dashboard from "./Dashboard";
import axios from "axios"; 
import { BrowserRouter } from "react-router-dom";
import Home from "./Home";

const Login = (props) => {
    
    const [loggedIn, setLoggedIn] = useState(false);
    const [userID, setUserID] = useState('');
    const [password,setPassword] =useState('');
    const [userData,setUserData] = useState(sessionStorage.getItem("SessionUserLoginData")?JSON.parse(sessionStorage.getItem("SessionUserLoginData")):[]);
    
    // const [message,setMessage] = useState('');
  //  const [userDataObj, setUserDataObj] = useState([]);
  //  const [sessionStrLogin, setSessionStrLogin] = useState(sessionStorage.getItem("SessionApiToken")?sessionStorage.getItem("sessionApiToken"):[]);
    const [loading, setLoading]=useState(false);
   
const enteredUserID = (e) =>{
    setUserID(e.target.value);
    // console.log(userID);
}


    const LoginHandler = async ()=>{  
      setLoading(true)
      localStorage.clear();
      sessionStorage.clear();
     await axios
     .post( `${process.env.REACT_APP_BASE_API_URL}LUCIDUserLogin`,
     {},
     {
       auth: {
         username: userID,
         password: password,
       },
      }
     )
    .then((response) => {
      setLoading(false);
     console.log(response.data);
     const data = response.data;
       if (data.errorCode===1) {
         alert(data.message);
       } 
     setUserData(response.data);
    sessionStorage.setItem('SessionUserLoginData', JSON.stringify(data)); 
      setLoggedIn(true);
      props.LoginStatus(true,data.response.lucidApiToken);
    //  console.log(userData);    
    })
    .catch((error) => {
   //  setMessage(userData.message);
      console.log(error) 
    });
   // setMessage("Login Try");      
   }  


    const homeHandler = () => {
       setUserData('');
       setLoggedIn(false);
       props.LoginStatus(false);
       localStorage.removeItem('APIKey');
       localStorage.clear();
       sessionStorage.clear(); 
    }

    useEffect (() => {
      if (userData.message==="Success")
          {
       setLoggedIn(true);
       props.LoginStatus(true);
       }  
    },[userData,props]); 
   
    const LoginForm = () => (
      <>
        <Grid container columns={1}> 
        <Grid.Column style={{margin:'30px', borderBottom:' 1px solid grey',paddingBottom:'10px' }}> 
          <h2 className='login-header'>
          Lucid License Management
         </h2> 
        </Grid.Column> 
        </Grid> 
      <Grid.Row columns={3} > 
      </Grid.Row>
      <Grid centered columns={3}>
        <Grid.Column>
        </Grid.Column>
      <Grid.Column   textAlign="center" className='login'> 
          <p>
           Login
          </p> 
         <Segment>
          { loading &&
          <Dimmer>
            <Loader />
          </Dimmer>
         }
          <Form  className='form-input'>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="User ID"
              onChange={enteredUserID}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              onChange={(e)=>setPassword(e.target.value)}
            /> 
            <Button color="blue" 
             //size="large"
            onClick={LoginHandler}
            >
              Login
            </Button>
          </Form>
        </Segment> 
      </Grid.Column>
      <Grid.Column>
        </Grid.Column>
    </Grid> 
    </>
    ) 
   if(loggedIn && userData.message==="Success") 
        return (
               <>
                 <BrowserRouter>
                      <Home LogOut = {homeHandler} userPropsData={userData}/>
                   </BrowserRouter>
                </>
                ); 
       return(
           <>
              {LoginForm()}
           </>
            )
       }

      export default Login;
