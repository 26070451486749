import { useState, useEffect,useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Container, Form, Grid, 
  Pagination,Button, Dropdown,Table, 
  TableHeader, TableHeaderCell, Input,Card, Icon,Image } from "semantic-ui-react";
import ContextData from '../../store/context-data';

const StateMaster = () => {
  const ctx = useContext(ContextData);
  const [userRightsDashboard, setUserRightsDashboard] = useState(sessionStorage.getItem('userRights')?JSON.parse(sessionStorage.getItem('userRights')):[])
  const [countryOption, setCountryOption] = useState();
  const [pageData,setPageData] = useState('');
  const [regionData,setRegionData] = useState('');
  const [countryMasterPageData,setCountryMasterPageData] = useState('');
  const [stateMasterPageDetails,setStateMasterPageDetails] = useState('');
  const [stateMaster, setStateMaster] = useState('');
  const [countryDetails,setCountryDetails] = useState('');
  const [countryCode,setCountryCode] = useState('');
  const [searchTerm,setSearchTerm] = useState('');
  const [stateStatus, setStateStatus] = useState(1);
  const [currentPageNumber,setCurrentPageNumber] = useState(1)
  const [noOfRows,setNoOfRows] = useState(50);
  const [stateName,setStateName] = useState('')
  const [countryName, setCountryName] = useState('');
  const [Edit, setEdit] = useState();
  const [Add,setAdd] = useState();
  const [View, setView] = useState();
  const [AddCity, setAddCity] = useState();
  const [userType,setUserType] = useState();

  const selectOptions= [
    {
    key: 1,
    text: 24,
    value: 24,
  }, 
  {
    key: 2,
    text: 48,
    value: 48,
  },
  {
    key: 3,
    text: 96,
    value: 96,
  }
  ]
  const countryList =  stateMasterPageDetails && stateMasterPageDetails.countryList.map((cn, id)=>{
           return {
            key: id,
            text: cn.countryName,
            value: cn.countryCode,
           }
  })

  useEffect(()=>{
    stateMasterPageDetails && stateMasterPageDetails.pageControlRights.forEach(item =>{
      console.log(item)
       item.controlName === 'Add' && setAdd(item.visible)
       item.controlName === 'Edit' && setEdit(item.visible)
       item.controlName === 'View' && setView(item.visible)
       item.controlName === 'Add City' && setAddCity(item.visible) 
    })
    userRightsDashboard && userRightsDashboard.forEach(item=>{
       item.userType && setUserType(item)
    })
    
  },[stateMasterPageDetails])
//GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/'
 // check error with db admin
 // useEffect(()=>{
 //    ctx.APIToken &&
 //    axios
 //    .get(
 //     `${process.env.REACT_APP_BASE_API_URL}Master/GetRegionMasterPageDetails`,{

 //      headers: { Authorization: `Bearer ${ctx.APIToken}`}
 //     }
 //    )
 //    .then((response) => {
 //    //   setLoaderToggle(false);
 //      const data = response.data;
 //     console.log(data)
 //     setPageData(data);  
 //    }).catch((error)=>{
 //     //console.log(error);
 //    }) 
 //   },[])

 //  useEffect(()=>{
 //    ctx.APIToken &&
 //   axios
 //   .get(
 //    `${process.env.REACT_APP_BASE_API_URL}Master/GetRegionMasterList`,{ 
 //     headers: { Authorization: `Bearer ${ctx.APIToken}`}
 //    }
 //   )
 //  .then((response) => {
 //  //   setLoaderToggle(false);
 //   const data = response.data.response;
 //   console.log(data)
 //    setRegionData(data);  
 //  }).catch((error)=>{
 //    //console.log(error);
 //  }) 
 //  },[])
  
    //'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/GetStateMasterPageDetails'  
    useEffect(()=>{
       ctx.APIToken &&
       axios
       .get(
        `${process.env.REACT_APP_BASE_API_URL}Master/GetStateMasterPageDetails`,{      
         headers: { Authorization: `Bearer ${ctx.APIToken}`}
        }
       )
       .then((response) => {
       //   setLoaderToggle(false);
         const data = response.data;
        console.log('page state master user rights',data)
        setStateMasterPageDetails(data.response);  
       }).catch((error)=>{
        //console.log(error);
       }) 
      },[])
    //  useEffect(()=>{
    //       if(stateMasterPageDetails && countryName){
    //     //  = stateMasterPageDetails.countryList.includes(countryName).map(item =>item);
    //       const cCode =  stateMasterPageDetails.countryList.map((field)=>{   
    //          field.countryName.toLowerCase().includes(countryName.toLowerCase())  
    //        return field; 
    //        } 
    //     ).map((field, kid) => field) 
    //     console.log(cCode);
    //        setCountryCode(cCode);
    //
    //       }
    //  },[countryName,stateMasterPageDetails])

//curl --location --request GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/GetStateMasterList?
  useEffect(()=>{
    ctx.APIToken &&
    axios
    .get(
     `${process.env.REACT_APP_BASE_API_URL}Master/GetStateMasterList`,{
       params:{
        Status:stateStatus,
        CurrentPageNumber:currentPageNumber,
        NoOfRowsPerPage:noOfRows,
        ...(countryCode && {CountryCode:countryCode}),
        ...(stateName && {StateName:stateName})
       }, 
      headers: { Authorization: `Bearer ${ctx.APIToken}`}
     }
    )
   .then((response) => {
  //   setLoaderToggle(false);
    const data = response.data.response;
    //console.log(data)
     setStateMaster(data);
   }).catch((error)=>{
     //console.log(error);
   }) 
  },[currentPageNumber, noOfRows])
   

 // //GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/GetCountryDetails'
 //  useEffect(()=>{
 //   ctx.APIToken &&
 //   axios
 //   .get(
 //    `${process.env.REACT_APP_BASE_API_URL}Master/GetCountryDetails`,{
 //     params:{CountryCode:countryCode},
 //     headers: { Authorization: `Bearer ${ctx.APIToken}`}
 //    }
 //   )
 //  .then((response) => {
 // //   setLoaderToggle(false);
 //   const data = response.data;
 //   //console.log(data)
 //    setCountryDetails(data);  
 //  }).catch((error)=>{
 //    //console.log(error);
 //  }) 
  // },[])

 const searchHandler = () =>{
  axios
  .get(
   `${process.env.REACT_APP_BASE_API_URL}Master/GetStateMasterList`,{
     params:{
      Status:stateStatus,
      CurrentPageNumber:currentPageNumber,
      NoOfRowsPerPage:noOfRows,
      ...(countryCode && {CountryCode:countryCode}),
      ...(stateName && {StateName:stateName})
     }, 
    headers: { Authorization: `Bearer ${ctx.APIToken}`}
   }
  )
 .then((response) => {
//   setLoaderToggle(false);
  const data = response.data.response;
 // console.log(data)
   setStateMaster(data);
 }).catch((error)=>{
   //console.log(error);
 }) 
 }
 const searchClearHandler = (buttonname) =>{
  axios
  .get(
   `${process.env.REACT_APP_BASE_API_URL}Master/GetStateMasterList`,{
     params:{
      Status:stateStatus,
      CurrentPageNumber:currentPageNumber,
      NoOfRowsPerPage:noOfRows,
      ...((buttonname === null) && {CountryCode:''} ||
      countryCode && {CountryCode:countryCode}
      ),
      ...((buttonname === 'stateName') && {StateName:''} ||
      stateName && {StateName:stateName}
      ),
     }, 
    headers: { Authorization: `Bearer ${ctx.APIToken}`}
   }
  )
 .then((response) => {
//   setLoaderToggle(false);
  const data = response.data.response;
  //console.log(data)
   setStateMaster(data);
 }).catch((error)=>{
   //console.log(error);
 }) 
 }

 
    return(
      <Container >
        <Grid>
                <Grid.Row columns={1} >
                     <Grid.Column textAlign="center" style={{marginTop:'10%', paddingTop:'10px'}}>
                         <h3>State Master</h3>
                     </Grid.Column> 
                 </Grid.Row>
                 <Grid.Row columns={4} >
                   <Grid.Column width={7}> 
                     <Form inline>
                       <Form.Group>
                      
                      <Form.Field inline> 
                      <Dropdown  
                               selectOnBlur={false} 
                              placeholder='Country' 
                              search  
                              options={countryList}
                              selection 
                              clearable = {true}
                              value={countryCode}
                              onChange={(e,data)=>{
                                setCountryCode(data.value) 
                              }}   
                              /> 
                      </Form.Field> 
                      <Form.Field inline> 
                         <Input
                         onChange={(e, data)=>{setStateName(data.value)}}
                         placeholder='Search by State Name'
                         value={stateName}
                         icon={stateName && <Icon name='delete' link onClick={(e,data)=>{ 
                          setStateName('');
                          searchClearHandler('stateName');
                        }} />} 
                          />
                         
                      </Form.Field> 
                       </Form.Group>
                    
                     </Form> 
                      </Grid.Column> 
                      <Grid.Column width={6} style={{paddingTop:'10px'}}>
                   <Form> 
                <Form.Group inline key='orgstatus'>  
                <label>Status</label>
                 <Form.Field>
                
                 <Input 
                  type="radio" id="active" name="org-status" value='1' checked ={stateStatus===1}
                      onChange={(e)=>{ 
                          setStateStatus(Number(e.target.value));
                          setCurrentPageNumber(1);  
                         // console.log(e.target.value);      
                     }}
                    />
                 <label>Active</label>
                 </Form.Field>
                 
                 <Form.Field>
                 <Input type="radio" id="passive" name="org-status" value='0'
                    checked ={stateStatus===0}
                    onChange={(e)=>{
                      setStateStatus(Number(e.target.value)); 
                      setCurrentPageNumber(1);  
                        // console.log(e.target.value); 
                        }}
                 />
                  <label>Passive</label>
                 </Form.Field>
                    <Form.Field> 
                   <Input type="radio" id="both" name="org-status" value='2'
                    checked ={stateStatus===2}
                    onChange={(e)=>{
                      setStateStatus(Number(e.target.value));
                      setCurrentPageNumber(1);   
                        // console.log(e.target.value);   
                        }}
                     />
                     <label >Both</label> 
                   </Form.Field>   
                </Form.Group> 
              </Form>
                   </Grid.Column> 
                   <Grid.Column width={3} floated="right" style={{ position:'absolute', right:'0px'}}>
                 <Button
                 color='blue'
                 style={{width:'115px'}}
                 onClick={searchHandler}
                 >
                       Search
                     </Button> 
                   </Grid.Column> 
                 </Grid.Row>
                 <Grid.Row>
                 <Grid.Column width={2} style={{paddingRight:'0px',marginRight:'0px'}}>
                   <Form inline>
                     <Form.Field inline>  
                         <label> Select</label>
                         <Dropdown
                       compact
                       placeholder="24"
                       defaultValue={24}
                       selection 
                       options={selectOptions} 
                       onChange={(e, data)=>{
                        setNoOfRows(data.value);
                        setCurrentPageNumber(1);
                        window.scrollTo(0, 0);
                       }} 
                      /> 
                      </Form.Field>  
                     </Form>  
                  </Grid.Column> 
                 <Grid.Column width={3} floated="left" style={{paddingLeft:'0px'}}>
                       
                       <Pagination 
                   size='small' 
                   boundaryRange={null}
                   //defaultActivePage={1}
                   activePage={currentPageNumber}
                   color='blue'
                   ellipsisItem={null}
                   firstItem={1}
                   lastItem={null}
                   siblingRange={1}
                   totalPages={stateMaster && stateMaster.paginationDetail.totalPages}
                   onPageChange={ (e,data)=>{ setCurrentPageNumber(data.activePage);
                    }}
  
                 >  
                 </Pagination>
                       </Grid.Column> 
                  
                   <Grid.Column floated='right' width={3} style={{ position:'absolute', right:'0px'}}>
                   { (Add || userType === 2) && 
                     <Link 
                    to={ {
                      pathname: "/AddNewState", 
                     // state: regionData,
                      countryData:stateMaster,
                    }}
                   > <Button color='blue'>
                       + New State
                     </Button> </Link>}
                   </Grid.Column> 
                 </Grid.Row>
                 <Grid.Row className="vcard-section">
                  <Card.Group itemsPerRow={5} className='dash-card-links' 
                  style={{paddingTop:'2%'}}> 
                {     
                stateMaster && 
                stateMaster.stateList.map((field)=>{  
                      if(searchTerm===""){
                        return field} else if (
                    field.countryName.toLowerCase().includes(searchTerm.toLowerCase())
                   ) { 
                    return field; 
                   }
                 }).map((field, kid) => { 
                 
                      if(field)
                   return( 
                    <Card color='grey' key={field.countryCode}                                                                                                                                                                                                                                                                                                                                                                                                                        style={{marginLeft:'20px', width:'243px'}} 
                    >
                         <Card.Content>
                        { field.imageUrl && <Image
                             floated='left'
                             style={{width:'50px'}}
                             src={field.imageUrl}
                           />   || <Icon name='picture' color='grey' size='huge' style={{ float:'left'}}/>
                        }
                           <Card.Meta 
                           style={ field.status === 1?{color:'green',position:'absolute',right:'5px',top:'0'}:{color:'red', position:'absolute',right:'5px', top:'0'}}
                            floated='right'
                           >{field.status===1?'Active':'Passive'}
                           </Card.Meta>
                           <div 
                           >
                           <div style={{fontSize:'2vh'}}><b>{field.stateName}</b></div> 
                           </div>
                           <div style={{fontSize:'2vh'}}>
                           {field.countryName}
                           </div>       
                         </Card.Content>
                         <Card.Content extra> 
                           <div>  {(Edit || (userType && userType.userType === 2)) &&  
                              <Link
                              to={{
                                pathname:"/AddNewState", 
                               state: field,
                                // countryMaster:countryMasterPageData,
                                // countryData:countryDetails.response,
                                stateCode:field.stateCode,
                                button:'edit'
                              }} 
                            // onClick={editPropertyHandler.bind(this,field.propertyId)}
                             style={{marginRight:'5%'}}
                             key='Edit'
                             > 
                              <Icon  name='edit' color='red' />
                             <span>  Edit </span>
                             </Link>
                            }
                             { (View || (userType && userType.userType === 2)) &&  
                             <Link  to={{
                              pathname:"/AddNewState", 
                              state: field,
                             // countryMaster:countryMasterPageData,
                              //countryData:countryDetails.response,
                              stateCode:field.stateCode,
                              button:'view'
                            }} 
                              color='green'
                             style={{marginRight:'5%'}}
                             key='View'
                             >
                              <Icon  name='eye' color='green' />
                              <span> View </span> 
                             </Link>
                             } 
                              {  (AddCity || (userType && userType.userType === 2)) &&  
                             <Link  to={{
                              pathname:"/AddNewCity", 
                              state: field,
                              countryMaster:countryMasterPageData, 
                              //countryData:countryDetails.response, 
                              button:'add'
                            }} 
                              color='green'
                             style={{marginRight:'5%'}}
                             key='add'
                             >
                              <Icon  name='plus' color='yellow' />
                              <span> Add City </span> 
                             </Link>
                             } 
                           </div>
                         
                         </Card.Content>
                       </Card>    
                   ) 
                })
               }         
              </Card.Group> 
                  </Grid.Row>
               
        </Grid>
      </Container>
    )
    
}

export default StateMaster;