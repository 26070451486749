import React from "react";
//import { useMediaQuery } from 'react-responsive'; 
import axios from "axios";
import { Link } from "react-router-dom"
import { useState, useEffect, useContext } from "react";
import { Switch, Route, useHistory } from 'react-router-dom'
//import { Container,Form,Row, Col,Button } from "react-bootstrap";
import { Container, Grid, Icon, Modal, Input, Form, Button } from "semantic-ui-react";
import Dashboard from "./Dashboard";
import NewOrganization from "../NewOrganization";
import NewProperty from "../NewProperty";
import SelectModules from "./SelectModules";
import ContextData from "../store/context-data";
import DefaultUsers from "./DefaultUsers";
import LicensePreview from "./LicensePreview";
import { useDate } from "./time";
import ViewOrg from "./ViewOrg";
import ViewPrope from "./ViewPrope";
import Footer from "./Footer";
import OrgListReport from "./Reports/OrgListReport";
import PropListReport from "./Reports/PropListReports";
import PropModuleReport from "./Reports/PropModuleReport";
import CountryMaster from "./Settings/CountryMaster";
import StateMaster from "./Settings/StateMaster";
import CityMaster from "./Settings/CityMaster";
import AreaMaster from "./Settings/AreaMaster";
import AddNewCountry from "./Settings/AddNewCountry";
import AddNewState from "./Settings/AddNewState";
import AddNewCity from "./Settings/AddNewCity";
import AddNewArea from "./Settings/AddNewArea";


const Home = (props) => {

  const [style, setStyle] = useState({});
  const [styleMain, setStyleMain] = useState({});
  const [openButton, setOpenButton] = useState({ fontSize: '20px', cursor: 'pointer', marginLeft: '20px', marginTop: '15px', color: 'white', float: 'left' });
  //const [date, setDate] = useState([]);
  const [menuDta, setMenuDta] = useState('');
  const [orgName, setOrganizationName] = useState();
  const [enteredCountry, setEnteredCountry] = useState()
  const [enteredCountryCode, setEnteredCountryCode] = useState();
  const [enteredState, setEnteredState] = useState();
  const [enteredStCode, setEnteredStCode] = useState();
  const [updateCtx, setUpdateCtx] = useState(false);
  const [propertyName, setPropertyName] = useState();
  const [selectModule, setSelectModule] = useState([]);
  const [defaultUsers, setDefaultUsers] = useState([]);
  const [adrDetails, setAdrsDetails] = useState([]);
  const [newpropertyData, setNewProperty] = useState();
  const updateTime = useDate();
  const [selectedList, selectedModuleList] = useState();
  const [clickedMenu, setClickedMenu] = useState('');
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [passwordMessage, setPasswordMessage] = useState();
  const [userData] = useState(sessionStorage.getItem("SessionUserLoginData") ? JSON.parse(sessionStorage.getItem("SessionUserLoginData")) : []);
  const [confirmPassword, setConfirmPassword] = useState();
  const [userRights, setUserRights] = useState()
  // sessionStorage.setItem('menuDta', menuDta?menuDta:[]);
  // console.log(userData.response);
  // const ctx = useContext(ContextData);
  const userType = userData.length > 0 ? userData.response.userType : '';
  const [modal, setModal] = useState(false);
  const [sideNavStyle, setSideNavStyle] = useState({
    width: '260px',
    textAlign: 'center',
    fontWeight: 'bold',
    backgroundColor: 'rgb(11, 164, 235)',
    color: 'white',
  });
  const [orgPadding, setOrgPadding] = useState({ marginLeft: '130px' });
  //const icons =['dashboard'];
  // const propsArr = [adrDetails,newpropertyData,selectModule,defaultUsers];

  const userHomeData = props.userPropsData;
  const { userName, lucidLogoUrl: logoURL, moduleName, organizationName, lucidApiToken } = userHomeData.response;
  // const today = new Date(serverDate); 
  const [Settings, setSettingsMenu] = useState(
    {
      color: 'white',
      //  marginRight:'10px',
      marginLeft: '10px',
      display: 'none'
    }
  );

  let history = useHistory();
  const openNav = () => {
    setStyle({
      width: "200px"
    })
    setStyleMain({
      marginLeft: "200px"
    })

    //  setOpenButton({opacity:0});
    setSideNavStyle({
      width: '267px',
      marginLeft: '0px',
      textAlign: 'center',
      fontWeight: 'bold',
      backgroundColor: 'rgb(11, 164, 235)',
      color: 'white',
    })

    setOrgPadding({ marginLeft: '10px' })
    //  console.log(sideNavStyle);

    setOpenButton({ fontSize: '20px', cursor: 'pointer', marginLeft: '20px', marginTop: '15px', color: 'white', float: 'left', opacity: 0 })
    // setSideNavStyle({width:'260px',color:'white', 
    // textAlign:'center', fontWeight:'bold', 
    //  backgroundColor:'rgb(11, 164, 235)' });
    // setOrgPadding({marginLeft:'130px'})
  }

  const closeNav = () => {
    setStyle({
      width: "0px"
    })
    setStyleMain({
      marginLeft: "0px"
    })

    setOpenButton({ fontSize: '20px', cursor: 'pointer', marginLeft: '20px', marginTop: '15px', color: 'white', float: 'left', opacity: 1 })
    setSideNavStyle({
      width: '260px', color: 'white',
      textAlign: 'center', fontWeight: 'bold',
      backgroundColor: 'rgb(11, 164, 235)'
    });
    setOrgPadding({ marginLeft: '130px' })

  }
  const rightsHandler = (userRights) => {
    setUserRights(userRights)
  }
  useEffect(() => {
    !menuDta &&
      setMenuDta(JSON.parse(sessionStorage.getItem('menuDta')));
  }, [menuDta])

  const menuHandler = (props) => {
    // console.log(JSON.parse(sessionStorage.getItem('menuDta'))); 
    !sessionStorage.getItem('menuDta') &&
      sessionStorage.setItem('menuDta', JSON.stringify(props.menus));
    setMenuDta(props.menus);

  }

  const selectModuleHandler = (data, list) => {
    setSelectModule(data);
    selectedModuleList(list);
    // console.log('Data received from select modules' + JSON.stringify(data));
    //  console.log(data);
  }

  const usersHandler = (data) => {
    setDefaultUsers(data);
  }

  const propertyNameHandler = (formPropertyName, data) => {
    setPropertyName(formPropertyName);
    setNewProperty(data);
  }
  const formDataPull = (organizationName, enteredCountry, countryCode, enteredstate, enteredstatecode, data) => {
    setOrganizationName(organizationName);
    setEnteredCountry(enteredCountry);
    setEnteredCountryCode(countryCode);
    setEnteredState(enteredstate);
    setEnteredStCode(enteredstatecode);
    setAdrsDetails(data);//JSON.Stringify(data)
    // console.log(data);
    //console.log('Received Address Data' +JSON.stringify(data) );//fine
    //console.log('From Home with formData - Organization Details data Object '+Object.entries(data));//fine
  }
  useEffect(() => {
    //  console.log(orgName);
    //console.log(enteredCountry);
    //console.log(enteredCountryCode);
    //console.log(enteredState);
    //console.log(enteredStCode); 
    setUpdateCtx(true);
  }, [orgName, enteredCountry, enteredCountryCode, enteredState, enteredStCode, propertyName])

  const showSubmenu = (menuStyle) => {
    setClickedMenu(menuStyle)
    setSettingsMenu(
      {
        color: 'white',
        // marginRight:'10px',
        marginLeft: '10px',
        display: Settings.display === 'none' ? 'block' : 'none'
      }
    )
  }
  const passwordModalHandler = () => {
    setModal(true);
    setPasswordMessage('');
  }

  const passwordHandler = () => {
    // console.log(ctx.APIToken);
    if ((newPassword === confirmPassword) && newPassword && confirmPassword) {
      axios.post(`${process.env.REACT_APP_LUCID_API_URL}ChangePassword`,
        {
          "OldPassword": oldPassword,
          "NewPassword": newPassword,
          "SystemDetails": {
            "ApplicationName": "LUCID License Management",
            "ApplicationVersion": "1.0.0.0",
            "BrowserName": "Chrome",
            "BrowserVersion": "83",
            "DeviceId": "",
            "DeviceType": "Desktop",
            "IP": "2409:4072:198:c9b1:95a:80d4:a728:36e",
            "Mac": "0210D6A869E8",
            "OPS": "5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/83.0.4103.97 Safari/537.36",
            "Source": "React App",
            "SystemName": "EC2AMAZ-EVPTFRH",
            "SystemTimeZoneId": 1
          }
        }, {
        headers: { Authorization: `Bearer ${userData && userData.response.lucidApiToken}` },
        "Content-Type": "application/json"
      }
      ).then((response) => {
        //console.log(response)
        setPasswordMessage(response.data.message)
      })
    } else {
      setPasswordMessage('Password is not Matching')
    }
  }
  return (
    <>
      <Container fluid>


        <div className="sidenav" style={style}>
          <div key='clsbtn' className="closebtn" onClick={closeNav}>&times;</div>
          <div key='usrcrcl' className='center'><Icon name='user circle' color='teal' size='big' />
          </div>
          <ul className='center'>
            <li key='usrn' style={{ marginLeft: '10px' }}> {userName}</li>
            <li key='dte' style={{ float: 'left', marginLeft: '10px', paddingRight: '10px' }}>
              {updateTime.date}
            </li>
            <div key='tme' style={{ marginLeft: '10px' }}> {updateTime.time} </div>
          </ul>
          <li className="hover-pwd"
            style={{
              listStyle: 'none',
              color: 'white',
              paddingLeft: '25px',
              cursor: 'pointer'
            }}
            onClick={passwordModalHandler}
          >
            Change Password
          </li>
          {menuDta &&
            menuDta.map((menu, idm) => {
              // console.log(menu);
              return (
                <ul key={idm} className='center' >  {
                  (userType === 2 || menu.visible === true) &&
                  <Link key={menu.menuName} to={`/${menu.menuName}`}
                    onClick={showSubmenu.bind(this, menu.menuName)}
                  >
                    <Icon name='dashboard'
                    />   {menu.menuName}
                  </Link>
                }
                  <li key={menu.menuName + idm} id={menu.menuName}
                    style={(menu.menuName === clickedMenu && { ...Settings }) || { display: 'none' }}
                  >
                    {menu.menus.map(item => {
                      //  console.log(item);
                      switch (item.menuName) {
                        case 'Organization List Report':
                          if (userType === 2 || menu.visible === true)
                            return <Link key={item.menuName} to='/OrgListReport' className='sub-menu'> {item.menuName} </Link>;
                          return null;
                        case 'Property List Report':
                          if (userType === 2 || menu.visible === true)
                            return <Link key={item.menuName} to='/PropListReport' className='sub-menu'> {item.menuName} </Link>;
                          return null;
                        case 'Property Module Details Report':
                          if (userType === '2' || menu.visible === true)
                            return <Link key={item.menuName} to='/PropModuleReport' className='sub-menu'> {item.menuName} </Link>;
                          return null;
                        case 'Country Master':
                          if (userType === 2 || menu.visible === true)
                            return <Link key={item.menuName} to='/CountryMaster' className='sub-menu'> {item.menuName} </Link>;
                          return null;
                        case 'State Master':
                          if (userType === 2 || menu.visible === true)
                            return <Link key={item.menuName} to='/StateMaster' className='sub-menu'> {item.menuName} </Link>;
                          return null;
                        case 'City Master':
                          if (userType === 2 || menu.visible === true)
                            return <Link key={item.menuName} to='/CityMaster' className='sub-menu'> {item.menuName} </Link>;
                          return null;
                        case 'Area Master':
                          if (userType === 2 || menu.visible === true)
                            return <Link key={item.menuName} to='/AreaMaster' className='sub-menu'> {item.menuName} </Link>;
                          return null;
                        default:
                          return <Link key={item.menuName} to="/" className='sub-menu'> {item.menuName} </Link>;
                      }
                    }
                    )
                    }
                  </li>
                </ul>
              )
            }
            )

          }
          <ul className='center'>

            <li style={{ marginLeft: '10px' }}
              onClick={() => {
                props.LogOut();
                history.push('/');
              }}>Log Out</li>
          </ul>
        </div>


        <Grid >
          <Grid.Row>
            <Grid.Column width={16}>
              <div style={{
                backgroundColor: '#51504F',
                marginTop: '0px',
                zIndex: 2,
                position: 'fixed',
                left: '0px',
                paddingBottom: '1px',
                width: '100%',
              }}>
                <div className="main" style={styleMain}>
                  <div style={openButton} onClick={openNav} >&#9776;</div>
                  <Link to='/'>
                    <div style={{ float: 'left' }}>
                      <img src={logoURL} style={{ marginLeft: '20px', marginRight: '10px', maxWidth: '80px', paddingTop: '10px' }} alt='Lucid Logo' />
                    </div>
                    <div className='orgName' style={{ color: 'white' }}>{organizationName} - {moduleName} </div>
                  </Link>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {updateCtx &&
          <ContextData.Provider value={{
            CtxOrgName: orgName,
            propertyName: propertyName,
            country: enteredCountry,
            countryCode: enteredCountryCode,
            state: enteredState,
            stateCode: enteredStCode,
            APIToken: lucidApiToken,
            orgDetails: adrDetails,
            newProperty: newpropertyData,
            selectedModules: selectModule,
            defaultUsers: defaultUsers,
            selectedList: selectedList,
            dashBoStyle: sideNavStyle,
            orgPadding: orgPadding,
            userRights: userRights
          }}>
            <div className="main" style={styleMain} onClick={closeNav}>
              <Modal style={{ maxWidth: '30%' }}
                closeIcon
                dimmer='blurring'
                open={modal}
                //  trigger={modal}
                onClose={() => setModal(false)}
                onOpen={() => setModal(true)}
              >

                {
                  <>
                    <Modal.Content >
                      <Grid>
                        <Grid.Row centered style={{ borderBottom: '1px solid grey' }}>
                          <h3>Change Password</h3>
                        </Grid.Row>
                        <Grid.Row centered>
                          <Grid.Column style={{
                            border: 'solid thin #DADBDD',
                            boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.2)', paddingBottom: '10px', width: '213px'
                          }}>
                            <Form>
                              <Form.Field inline>
                                <label style={{ paddingRight: '25px' }}>Old Password </label>
                                <Input
                                  placeholder=' Password'
                                  type="password"
                                  onChange={(e, data) => {
                                    setOldPassword(data.value)
                                    setPasswordMessage('')
                                  }}
                                />
                              </Form.Field>
                              <Form.Field inline>
                                <label style={{ paddingRight: '20px' }}>New Password</label>
                                <Input
                                  type="password"
                                  onChange={(e, data) => setNewPassword(data.value)}
                                  placeholder=' Password'
                                />
                              </Form.Field>
                              <Form.Field inline>
                                <label>Confirm Password</label>
                                <Input
                                  placeholder=' Password'
                                  type="password"
                                  onChange={(e, data) => {
                                    setConfirmPassword(data.value);
                                    if (data.value === newPassword || data.value === '') {
                                      setPasswordMessage('')
                                    }
                                    else {
                                      setPasswordMessage('Password Not Matching')
                                    }
                                  }}
                                />
                                <p style={passwordMessage === 'Success' ? { color: 'green' } : { color: 'red' }}>{passwordMessage}</p>
                              </Form.Field>

                            </Form>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row centered>
                          <Button onClick={passwordHandler} color='blue'>
                            Submit
                          </Button>
                        </Grid.Row>
                      </Grid>

                      <div style={{ color: 'green', fontSize: '12px', textAlign: 'right' }}> { }  </div>

                    </Modal.Content>
                  </>
                }
                <Modal.Actions>



                </Modal.Actions>

              </Modal>
              {lucidApiToken &&
                <Switch>
                  <Route exact path="/" render={(props) => (
                    <Dashboard {...props} APIToken={lucidApiToken} menus={menuHandler}
                      rightsHandler={rightsHandler}
                    />
                  )} />
                  <Route exact path="/DashBoard" render={(props) => (
                    <Dashboard {...props}
                      APIToken={lucidApiToken}
                      menus={menuHandler}
                      dashBoStyle={sideNavStyle}
                    />
                  )} />
                  <Route path="/NewProperty"
                    render={(props) => (
                      <NewProperty {...props} APIToken={lucidApiToken}
                        formPropertyNameHandler={propertyNameHandler}
                        propertyId={props.location.state && props.location.state.propertyId}
                        orgName={props.location.state && props.location.state.orgName}
                        orgId={props.location.state && props.location.state.orgId}
                      />
                    )} />
                  <Route path="/NewOrganization"
                    render={(props) => (
                      <NewOrganization {...props}
                        APIToken={lucidApiToken}
                        formPullData={formDataPull}
                        OrganizationId={props.location.state && props.location.state.OrganizationId}
                        ViewOrg={props.location.state && props.location.state.ViewOrg}
                      />
                    )} />
                  <Route path="/SelectModules"
                    render={(props) => (
                      <SelectModules {...props} selectedModulesHandler={selectModuleHandler} />
                    )} />
                  <Route path="/DefaultUsers"
                    render={(props) => (
                      <DefaultUsers {...props} APIToken={lucidApiToken} defaultUsersHandler={usersHandler} />
                    )} />
                  <Route path="/LicensePreview"
                    render={(props) => (
                      <LicensePreview {...props} orgDetails={adrDetails} newProperty={newpropertyData}
                        selectedModules={selectModule} defaultUsers={defaultUsers} />
                    )} />

                  <Route path="/ViewOrg"
                    render={(props) => (
                      <ViewOrg {...props} orgId={props.location.state.organizationId} />
                    )} />

                  <Route path="/ViewPrope"
                    render={(props) => (
                      <ViewPrope {...props}
                        propId={props.location.state.propertyId}
                        orgName={props.location.state.orgName}
                      />
                    )} />

                  <Route path="/OrgListReport"><OrgListReport /></Route>
                  <Route path="/PropListReport"><PropListReport /></Route>
                  <Route path="/PropModuleReport"><PropModuleReport /></Route>
                  <Route path="/CountryMaster"><CountryMaster /></Route>
                  <Route path="/StateMaster"><StateMaster /></Route>
                  <Route path="/CityMaster"><CityMaster /></Route>
                  <Route path="/AreaMaster"><AreaMaster /></Route>
                  <Route path="/AddNewCountry"
                    render={(props) => (
                      <AddNewCountry {...props}
                        regionData={props.location.state}
                        countryData={props.location.countryData}
                        countryMaster={props.location.countryMaster}
                        countryCode={props.location.countryCode}
                        button={props.location.button}
                      />
                    )}
                  />
                  <Route path="/AddNewState"
                    render={(props) => (
                      <AddNewState {...props}
                        countryData={props.location.countryData}
                        countryDetails={props.location.countryDetails}
                        state={props.location.state}
                        button={props.location.button}
                      />
                    )}
                  />
                  <Route path="/AddNewCity"
                    render={(props) => (
                      <AddNewCity {...props}
                        state={props.location.state}
                        countryData={props.location.countryData}
                        button={props.location.button}
                      />
                    )}
                  />
                  <Route path="/AddNewArea"
                    render={(props) => (
                      <AddNewArea {...props}
                        state={props.location.state}
                        countryData={props.location.countryData}
                        button={props.location.button}
                      />
                    )}

                  />

                </Switch>
              }

            </div>
          </ContextData.Provider>
        }

      </Container>
      <Footer />
    </>
  )
}

export default Home;