import { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import axios from "axios";
import { Container, Grid, Form, Button, Accordion, Icon, Input } from 'semantic-ui-react';
import ContextData from "./store/context-data";
import { Link } from "react-router-dom";
import FormCreateProperty from './FormCreateProperty';
import FormPropertyAddress from "./FormPropertyAddress";
import FormPropertyReg from "./FormPropertyReg";
const options = [
  { key: 'jan', text: 'January', value: '1' },
  { key: 'feb', text: 'February', value: '2' },
  { key: 'mar', text: 'March', value: '3' },
  { key: 'apr', text: 'April', value: '4' },
  { key: 'may', text: 'May', value: '5' },
  { key: 'jun', text: 'June', value: '6' },
  { key: 'jul', text: 'July', value: '7' },
  { key: 'aug', text: 'August', value: '8' },
  { key: 'sep', text: 'September', value: '9' },
  { key: 'oct', text: 'October', value: '10' },
  { key: 'nov', text: 'November', value: '11' },
  { key: 'dec', text: 'December', value: '12' },
]

const NewProperty = (props) => {
  // console.log("NewProperty", props);
  const ctx = useContext(ContextData)
  const [editProperty, setEditProperty] = useState(localStorage.getItem('editProperty') ? JSON.parse(localStorage.getItem('editProperty')) : '');
  // console.log(editProperty);           
  const [editData] = useState(localStorage.getItem('editData') ? JSON.parse(localStorage.getItem('editData')) : '')
  const localStrProp = sessionStorage.getItem('newProperty') ? JSON.parse(sessionStorage.getItem('newProperty')) : '';
  //  console.log(localStrProp.PropertyDetails.YearEndSettings.OpeningFinancialMonth );
  // const [minMaxIcon,setMinMaxIcon] = useState('[+]'); 
  // const [minMaxIcon3,setMinMaxIcon3] = useState('[+]');
  // const [open, setOpen] = useState(false); 
  // const [open3, setOpen3] = useState(false); 
  // const [enteredCountry,setCountry] = useState();
  // const [city,setCity] = useState();
  // const [state,setState] = useState();
  // const [area,setArea] = useState();
  // const [zipcode,setZipCode] = useState();
  // const [contactmobile,setContactMobile]=useState();
  // const [contacttelephone,setTelphone] = useState();
  // const [countryCode,setCountryCode] = useState();
  // const [areaCode, setAreaCode] = useState();
  // const [emailid, setEmailID] = useState();
  // const [website,setWebsite] = useState();
  // const [AddressLine1,setAddressLine1 ] =useState();
  // const [AddressLine2,setAddressLine2 ] =useState();
  // const [AddressLine3,setAddressLine3 ] =useState();
  // const [Landmark,setLandmark ] =useState();
  // const [countryMaster,setCountryMaster] = useState([]);
  const [formPropAdrsData, setFormPropAdrsData] = useState([]);
  const [ObjFormProperty, setFormProperty] = useState([]);
  const [propStatus, setPropStatus] = useState(0);
  const [formPropRegData, setFormPropRegData] = useState([]);
  const [financialMonth, setFinancialMonth] = useState((editData && editData.propertyDetails.yearEndSettings.openingFinancialMonth) || (localStrProp && localStrProp.PropertyDetails.YearEndSettings.OpeningFinancialMonth) || '4');
  const [startDay, setStartDay] = useState((editData && editData.propertyDetails.yearEndSettings.startDayOfTheMonth) || (localStrProp && localStrProp.PropertyDetails.YearEndSettings.StartDayOfTheMonth) || '1');

  const [monthdefault, setMonthDefault] = useState();
  let history = useHistory();

  const [activeIndex, setActiveIndex] = useState(0);
  //const [activeIndex1,setActiveIndex1] = useState(0);

  const goBack = () => {

    if (editProperty) {
      history.push(
        {
          pathname: "/ViewOrg",
          state: {
            organizationId: editProperty.organizationDetails.organizationId,
          }
        }
      );
    } else {
      history.goBack();
      setTimeout(() => window.scrollTo(0, 0), 100)
    }

  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []
  )
  useEffect(() => {
    props.propertyId &&
      axios.get(`${process.env.REACT_APP_BASE_API_URL}Organization/GetPropertyDetails`,
        {
          params: {
            'PropertyId': props.propertyId
          }, headers: { Authorization: `Bearer ${ctx.APIToken}` }
        },
      )
        .then((response) => {
          console.log("GetPropertyDetails",response)
          const data = response.data.response;
          data.organizationDetails = {};
          data.organizationDetails.organizationName = props.orgName;
          data.organizationDetails.organizationId = props.orgId;
          setEditProperty(data);
          localStorage.setItem('editProperty', JSON.stringify(data));
        })
    //console.log(props.propertyId,props.orgName, props.orgId)  
  }, [props.propertyId, props.orgName, props.orgId])

  useEffect(() => {
    if (financialMonth) {
      options.forEach((item) => {
        if (Number(item.value) === Number(financialMonth)) {
          setMonthDefault(item.text);
        }
      })
      if (financialMonth.length === 0) setMonthDefault('April');
    }
  }, [financialMonth])

  useEffect(() => {
    if (editProperty && !localStrProp) {
      setFinancialMonth(editProperty.propertyDetails.yearEndSettings.openingFinancialMonth);
      setStartDay(editProperty.propertyDetails.yearEndSettings.startDayOfTheMonth);
    }
    //   sessionStorage.getItem('userRights') && console.log('user rights from view org',JSON.parse(sessionStorage.getItem('userRights')))

  }, [editProperty])

  //console.log('find ', options.find(item=> item.value==financialMonth).text);
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;

    // const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex);
  }

  const formPropertyHandler = (data, propStatus) => {
    setFormProperty(data);
     console.log(data);
    setPropStatus(propStatus);

  }
  const formPropertyAdrsHandler = (data) => {
    setFormPropAdrsData(data);
    // console.log('data received from create property Address ');
    //   console.log(data);

  }

  const formPropertyRegHandler = (data) => {
    setFormPropRegData(data);
    //    console.log("Data recieved from property registration form");
     console.log("new prop -formPropertyRegHandler",data);
  }

  const formHandler = () => {
    const formPropertyData =
    {
      PropertyDetails: {
        ObjFormProperty,
        formPropAdrsData,
        Status: propStatus,
        formPropRegData,
        YearEndSettings: {
          OpeningFinancialMonth: financialMonth || 4,
          StartDayOfTheMonth: startDay || 1
        },
      },
    }
    //console.log(formPropertyData);
    props.formPropertyNameHandler(ObjFormProperty.PropertyName, formPropertyData);
    sessionStorage.setItem('newProperty', JSON.stringify(formPropertyData));
    // console.log('local storage for property data updated');
    //  console.log(JSON.parse(sessionStorage.getItem('newProperty')));
  }
  return (
    <>
      <Container>
        <FormCreateProperty formPropertyHandler={formPropertyHandler} editProperty={editProperty} />
        <FormPropertyAddress formPropertyAdrsHandler={formPropertyAdrsHandler} editProperty={editProperty} />
        <FormPropertyReg formPropertyRegHandler={formPropertyRegHandler} editProperty={editProperty} />
        <Grid>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              <b>Year End Settings </b>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Grid>
                <Grid.Row style={{ paddingLeft: '50px' }}>

                  <Form.Group inline    >
                    <label style={{ marginRight: '30px' }}> Opening Finacial Month</label>
                    <Input list="fmonth"
                      name="monthlist"
                      id="monthlist"
                      defaultValue={monthdefault}
                      style={{ width: '185px' }}
                      onChange={(e) => {
                        options.forEach((item) => {
                          if (item.text === e.target.value) {
                            setFinancialMonth(item.value);
                          }

                        })
                      }
                      }
                    />
                    <datalist id="fmonth" autoComplete="off">
                      {options
                        .map((month, idx) => {
                          return (

                            <option key={idx + month.text} value={month.text}
                            />

                          );

                        })

                      }
                    </datalist>
                  </Form.Group>
                  <Form.Group inline >
                    <label className="margin-month">Start Day of the Month</label>
                    <Input className="linegap"
                      defaultValue={startDay || 1}
                      onChange={(e) => setStartDay(e.target.value)}
                    />
                  </Form.Group>
                </Grid.Row>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </Grid>
        <Grid>
          <Grid.Row >
            <Grid.Column width={4}>
              <Button
                color='blue'
                onClick={goBack}
              > Go Back</Button>
            </Grid.Column>
            <Grid.Column width={4} floated='right'>
              {<Link to='/SelectModules'><Button color='blue' onClick={formHandler}>Next</Button></Link>}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div style={{ height: '100px' }}>

        </div>
      </Container>
    </>
  )

}

export default NewProperty; 
