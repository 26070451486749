import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { Container, Form, Grid, Button, Checkbox, Input } from 'semantic-ui-react'
import { Link, useHistory } from "react-router-dom";
import React from 'react'
//import ProgressSuccess from './ProgressSuccess';
import ContextData from "../store/context-data";
const SelectModules = (props) => {

  const ctx = useContext(ContextData);
  const [orgAddProperty] = useState(localStorage.getItem('orgAddProperty') ? JSON.parse(localStorage.getItem('orgAddProperty')) : '');
  const [editProperty] = useState(localStorage.getItem('editProperty') ? JSON.parse(localStorage.getItem('editProperty')) : '');
  // console.log(editProperty);
  const [editData] = useState(localStorage.getItem('editData') ? JSON.parse(localStorage.getItem('editData')) : '')
  const sessionOrgDetails = sessionStorage.getItem('SessionOrgDetails') ? JSON.parse(sessionStorage.getItem('SessionOrgDetails')) : '';
  //const [localStrSelectedModules, setLocalStrModules] = useState(localStorage.getItem('selectedModules') ?JSON.parse(localStorage.getItem('selectedModules')):'');    
  const [localStrProp] = useState(sessionStorage.getItem('newProperty') ? JSON.parse(sessionStorage.getItem('newProperty')) : '');
  const [selectModule, setSelectModule] = useState([]);
  const [touchedModule, setTouchedModule] = useState('');
  const [touchedSubModule, setTouchedSubModule] = useState('');

  const [toggle, setToggle] = useState(false);
  //const [bar,setPercent] = useState({percent:0,color:'red',toggleSave:true});
  //const [progColor,setProgColor] = useState();
  //const [v, setV] = useState(false);
  const [propertyName] = useState(localStrProp && localStrProp.PropertyDetails.ObjFormProperty.PropertyName)
  const [orgName, setOrgName] = useState((orgAddProperty && orgAddProperty.organizationDetails.organizationName) || (sessionOrgDetails && sessionOrgDetails.OrganizationDetails.OrganizationName))
  //const [sessionList] = useState(sessionStorage.getItem('newModules')?JSON.parse(sessionStorage.getItem('newModules')):[]);
  const [newSelectedModules, setNewSelectedModules] = useState(sessionStorage.getItem('newModulesforedit') ? JSON.parse(sessionStorage.getItem('newModulesforedit')) : '');
  const [newModulesfeatures, setNewModulesFeatures] = useState(sessionStorage.getItem('newModulesFeatures') ? JSON.parse(sessionStorage.getItem('newModulesFeatures')) : '');
  const [newSubModules, setNewSubModules] = useState(sessionStorage.getItem('newSubModules') ? JSON.parse(sessionStorage.getItem('newSubModules')) : '');
  // console.log('new session new new moules feature ',newModulesfeatures);
  //console.log('new session new submodules ',newSubModules);
  //console.log('new session new modules ',newSelectedModules);
  //const [check, setCheck] = useState();

  let history = useHistory();
  const localCheckBoxHandler = (modulecode) => {
    newSelectedModules.forEach((module) => {
      if (module.ModuleCode === modulecode) {
        // console.log(`already selected`);
        //setCheck(true)
        return true;
      }
      else { return; }
      // console.log(`already selected`);
      // setCheck();

    })
  }

  const goBack = () => {
    history.goBack();
    setTimeout(() => window.scrollTo(0, 0), 100);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []
  )

  useEffect(() => {
    if (editProperty) {
      setOrgName((editProperty.organizationDetails.organizationName))
      editProperty.propertyDetails.selectedModules.forEach((module) => {
        !newSelectedModules &&
          selectModule.forEach(mainmod => {
            setNewSelectedModules(prev =>
              [...prev, { ModuleCode: module.moduleCode, ModuleName: (mainmod.moduleCode === module.moduleCode && mainmod.moduleName) }].filter(item => item.ModuleName))
          }
          );
      })
      !newSubModules &&
        editProperty.propertyDetails.selectedModules.forEach((module) => {
          module.subModules.forEach((sub) => {
            //  console.log(sub.moduleCode);
            // console.log( editData.propertyDetails.selectedModules);
            selectModule.forEach(mainmod => {
              if (sub.moduleCode)
                mainmod.subModules.forEach(mainsub => {
                  if (mainsub.moduleCode === sub.moduleCode)
                    setNewSubModules((prev) => [...prev, { ModuleCode: sub.moduleCode, ModuleName: mainsub.moduleName }].filter(item => item.ModuleName));
                }

                )

            })
          })
        });

      !newSubModules &&
        editProperty.propertyDetails.selectedModules.forEach((module) => {
          module.features.forEach((ftr) => {
            // console.log(ftr);
            // console.log( editData.propertyDetails.selectedModules);
            selectModule.forEach(mainmod => {
              if (ftr)
                mainmod.features.forEach(Ftr => {
                  if (Ftr.featureId === ftr)
                    setNewModulesFeatures((prev) => [...prev, { featureId: ftr, featureName: Ftr.featureName }].filter(item => item.featureName))

                }
                )
            })
          })
        });

    }

    editData && editData.propertyDetails.selectedModules.forEach((module) => {
      !newSelectedModules &&
        selectModule.forEach(mainmod => {
          setNewSelectedModules(prev =>
            [...prev, { ModuleCode: module.moduleCode, ModuleName: (mainmod.moduleCode === module.moduleCode && mainmod.moduleName) }].filter(item => item.ModuleName))
        }
        );
    })
    editData && !newSubModules &&
      editData.propertyDetails.selectedModules.forEach((module) => {
        module.subModules.forEach((sub) => {
          //  console.log(sub.moduleCode);
          // console.log( editData.propertyDetails.selectedModules);
          selectModule.forEach(mainmod => {
            if (sub.moduleCode)
              mainmod.subModules.forEach(mainsub => {
                if (mainsub.moduleCode === sub.moduleCode)
                  setNewSubModules((prev) => [...prev, { ModuleCode: sub.moduleCode, ModuleName: mainsub.moduleName }].filter(item => item.ModuleName));
              }
              )
          })
        })
      });

    editData && !newSubModules &&
      editData.propertyDetails.selectedModules.forEach((module) => {
        module.features.forEach((ftr) => {
          // console.log(ftr);
          // console.log( editData.propertyDetails.selectedModules);
          selectModule.forEach(mainmod => {
            if (ftr)
              mainmod.features.forEach(Ftr => {
                if (Ftr.featureId === ftr)
                  setNewModulesFeatures((prev) => [...prev, { featureId: ftr, featureName: Ftr.featureName }].filter(item => item.featureName))

              }
              )
          })
        })
      });
    //console.log(newSelectedModules);                      
  }, [selectModule, editData, newSubModules, newSelectedModules, editProperty])

  useEffect(() => {
    //  console.log(toggle);
    //  console.log("from select modules ctx - propertyName"+ctx.propertyName);
    //   console.log(ctx.newProperty.propertDetails.propertyName);
  }, [toggle])

  const formHandler = () => {
    setToggle(true);

    const newModuleData = [{ ...newSelectedModules, Features: [...newModulesfeatures], SubModules: [...newSubModules] }]
    //  localStorage.setItem('selectedModules', JSON.stringify(selectModules));
    sessionStorage.setItem('newModules', JSON.stringify(newModuleData));
    sessionStorage.setItem('newModulesforedit', JSON.stringify(newSelectedModules));
    sessionStorage.setItem('newModulesFeatures', JSON.stringify(newModulesfeatures));
    sessionStorage.setItem('newSubModules', JSON.stringify(newSubModules));
    // console.log('new module data',newModuleData); 
  }



  useEffect(() => {
    //   console.log("Connecting...");
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetModuleList`,
      { headers: { Authorization: `Bearer ${ctx.APIToken}` } }
    )
      .then((response) => {
        console.log(response.data)
        const modulesData = response.data.response.modules;
        setSelectModule(modulesData);
        // setLoader(true);
      })
  }, [setSelectModule, ctx.APIToken]);

  return (
    <>
      <Container>
        <Grid>

          <Grid.Row textAlign='center' style={{ marginTop: '100px' }}>
            <h3>Select Modules</h3>
          </Grid.Row>

        </Grid>
        <Grid style={{ marginLeft: '2%' }}>

          <Grid.Row className='mb-2'>

            <Grid.Column width={6}>
              <Form>
                <Form.Group inline>
                  <label>Organization Name</label>
                  <Form.Field
                    control={Input}
                    //  placeholder={ctx.CtxOrgName} 
                    readOnly
                    defaultValue={orgName}
                    width={10}
                  /></Form.Group>
                <Form.Group inline>
                  <label>Property Name *</label>
                  <Form.Field
                    control={Input}
                    // placeholder={ctx.CtxOrgName} 
                    defaultValue={propertyName}
                    readOnly
                    width={10}
                  />
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>



          {
            <Grid.Row>

              <Grid.Column width={10} >  {/*Main div starts here*/}
                <Grid.Row >

                  <Grid.Column width='2'> <h4>Modules ({selectModule.length}) </h4></Grid.Column>
                  <Grid.Column width={4}>

                  </Grid.Column>

                </Grid.Row>

                <Grid.Row style={{ marginBottom: '20px' }}>   {/*Modules div starts here*/}
                  <Grid.Column width={1} > </Grid.Column >
                  <Grid.Column width={10} className='sm-module' >
                    {selectModule.map((item, idslct) => {
                      return (
                        <Grid.Row key={idslct} onClick={
                          () => {
                            //   console.log(item.moduleName);
                            setTouchedModule(item.moduleName);
                            setTouchedSubModule('');
                          }}
                          columns={2} className='sm-card'>
                          <Checkbox toggle style={{ float: 'right', zIndex: 0 }}
                            //   type="checkbox"
                            checked={Boolean(newSelectedModules && newSelectedModules.some((x) => x.ModuleCode === item.moduleCode))}
                            //checked = {check}
                            value={item.moduleName}
                            id={item.moduleCode}
                            // label={item.moduleName}
                            onChange={(e) => { //checkBoxHandler(e);
                              localCheckBoxHandler.bind(this, item.moduleCode)

                              if (e.target.checked === true) {
                                setNewSelectedModules((prev) => [...prev, { ModuleCode: e.target.id, ModuleName: e.target.value }]);

                              }
                              else {
                                const arr = newSelectedModules.filter((item) => item.ModuleCode !== e.target.id);
                                //console.log(arr);
                                setNewSelectedModules(arr);
                                var index = newSelectedModules.indexOf(e.target.id);
                                if (index > -1) {
                                  newSelectedModules.splice(index, 1);
                                }
                              }
                            }}
                          />
                          <Grid.Column width={10} className=''>{item.moduleName}</Grid.Column >
                          <Grid.Column width={5} className=''>

                          </Grid.Column >
                        </Grid.Row>

                      )
                    })
                    }

                  </Grid.Column >
                </Grid.Row>      {/*Modules div ends here*/}

                <Grid.Row >  {/*SubModules header and search starts here*/}

                  <Grid.Column width={4}> <h4>Sub Modules of {touchedModule} ({selectModule.map((item => {
                    if (item.subModules.length > 0 && touchedModule === item.moduleName)
                      return item.subModules.length;
                    return null;
                  }))}{selectModule.map((item => {
                    if (item.subModules.length === 0 && touchedModule === item.moduleName)
                      return item.subModules.length;
                    return null;
                  }))} )  </h4></Grid.Column >
                  <Grid.Column width={4}>
                  </Grid.Column >
                </Grid.Row>     {/*SubModules header and search ends here*/}
                <Grid.Row style={{ marginBottom: '20px' }}>   {/*SubModules div starts here*/}
                  <Grid.Column width={1} > </Grid.Column >
                  <Grid.Column width={10} className='sm-module'>

                    {/*session storage*/}



                    {
                      selectModule.map((item) => {

                        if (touchedModule === item.moduleName)
                          return (<>
                            {item.subModules.map((item, idsubm) => {
                              return (
                                <div key={idsubm} onClick={() => {
                                  //  console.log(item.moduleName);
                                  setTouchedSubModule(item.moduleName);

                                }}
                                  className='sm-card' style={{ float: 'left' }}>
                                  <Checkbox toggle style={{ float: 'right', zIndex: 0 }}

                                    // type="checkbox"
                                    id={item.moduleCode}
                                    value={item.moduleName}
                                    checked={Boolean(newSubModules && newSubModules.some((x) => x.ModuleCode === item.moduleCode))}
                                    onChange={(e) => {// checkBoxHandler(e);

                                      if (e.target.checked === true) {
                                        setNewSubModules((prev) => [...prev, { ModuleCode: e.target.id, ModuleName: e.target.value }]);

                                      }
                                      else {
                                        const arr = newSubModules.filter((item) => item.ModuleCode !== e.target.id);
                                        //console.log(arr);
                                        setNewSubModules(arr);

                                        var index = newSubModules.indexOf(e.target.id);
                                        if (index > -1) {
                                          newSubModules.splice(index, 1);
                                        }
                                      }
                                    }}
                                  />
                                  <Grid.Column width={7} className='txt '>{item.moduleName}</Grid.Column >
                                  <Grid.Column width={7} className='sel-check'>

                                  </Grid.Column >
                                </div>

                              )

                            })}


                          </>)
                        return null;
                      })
                    }

                  </Grid.Column >
                </Grid.Row>      {/*SubModules div ends here*/}

                <Grid.Row>  {/* Modules Features header and search starts here*/}

                  <Grid.Column width={4}> <h4>Features Of {(!touchedSubModule && touchedModule) || (touchedModule && touchedSubModule)} ( {

                    selectModule.map((item) => {

                      if (item.features.length > 0 && touchedModule === item.moduleName)
                        if (!touchedSubModule) return item.features.length;

                      return (<>
                        {item.subModules.map((sub) => {
                          if (touchedSubModule === sub.moduleName) {
                            //  setTouchedModule(()=>'');
                            return sub.features.length;
                          }
                          else return null;
                        })
                        }
                      </>)
                    })}
                    {

                      selectModule.map((item) => {
                        if (item.features.length === 0 && touchedModule === item.moduleName)
                          if (!touchedSubModule) return item.features.length;
                        return null;
                      })}
                    )
                  </h4></Grid.Column >
                  <Grid.Column width={4}>


                  </Grid.Column >
                </Grid.Row>

                {/*default- for display purpose Features header and search ends here*/}
                {!touchedSubModule && !touchedModule &&
                  <Grid.Row >   {/*default Features div starts here*/}
                    <Grid.Column width={1} > </Grid.Column >
                    <Grid.Column width={10} className='mb-2 sm-module'>
                    </Grid.Column >
                  </Grid.Row>}    {/* default Features div 1 ends her*/}
                {touchedSubModule &&
                  <Grid.Row >   {/*Features div for subModules starts here*/}
                    <Grid.Column width={1} > </Grid.Column >
                    <Grid.Column width={10} className='mb-2 sm-module'>

                      {selectModule.map((item) => {
                        return <>
                          {
                            item.subModules.map((sub) => {
                              if (touchedSubModule === sub.moduleName)
                                if (
                                  (touchedSubModule && !touchedModule) || (touchedModule && touchedSubModule)
                                )
                                  return <>
                                    {sub.features.map((item) => {

                                      return (
                                        <>
                                          <div className='sm-card' style={{ float: 'left' }} key={Math.random().toString}>
                                            <Checkbox toggle style={{ float: 'right', zIndex: 0 }}
                                              // type="checkbox"
                                              id={item.featureId.toString()}
                                              value={item.featureName}

                                            //  onChange={checkBoxHandler}
                                            />
                                            <Grid.Column width={7} className='txt '>{item.featureName} </Grid.Column >
                                            <Grid.Column width={5} className='sel-check'>

                                            </Grid.Column >
                                          </div>
                                        </>
                                      )
                                    })}
                                  </>
                              return null;
                            })
                          }

                        </>

                      })}

                    </Grid.Column >
                  </Grid.Row>}    {/*Features div subModules  ends her*/}

                {!touchedSubModule && touchedModule &&
                  <Grid.Row >   {/*Features of Modules starts here*/}
                    <Grid.Column width={1} > </Grid.Column >
                    <Grid.Column width={10} className='mb-2 sm-module'>

                      {selectModule.map((item) => {
                        if (touchedModule === item.moduleName && (!touchedSubModule && touchedModule)) {

                          return <>

                            {item.features.map((item) => {

                              return (
                                <>
                                  <div className='sm-card' style={{ float: 'left' }} key={Math.random().toString}>
                                    <Checkbox toggle style={{ float: 'right', zIndex: 0 }}
                                      //type="checkbox"
                                      key={item.featureId}
                                      id={item.featureId}
                                      value={item.featureName}
                                      checked={Boolean(newModulesfeatures && newModulesfeatures.some((x) => x.featureId === item.featureId))}

                                      onChange={(e) => {
                                        //   checkBoxHandler(e); 
                                        if (e.target.checked === true)
                                          setNewModulesFeatures((prev) => [...prev, { featureId: e.target.id, featureName: e.target.value }])
                                        else {
                                          const arr = newModulesfeatures.filter((item) => item.featureId !== e.target.id);
                                          //console.log(arr);
                                          setNewModulesFeatures(arr);
                                          var index = newModulesfeatures.indexOf(e.target.id);
                                          if (index > -1) {
                                            newModulesfeatures.splice(index, 1);
                                          }
                                        }
                                      }}
                                    />
                                    <Grid.Column width={7} className='txt '>{item.featureName} </Grid.Column >
                                    <Grid.Column width={5} className='sel-check'>

                                    </Grid.Column >
                                  </div>
                                </>
                              )
                            })}
                          </>

                        }
                        return null;
                      })
                      }

                    </Grid.Column >
                  </Grid.Row>}    {/*Features div 2 ends here*/}

              </Grid.Column>    {/*Main div ends here*/}
              {/* right div starts here*/}



              <Grid.Column width={5}
              >
                <Grid.Row >
                  <Grid.Column width='2'> </Grid.Column>
                  <Grid.Column width={4}>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className='side-list' >
                  {
                    newSelectedModules && newSelectedModules.map((module, mdid) => {
                      return <React.Fragment key={mdid}>
                        <ul >
                          <li><b>{module.ModuleName}</b></li>
                          {
                            newSubModules &&

                            newSubModules.map((subModule, subid) => {
                              if (module.ModuleCode === 'POS')
                                return (
                                  <li key={subid}>
                                    <ul>
                                      <li>{subModule.ModuleName}</li>
                                    </ul>
                                  </li>
                                )
                              return null;
                            })
                          }
                          {
                            newModulesfeatures &&
                            module.ModuleCode === 'POS' &&
                            newModulesfeatures.map((feature) => {
                              //  let xfeature = <ul> <li><b>Features</b></li></ul>;  
                              let xlist = <li><ul><li>{feature.featureName}</li>
                              </ul></li>;
                              // xfeature += xlist; not working
                              return <>
                                {xlist}
                              </>

                            })
                          }
                        </ul>
                      </React.Fragment>

                    })}


                </Grid.Row>
              </Grid.Column >

            </Grid.Row>}
          <Grid.Row>
            <Grid.Column width={10}>

            </Grid.Column>
            <Grid.Column width={4}>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row >
            <Grid.Column width={4}>
              <Button
                color='blue'
                onClick={goBack}
              > Go Back</Button>
            </Grid.Column>

            <Grid.Column width={4} floated='right'>
              {((editData || editProperty) &&
                <Link to='/LicensePreview'> <Button color='blue' onClick={formHandler}>Next </Button></Link>)
                ||
                <Link to='/DefaultUsers'> <Button color='blue' onClick={formHandler}>Next </Button></Link>
              }
            </Grid.Column>

          </Grid.Row>
        </Grid>
        <div style={{ height: '100px' }}>

        </div>
      </Container>
    </>

  )
}

export default SelectModules;
