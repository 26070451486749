import { useState,useContext,useEffect} from "react";
import axios from "axios";
import { Container, Grid, Form, Input, Button, Dropdown } from "semantic-ui-react";
import { Link,useHistory } from "react-router-dom";
import ContextData from '../../store/context-data';

const AddNewCity = (props) =>{
    const ctx = useContext(ContextData);
    const [cityMasterPageDetails,setCityMasterPageDetails] = useState('');
    const [stateMaster, setStateMaster] = useState('');

    const [propStatus,setPropStatus] = useState(1);
    let history = useHistory();  
    const [countryName,setCountryName] = useState('');
    const [countryCode,setCountryCode] = useState('');
    const [stateName, setStateName] = useState('');
    const [stateCode, setStateCode] = useState('');
    const [cityCode,setCityCode] = useState(0);
    const [cityName,setCityName] = useState('');
    const [spokenLanguageId,setSpokenLanguageId] = useState('');
    const [telephoneCode,setTelephoneCode] = useState('');
    const [latitude,setLatitude] = useState('');
    const [longitude,setLongitude] = useState('')
    const [cityShortName,setCityShortName]= useState('');
    const [readOnlyToggle,setReadOnlyToggle] = useState(false);
    const [headerMessage,setHeaderMessage] = useState('Create New City');
    const [cityDetails, setCityDetails] = useState('');
    
   // console.log(props.regionData)
    //console.log(props.countryData)

    const countryOptions = cityMasterPageDetails && cityMasterPageDetails.countryList.map((list, idr)=>{ return {
      key: idr,
      text: list.countryName,
      value:list.countryCode,
      disabled: readOnlyToggle
      }
    })
  const languageOptions = cityMasterPageDetails && cityMasterPageDetails.languageList.map((list, idr)=>{ return {
       key: idr,
       text: list.languageName,
       value:list.languageId,
       disabled: readOnlyToggle
       }
     })

     const stateOptions = stateMaster && stateMaster.stateList.map((state,idc)=> { 
      return  {
      key: idc,
      text: state.stateName,
      value: state.stateCode,
      
    }})
    //   stateOptions && stateOptions.map( 
    // item =>{ 
    //   stateName && 
    //     console.log(stateName) 
    //   if(item.text === cityDetails.stateName) return item.value; })
//  const currencyOptions = props.countryData.countryList.map((list, idr)=>{ return {
//      key: 1,
//      text: 'currency',
//      value:'s'
//  }
//  })
useEffect(()=>{
  if(props.state || props.countryData){
   // console.log(props.state)
    setCityCode(props.state.cityCode);
    setCountryName(props.state.countryName);
    setStateName(props.state.stateName); 
    setStateCode(props.state.stateCode);
    // language options
  axios.get(`${process.env.REACT_APP_BASE_API_URL}Master/GetCityMasterPageDetails`,{ 
    params:{
      StateCode:props.state.stateCode
    },
    headers: { Authorization: `Bearer ${ctx.APIToken}`}}
    ).then((response)=>{
    const data = response.data.response;
    //console.log('master city ',data)
    setCityMasterPageDetails(data);

 // console.log(props.button)
  if(props.button === 'view') {
     setReadOnlyToggle(true);
     setHeaderMessage('View City');
     }
  if(props.button === 'edit' ||  props.button === 'add') {
    setReadOnlyToggle(false);
    props.button === 'edit' && setHeaderMessage('Edit City');
    props.button === 'add' && setHeaderMessage('Add City');
  }
  
  
 }
 )   
}   
   
    },[props.state])
    useEffect(()=>{
       // for state dropdown   
    ctx.APIToken &&
    axios
    .get(
     `${process.env.REACT_APP_BASE_API_URL}Master/GetStateMasterList`,{
       params:{
        Status:1,
        CurrentPageNumber:1,
        NoOfRowsPerPage:50,
        ...(countryCode && {CountryCode: countryCode}),
       // ...(stateName && {StateName:stateName})
       }, 
      headers: { Authorization: `Bearer ${ctx.APIToken}`}
     }
    )
   .then((response) => {
  //   setLoaderToggle(false);
    const data = response.data.response;
    //console.log(data)
     setStateMaster(data);
   }).catch((error)=>{
     //console.log(error);
   })
    },[countryCode])

    useEffect(()=>{
        if(cityMasterPageDetails){
            cityMasterPageDetails.countryList.forEach(item => {
              if(item.countryName === props.state.countryName) setCountryCode(item.countryCode);
            });
        }
    },[cityMasterPageDetails])


    //https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/GetCityDetails?CityCode=<<CityCode>>
    useEffect(()=>{
      if(ctx.APIToken && props.state) {
      
      axios
      .get(
       `${process.env.REACT_APP_BASE_API_URL}Master/GetCityDetails`,{
        params:{CityCode:props.state.cityCode},
        headers: { Authorization: `Bearer ${ctx.APIToken}`}
       }
      )
     .then((response) => {
    //   setLoaderToggle(false);
      const data = response.data.response; 
       setCityDetails(data);  
     }).catch((error)=>{
       //console.log(error);
     }) 
    }
    },[props.state])
    useEffect(()=>{
        if(cityDetails){
          setStateCode(cityDetails.cityDetail.stateCode);
          setCityName(cityDetails.cityDetail.cityName);
          setCityCode(cityDetails.cityDetail.cityCode);
          setPropStatus(cityDetails.cityDetail.status);
    //setCountryName(data.stateDetails.countryName);
    setCountryCode(cityDetails.cityDetail.countryCode);
    
      setCityShortName(cityDetails.cityDetail.shortName);
     setSpokenLanguageId(cityDetails.cityDetail.spokenLanguageId);
     setTelephoneCode(cityDetails.cityDetail.telephoneCode);
     setLatitude(cityDetails.cityDetail.latitude);
     setLongitude(cityDetails.cityDetail.longitude); 
        }
    },[cityDetails])
    //https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/SaveCountry
 const saveHandler = () =>{
    const upload = {"CityCode": cityCode,
    "CityName": cityName,
    "ShortName": cityShortName,
    "StateCode": stateCode,
    "SpokenLanguageId": spokenLanguageId,
    "TelephoneCode": telephoneCode,
    "Latitude": Number(latitude),
    "Longitude": Number(longitude),
    "Status": propStatus,
   }
   //console.log(upload);

     axios.post(`${process.env.REACT_APP_BASE_API_URL}Master/SaveCity`,
      {"CityCode": cityCode,
      "CityName": cityName,
      "ShortName": cityShortName,
      "StateCode": stateCode,
      "SpokenLanguageId": spokenLanguageId,
      "TelephoneCode": telephoneCode,
      "Latitude": Number(latitude),
      "Longitude": Number(longitude),
      "Status": propStatus,
     },
       {headers: { Authorization: `Bearer ${ctx.APIToken}`}},  
     )
    .then((response)=>{
      if(response.data.message === 'Success')
      history.goBack();
         else alert(response.data.message);
    })  
 }
    return(
        <Container>
            <Grid>
            <Grid.Row columns={1} >
                     <Grid.Column textAlign="center" style={{marginTop:'10%', paddingTop:'10px'}}>
                         <h3>{headerMessage}</h3>
                     </Grid.Column> 
            </Grid.Row>
            <Grid.Row>
                 <Grid.Column width={10}>
                     <Form inline>
                     <Form.Field inline >  
                         <label style={{width:'105px'}}> Country Name *</label>
                        {  readOnlyToggle && <Input 
                          value={countryName}
                          style={{width:'550px'}}
                          readOnly
                          /> ||
                          <Dropdown 
                          selectOnBlur={false}
                          placeholder='Choose Country' 
                          search  
                          options={countryOptions}
                          selection 
                          value={countryCode} 
                          onChange={(e,data)=>setCountryCode(data.value)}
                          />   
                          }
                        
                     </Form.Field>

                     </Form>
                 </Grid.Column>
                 <Grid.Column style={{ paddingTop:'5px'}} width={5}>
                     <Form>
                     <Form.Group inline>
                 <label  style={{marginRight:'20px'}}>Status *</label> 
                 { readOnlyToggle &&
                 <input type="radio" id="active" name="prop-status" value="1" checked ={propStatus===1} 
                   /> ||
                   <input type="radio" id="active" name="prop-status" value="1" checked ={propStatus===1}
                   onChange={(e)=>{setPropStatus(Number(e.target.value)); //console.log(e.target.value);
                  }}
                   /> 
                   
                   }
                 <label>Active</label>
                 { readOnlyToggle &&
                 <input type="radio" id="passive" name="prop-status" value="0" 
                    checked ={propStatus===0} 
                    
                 />  ||
                 <input type="radio" id="passive" name="prop-status" value="0" 
                 checked ={propStatus===0}
                 onChange={(e)=>{setPropStatus(Number(e.target.value)); 
                  // console.log(e.target.value)
                  }}
              /> 
                 }
                 <label>Passive</label>
                </Form.Group>
                     </Form>
                
                 </Grid.Column>
               </Grid.Row>
               <Grid.Row>
                   <Grid.Column width={5} >
                   <Form inline>
                          <Form.Field inline>  
                              <label style={{width:'105px'}}>State Name * </label>
                             {readOnlyToggle && <Input 
                              value={stateName}
                              style={{width:'197px'}}
                              readOnly
                              /> ||
                              <Dropdown 
                              selectOnBlur={false}
                              placeholder='State' 
                              search  
                              options={stateOptions}
                              selection 
                              value={stateCode} 
                              onChange={(e,data)=>setStateCode(data.value)}
                              />   
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={5}>
                   
                   </Grid.Column>  
                 </Grid.Row> 
               <Grid.Row>
                   <Grid.Column width={5} >
                   <Form inline>
                          <Form.Field inline>  
                              <label style={{width:'105px'}}>City Name *</label>
                             { readOnlyToggle && <Input 
                              required
                              style={{width:'197px'}}
                              value={cityName} 
                              readOnly
                              /> ||
                              <Input 
                              required
                              style={{width:'197px'}}
                              value={cityName}
                              onChange={(e,data)=> setCityName(data.value)}
                              />
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={5}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{width:'100px'}}>Short Name</label>
                              { readOnlyToggle &&  <Input 
                              style={{width:'197px'}}
                              value={cityShortName}
                              readOnly
                              /> ||
                              <Input 
                              style={{width:'197px'}}
                              value={cityShortName}
                              onChange={(e,data)=> setCityShortName(data.value)}
                              /> 
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                 </Grid.Row> 
                 
                 <Grid.Row>
                   <Grid.Column width={5}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{width:'105px'}}>Spoken Language</label>
                             {readOnlyToggle && <Dropdown 
                              selectOnBlur={false}
                              placeholder='Language' 
                              search  
                              options={languageOptions}
                              selection 
                              value={spokenLanguageId} 
                              
                              /> ||
                              <Dropdown 
                              selectOnBlur={false}
                              placeholder='Language' 
                              search  
                              options={languageOptions}
                              selection 
                              value={spokenLanguageId} 
                              onChange={(e,data)=>setSpokenLanguageId(data.value)}
                              /> 

                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={5}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{width:'100px'}}>Telephone Code</label>
                              {readOnlyToggle && <Input 
                              style={{width:'197px'}}
                              value={telephoneCode} 
                              readOnly
                              /> ||
                              <Input 
                              style={{width:'197px'}}
                              value={telephoneCode}
                              onChange={(e,data)=> setTelephoneCode(data.value)}
                              /> 
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                 </Grid.Row> 
                 <Grid.Row>
                   <Grid.Column width={5}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{width:'105px'}}>Latitude </label>
                             {readOnlyToggle && <Input
                              style={{width:'197px'}}
                              value={latitude}
                              readOnly
                              /> ||
                              <Input
                              style={{width:'197px'}}
                              value={latitude}
                              onChange={(e,data)=> setLatitude(data.value)}
                              />

                             } 
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={6}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{width:'100px'}}>Longitude</label>
                              {readOnlyToggle &&
                                <Input
                               value={longitude} 
                               style={{width:'197px'}}
                               readOnly
                              /> ||
                              <Input
                               value={longitude}
                               onChange={(e,data)=> setLongitude(data.value)}
                               style={{width:'197px'}}
                              />
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                 </Grid.Row> 
                 <Grid.Row>
                     <Grid.Column width={3} floated="left">
                       <Button onClick={()=>{history.goBack();}}> Back</Button>
                     </Grid.Column>
                     <Grid.Column width={3} floated="right">
                       {!readOnlyToggle && <Button type ='submit' onClick={saveHandler}> Save</Button>}
                     </Grid.Column>
                 </Grid.Row>
            </Grid>
        </Container>
    )
}

export default AddNewCity;