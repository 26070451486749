import { useState, useEffect,useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Container, Form, Grid, 
  Pagination,Button, Dropdown,Table, 
  TableHeader, TableHeaderCell, Input,Card, Icon,Image } from "semantic-ui-react";
import ContextData from '../../store/context-data';
import { propTypes } from "react-bootstrap/esm/Image";

const AreaMaster = () => {
  const ctx = useContext(ContextData);
  const [userRightsDashboard, setUserRightsDashboard] = useState(sessionStorage.getItem('userRights')?JSON.parse(sessionStorage.getItem('userRights')):[])
  const [areaOption, setAreaOption] = useState(1);
  const [cityName,setCityName] = useState(''); 
  const [stateCode,setStateCode] = useState('');
  const [cityCode,setCityCode] = useState(''); 
  const [areaName, setAreaName] = useState('');
  const [pageData,setPageData] = useState('');
  const [regionData,setRegionData] = useState('');
  const [stateMaster, setStateMaster] = useState('');
  const [cityMasterList,setCityMasterList] = useState('');
  const [areaMasterPageDetails,setAreaMasterPageDetails] = useState('');
  const [areaMasterList,setAreaMasterList] = useState('');
  const [countryMaster,setCountryMaster] = useState('');
  const [countryDetails,setCountryDetails] = useState('');
  const [countryCode,setCountryCode] = useState('');
  const [searchTerm,setSearchTerm] = useState('');
  const [noOfRows, setNoOfRows] = useState(24);
  const [currentPageNumber,setCurrentPageNumber] = useState(1);
  const [Edit, setEdit] = useState();
  const [Add,setAdd] = useState();
  const [View, setView] = useState();
  const [AddArea, setAddArea] = useState();
  const [userType,setUserType] = useState();
  
  
  const selectOptions= [
    {
    key: 1,
    text: 24,
    value: 24,
  }, 
  {
    key: 2,
    text: 48,
    value: 48,
  },
  {
    key: 3,
    text: 96,
    value: 96,
  }
  ]
  const cityList = cityMasterList && cityMasterList.cityList.map((item, cid)=>{
    return  { 
       key: cid,
       text: item.cityName,
       value: item.cityCode,
    }
   }
   )
   const stateList = stateMaster && stateMaster.stateList.map((state,idc)=> { 
    return  {
    key: idc,
    text: state.stateName,
    value: state.stateCode,
  }})
  const countryList = areaMasterPageDetails && areaMasterPageDetails.countryList.map((item, cid)=>{
  
   return  { 
      key: cid,
      text: item.countryName,
      value: item.countryCode,
   }
  }
  )
  useEffect(()=>{
    areaMasterPageDetails && areaMasterPageDetails.pageControlRights.forEach(item =>{
     // console.log(item)
       item.controlName === 'Add' && setAdd(item.visible)
       item.controlName === 'Edit' && setEdit(item.visible)
       item.controlName === 'View' && setView(item.visible)
      // item.controlName === 'Add City' && setAddCity(item.visible) 
    })
    userRightsDashboard && userRightsDashboard.forEach(item=>{
       item.userType && setUserType(item)
    })
    
  },[areaMasterPageDetails])

  // State List
  useEffect(()=>{
    ctx.APIToken &&
    axios
    .get(
     `${process.env.REACT_APP_BASE_API_URL}Master/GetStateMasterList`,{
       params:{
        Status:2,
        CurrentPageNumber:1,
        NoOfRowsPerPage:50,
        ...(countryCode && {CountryCode:countryCode}),
        //StateName:stateName
       }, 
      headers: { Authorization: `Bearer ${ctx.APIToken}`}
     }
    )
   .then((response) => {
  //   setLoaderToggle(false);
    const data = response.data.response;
   // console.log('state list',data)
     setStateMaster(data);
     setCityMasterList('');
   }).catch((error)=>{
     //console.log(error);
   }) 
  },[countryCode])
  // for City List
  useEffect(()=>{
    ctx.APIToken &&
    axios
    .get(
     `${process.env.REACT_APP_BASE_API_URL}Master/GetCityMasterList`,{
       params:{
         ...(stateCode && {StateCode:stateCode})
       },
      headers: { Authorization: `Bearer ${ctx.APIToken}`}
     }
    )
    .then((response) => {
    //   setLoaderToggle(false);
      const data = response.data.response;
     //console.log('City master List ',data)
     setCityMasterList(data);  
    }).catch((error)=>{
     //console.log(error);
    }) 
   },[stateCode])

//'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/GetAreaMasterPageDetails'
useEffect(()=>{
  ctx.APIToken &&
  axios
  .get(
   `${process.env.REACT_APP_BASE_API_URL}Master/GetAreaMasterPageDetails`,{
    headers: { Authorization: `Bearer ${ctx.APIToken}`}
   }
  )
  .then((response) => {
  //   setLoaderToggle(false);
    const data = response.data.response;
   //console.log('Area master page details ',data)
   setAreaMasterPageDetails(data);  
  }).catch((error)=>{
   //console.log(error);
  }) 
 },[])

 // GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/GetAreaMasterList?Status=1&CurrentPageNumber=1&NoOfRowsPerPage=20&CountryCode=1&StateCode=1&CityCode=1&AreaName=Jeevan'
 useEffect(()=>{
  ctx.APIToken &&
 axios
 .get(
  `${process.env.REACT_APP_BASE_API_URL}Master/GetAreaMasterList`,{ 
    params:{
      Status:areaOption,
      CurrentPageNumber:currentPageNumber,
      NoOfRowsPerPage:noOfRows, 
      ...(countryCode && {CountryCode: countryCode}),
      ...(stateCode && {StateCode:stateCode}),
      ...(cityCode && {CityCode: cityCode}),
      ...(areaName && {AreaName:areaName}),
    },
    
   headers: { Authorization: `Bearer ${ctx.APIToken}`}
  }
 )
.then((response) => {
//   setLoaderToggle(false);
 const data = response.data.response;
// console.log('Area master list ',data)
  setAreaMasterList(data);  
}).catch((error)=>{
  //console.log(error);
}) 
},[noOfRows,currentPageNumber])

const searchHandler = () =>{ 
  ctx.APIToken &&
  axios
  .get(
   `${process.env.REACT_APP_BASE_API_URL}Master/GetAreaMasterList`,{ 
    params:{
      Status:areaOption,
      CurrentPageNumber:currentPageNumber,
      NoOfRowsPerPage:noOfRows, 
      ...(countryCode && {CountryCode: countryCode}),
      ...(stateCode && {StateCode:stateCode}),
      ...(cityCode && {CityCode: cityCode}),
      ...(areaName && {AreaName:areaName}),
    },
    headers: { Authorization: `Bearer ${ctx.APIToken}`}
   }
  )
 .then((response) => {
 //   setLoaderToggle(false);
  const data = response.data.response;
   
  setAreaMasterList(data);  
 }).catch((error)=>{
   //console.log(error);
 }) 
 }

 const searchClearHandler = (buttonname) =>{
  ctx.APIToken &&
  axios
  .get(
   `${process.env.REACT_APP_BASE_API_URL}Master/GetAreaMasterList`,{ 
    params:{
      Status:areaOption,
      CurrentPageNumber:currentPageNumber,
      NoOfRowsPerPage:noOfRows, 
      ...(countryCode && {CountryCode: countryCode}),
      ...(stateCode && {StateCode:stateCode}),
      ...(cityCode && {CityCode: cityCode}), 
      ...((buttonname === 'areaName') && {AreaName:''} ||
      areaName && {AreaName:areaName}
      ),
    },
    headers: { Authorization: `Bearer ${ctx.APIToken}`}
   }
  )
 .then((response) => {
 //   setLoaderToggle(false);
  const data = response.data.response;
   
  setAreaMasterList(data);  
 }).catch((error)=>{
   //console.log(error);
 }) 
 }
 
    return(
      <Container >
        <Grid>
                <Grid.Row columns={1}>
                     <Grid.Column textAlign="center" style={{marginTop:'80px', paddingTop:'10px'}}>
                         <h3>Area Master</h3>
                     </Grid.Column> 
                 </Grid.Row>
                 <Grid.Row columns={3} >
                   <Grid.Column width={9}> 
                     <Form inline>
                       <Form.Group>
                     
                      <Form.Field inline width={9}> 
                      <Dropdown   
                              placeholder='Country' 
                              fluid
                              clearable
                              selectOnBlur={false}  
                              search  
                              options={countryList}
                              selection 
                              value={countryCode}
                              onChange={(e,data)=>{
                                setCountryCode(data.value); 
                              }}   
                              /> 
                      </Form.Field> 
                      <Form.Field inline> 
                      <Dropdown   
                              placeholder='State' 
                              clearable
                              selectOnBlur={false} 
                              search  
                              options={stateList}
                              selection 
                              value={stateCode}
                              onChange={(e,data)=>{
                                setStateCode(data.value); 
                              }}   
                              /> 
                         
                      </Form.Field> 
                       <Form.Field inline> 
                       <Dropdown   
                              placeholder='City' 
                              selectOnBlur={false} 
                              clearable
                              search  
                              compact
                              options={cityList}
                              selection 
                              value={cityCode}
                              onChange={(e,data)=>{
                                setCityCode(data.value); 
                              }}   
                              /> 
                         
                      </Form.Field> 
                      <Form.Field inline> 
                         <Input style={{width:'160px'}}
                          value={areaName}
                          icon={areaName && <Icon name='delete' link onClick={(e,data)=>{ 
                           setAreaName(''); 
                           searchClearHandler('areaName');
                         }} />} 
                         placeholder='Search by Area Name'
                         onChange={(e,data)=>{setAreaName(data.value)}}
                         />
                         
                      </Form.Field> 
                   
                    
                       </Form.Group>
                    
                     </Form> 
                      </Grid.Column> 
                      <Grid.Column width={5} style={{paddingTop:'10px', position:'absolute', right:'170px'}}>
                   <Form> 
                <Form.Group inline key='orgstatus' style={{float:'right'}}>  
                <label>Status</label>
                 <Form.Field>
                 <Input 
                  type="radio" id="active" name="org-status" value='1' checked ={areaOption===1}
                      onChange={(e)=>{ 
                          setAreaOption(Number(e.target.value));
                          setCurrentPageNumber(1);  
                         // console.log(e.target.value);      
                     }}
                    />
                 <label>Active</label>
                 </Form.Field>
                 
                 <Form.Field>
                 <Input type="radio" id="passive" name="org-status" value='0'
                    checked ={areaOption===0}
                    onChange={(e)=>{
                      setAreaOption(Number(e.target.value)); 
                      setCurrentPageNumber(1);  
                        // console.log(e.target.value); 
                        }}
                 />
                  <label >Passive</label>
                 </Form.Field>
                    <Form.Field> 
                   <Input
                    type="radio" id="both" name="org-status" value='2'
                    checked ={areaOption===2}
                    onChange={(e)=>{
                      setAreaOption(Number(e.target.value));
                      setCurrentPageNumber(1);   
                        // console.log(e.target.value);   
                        }}
                     />
                     <label>Both</label> 
                   </Form.Field>
               
                 
                </Form.Group> 
              </Form>
                   </Grid.Column> 
                   <Grid.Column width={2} style={{ position:'absolute', right:'60px'}}>
                 <Button
                 color='blue'
                 onClick={searchHandler} style={{width:'130px'}}>
                       Search
                     </Button> 
                   </Grid.Column> 
                 </Grid.Row>
                 <Grid.Row columns={4}>
                 <Grid.Column width={2} style={{paddingRight:'0px',marginRight:'0px'}}>
                   <Form inline>
                     <Form.Field inline>  
                         <label> Select</label>
                         <Dropdown
                       compact
                       placeholder="24"
                       defaultValue='24' 
                       selection 
                       options={selectOptions} 
                       onChange={(e, data)=>{
                        setNoOfRows(data.value);
                        setCurrentPageNumber(1);  
                        window.scrollTo(0, 0);
                       }} 
                      /> 
                      </Form.Field>  
                     </Form>  
                  </Grid.Column> 
                 <Grid.Column width={3} floated="left" style={{paddingLeft:'0px'}}>
                       
                       <Pagination 
                   size='small' 
                   boundaryRange={null}
                   //defaultActivePage={1}
                   activePage={currentPageNumber}
                   color='blue'
                   ellipsisItem={null}
                   firstItem={1}
                   lastItem={null}
                   siblingRange={1}
                   totalPages={areaMasterList && areaMasterList.paginationDetail.totalPages}
                   onPageChange={ (e,data)=>{ setCurrentPageNumber(data.activePage);
                  }}
                 >  
                 </Pagination>
                       </Grid.Column> 
                  <Grid.Column width={7} >

                  </Grid.Column>
                   <Grid.Column width={3} style={{marginLeft:'10px', paddingRight:'0px', position:'absolute', right:'60px'}}>
                   { (Add || userType === 2)  &&    <Link 
                    to={ {
                      pathname: "/AddNewArea", 
                     // state: regionData,
                      countryData:areaMasterPageDetails,
                      button:'addNew' 
                    }}
                   > <Button 
                      color='blue'
                   style={{ float:'right', width:'130px', marginRight:'0px'}}>
                       + New Area
                     </Button> </Link>}
                   </Grid.Column> 
                 </Grid.Row>
                 <Grid.Row className="vcard-section">
                  <Card.Group itemsPerRow={5} className='dash-card-links' 
                  style={{paddingTop:'2%'}}> 
                {     
                areaMasterList && 
                areaMasterList.areaList.map((field)=>{  
                      if(searchTerm===""){
                        return field} else if (
                    field.countryName.toLowerCase().includes(searchTerm.toLowerCase())
                   ) { 
                    return field; 
                   }
                 }).map((field, kid) => { 
                 
                      if(field)
                   return(
                     
                    <Card color='grey' key={field.countryCode} style={{marginLeft:'20px', width:'250px'}} 
                    >
                         <Card.Content>
                        { field.imageUrl && <Image
                             floated='left'
                             style={{width:'50px'}}
                             src={field.imageUrl}
                           />   || <Icon name='picture' color='grey' size='huge' style={{ float:'left'}}/>
                        }
                           <Card.Meta 
                           style={ field.status === 1?{color:'green',position:'absolute',right:'5px',top:'0'}:{color:'red', position:'absolute',right:'5px', top:'0'}}
                            floated='right'
                           >{field.status===1?'Active':'Passive'}
                           </Card.Meta>
                           <div 
                           >
                           <div style={{fontSize:'2vh'}}><b>{field.areaName}</b></div>
                          
                           </div>
                           <div style={{fontSize:'2vh'}}>
                           {field.cityName}<br/>
                           {field.countryName} - {field.stateName}
                           </div>       
                         </Card.Content>
                         <Card.Content extra> 
                           <div>  
                              {  (Edit || (userType && userType.userType === 2)) && 
                           <Link to={{
                            pathname: "/AddNewArea", 
                            state:field,
                            countryData:areaMasterPageDetails,
                            button:'edit' 
                          }}
                             style={{marginRight:'5%'}}
                             key='Edit'
                             > 
                              <Icon  name='edit' color='red' />
                             <span>  Edit </span>
                             </Link>
                             } 
                             {  (View || (userType && userType.userType === 2)) && 
                             <Link to={{
                               pathname: "/AddNewArea", 
                               state: field,
                               countryData:areaMasterPageDetails,
                               button:'view'
                             }}
                              color='green'
                             style={{marginRight:'5%'}}
                             key='View'
                             >
                              <Icon  name='eye' color='green' />
                              <span> View </span> 
                             </Link>
                             } 
                           </div>
                         
                         </Card.Content>
                       </Card>    
                   ) 
                })
               }         
              </Card.Group> 
                  </Grid.Row>
               
        </Grid>
      </Container>
    )
    
}

export default AreaMaster;