import React from "react";
//import ReactDOM from 'react-dom';
import { useState, useEffect } from "react";
import { Form, Input, Button, Accordion, Icon, Grid, Card } from 'semantic-ui-react';

import AutoCompletePropReg from "./Components/AutoCompletePropReg";
import ModalMessage from "./Modal/ModalMessage";

const FormPropertyReg = (props) => {
  console.log("Form Property Registration", props);
  const [modalOpen, setModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [editProperty, setEditProperty] = useState(localStorage.getItem('editProperty') ? JSON.parse(localStorage.getItem('editProperty')) : '');
  const localStrProp = sessionStorage.getItem('newProperty') ? JSON.parse(sessionStorage.getItem('newProperty')) : '';
  const [editData] = useState(localStorage.getItem('editData') ? JSON.parse(localStorage.getItem('editData')) : '')
  // const [minMaxIcon2,setMinMaxIcon2] = useState('[+]');      
  // const [open2, setOpen2] = useState(false); 
  const [enteredPropRegId, setEnteredPropRegId] = useState();
  const [enteredPropType, setEnteredPropType] = useState();
  const [enteredRegNo, setEnteredRegNo] = useState('');
  const [propRegStatus, setPropRegStatus] = useState('1');
  // const[cardVal,setCardVal] = useState(); 
  // const[regCardRecieved,setRegCardRecieved] = useState([]);
  const [formRegData, setFormRegData] = useState(sessionStorage.getItem('newProperty') ? localStrProp.PropertyDetails.formPropRegData : []);


  //  const[cardRegGen,setCardRegGen] =useState(false); 
  const [activeIndex, setActiveIndex] = useState(0);
  const [clearInput, setClearInput] = useState(false);

  const [selectedRegistrationId, setselectedRegistrationId] = useState('');
  const [selectedRegistrationTypeName, setselectedRegistrationTypeName] = useState('');

  //   const [regCardStyle, setRegCardStyle] = useState({right:'0px' ,top:'0px', position:'absolute',display:'none' });
  // console.log('edit data.................',editData)

  //const setClose = () =>{
  //  setRegCardStyle({...regCardStyle,display:'block'});
  //}
  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    // const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex);
  }
  const PropTypeHandler = (id, type, regCard) => {
    console.log("from-PropTypeHandler", id, type)
    if (id && type) {
      setEnteredPropRegId(id);
      setEnteredPropType(type);
      setClearInput(false)
    }
    //  setRegCardRecieved(regCard);
    //  console.log('data recieved from AutoComplete prop reg handler -  ');
    //    console.log(id);
    //  console.log(type);
    //  console.log(regCard);
  }

  useEffect(() => {
    (editData && !localStrProp) && editData.propertyDetails.registrationDetails.forEach((item) => {
      setFormRegData((prev) =>
        [...prev,
        {
          "RegistrationId": item.registrationId,
          "RegistrationTypeId": Number(item.registrationTypeId),
          "registrationTypeName": item.registrationTypeName,
          "RegistrationNumber": item.registrationNumber,
          "Status": Number(item.status)
        }
        ].filter(item => item)
      );
    })
    // commented by dasprakash on 04-03-2024
    // if (editProperty) {
    //    console.log(' edit property from reg....', editProperty);    //   
    //   !localStrProp && editProperty.propertyDetails.registrationDetails.forEach((item) => {
    //         console.log('maja edit property wthin from reg....',item);
    //     setFormRegData((prev) =>
    //       [...prev,
    //       {
    //         "RegistrationId": item.registrationId,
    //         "RegistrationTypeId": Number(item.registrationTypeId),
    //         "registrationTypeName": item.registrationTypeName,
    //         "RegistrationNumber": item.registrationNumber,
    //         "Status": Number(item.status)
    //       }
    //       ].filter(item => item)
    //     );
    //   })
    // }


    // added by dasprakash on 04-03-2024
    if (editProperty) {
      console.log('ambani');
      console.log('or edit property from reg....', editProperty);
      !localStrProp &&
        setFormRegData(() =>
          editProperty.propertyDetails.registrationDetails.map((item) => ({
            RegistrationId: item.registrationId,
            RegistrationTypeId: Number(item.registrationTypeId),
            registrationTypeName: item.registrationTypeName,
            RegistrationNumber: item.registrationNumber,
            Status: Number(item.status)
          }))
        );
    }



  }, [editData, localStrProp, editProperty])

  useEffect(() => {
    props.editProperty &&
      setEditProperty(props.editProperty);
  }, [props.editProperty])


  useEffect(() => {
    props.formPropertyRegHandler(formRegData);

  }, [formRegData, props]);



  const addPropRegCard = () => {
    console.log("or majaFormRegData", formRegData);
    console.log("or majaenteredPropRegId", enteredPropRegId);
    console.log("or majaenteredPropType", enteredPropType);
    console.log("or majaenteredRegNo", enteredRegNo);
  
    const findRegistrationId = formRegData.find((i) => i.RegistrationTypeId === Number(enteredPropRegId));
    console.log("or findRegistrationId", findRegistrationId);
  
    if (findRegistrationId) {
      // Item with the same RegistrationTypeId already exists, update its values
      setFormRegData((prev) => prev.map((item) => {
        if (item.RegistrationTypeId === Number(enteredPropRegId)) {
          return {
            ...item,
            registrationTypeName: enteredPropType,
            RegistrationNumber: enteredRegNo,
            Status: Number(propRegStatus),
          };
        }
        return item;
      }));
      setEnteredPropType('');
      setEnteredRegNo('');
      setClearInput(true);
    } else if (
      enteredPropRegId &&
      enteredPropRegId !== '' &&
      enteredPropType &&
      enteredPropType.length > 0 &&
      enteredRegNo &&
      enteredRegNo.trim().length > 0
    ) {
      // Item with the RegistrationTypeId doesn't exist, add it to the formRegData
      setFormRegData((prev) => [
        ...prev,
        {
          RegistrationId: Number(enteredPropRegId),
          RegistrationTypeId: enteredPropRegId,
          registrationTypeName: enteredPropType,
          RegistrationNumber: enteredRegNo,
          Status: Number(propRegStatus),
        },
      ]);
  
      setEnteredPropType('');
      setEnteredPropRegId('');
      setEnteredRegNo('');
      setClearInput(true);
    } else {
      setModalOpen(true);
      setErrorMessage('Kindly Enter Valid Inputs');
    }
  };
  




  const modalToggleProps = () => {
    setModalOpen(false)
    setErrorMessage('')
  }
  const removeCardHandler = (value) => {
    const index = formRegData.indexOf(value);
    if (index > -1) {
      formRegData.splice(index, 1);
    }
    // console.log(formRegData); 
  }




  const CardDisplayer = () => {
    console.log("formRegData", formRegData)
    // console.log("das",editProperty.propertyDetails.registrationDetails)

    return (
      <Card.Group> {
        formRegData.map((item, idxr) => {
          return (
            <Card key={idxr} raised color='green'
              onClick={() => {
                setEnteredRegNo(item.RegistrationNumber);
                // PropTypeHandler(item.RegistrationId, item.registrationTypeName);
                setselectedRegistrationId(item.RegistrationId);
                setselectedRegistrationTypeName(item.registrationTypeName)
                setPropRegStatus(`${item.Status}`);
                console.log(item);
              }}
              className='regcard-content'
              style={{ backgroundColor: '#99b8b5', color: 'white', width: '200px' }}
            >
              <Card.Content>
                <div className='regcard-close' onClick={() => removeCardHandler(item)}>
                  <Icon name='close' color='red' />
                </div>
                <Card.Description>
                  <p>{item.registrationTypeName} -- {item.Status === 1 ? 'Active' : 'Passive'} </p>
                  <p>{item.RegistrationNumber} </p>
                </Card.Description>
              </Card.Content>
            </Card>
          )
        })
      }</Card.Group>
    );

  }


  return (
    <>
      <Accordion>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
        >
          <Icon name='dropdown' />
          <b> Property Registration Details</b>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <Grid stackable>
            <Grid.Row columns={3} style={{ paddingLeft: '50px' }}  >
              <Grid.Column width={3}  >
                <label>Registration Type</label>
                <AutoCompletePropReg formDataHandler={PropTypeHandler} clearInput={clearInput} selectedRegistrationId={selectedRegistrationId} selectedRegistrationTypeName={selectedRegistrationTypeName} />
              </Grid.Column>
              <Grid.Column width={3}>
                <label style={{ paddingRight: '10px' }}>Registration Number</label>
                <Input
                  //  control={Input} 
                  value={enteredRegNo}
                  onChange={(e) => {
                    setEnteredRegNo(e.target.value);
                    //   setRegCardRecieved(
                    //     (prevState)=>{ return (
                    //     [...prevState,{registrationNumber:e.target.value}])
                    //   });

                  }}
                />


              </Grid.Column>
              <Grid.Column width={6} >
                <Form>
                  <label style={{ width: '60px' }}>Status</label>
                  <Form.Group inline>
                    <input
                      type="radio"
                      id="active"
                      name="prop-status"
                      value="1"
                      checked={propRegStatus === '1'}
                      onChange={
                        (e) => {
                          setPropRegStatus(e.target.value);
                          // console.log(e.target.value);
                        }}
                    />
                    <label>Active</label>
                    <input type="radio" id="passive" name="prop-status" value="0"
                      checked={propRegStatus === '0'}
                      onChange={(e) => {
                        setPropRegStatus(e.target.value);
                        // console.log(e.target.value);
                      }}
                    />
                    <label>Passive</label>
                    <Button color='blue' onClick={addPropRegCard}>Add</Button>
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid.Row columns={6}
            style={
              {
                border: '1px solid #DADBDD ',
                padding: '10px', paddingLeft: '50px',
                height: '100px',
                overflowY: 'scroll',
                overflowX: 'hidden',
                marginBottom: '10px'
              }}
          >
            <CardDisplayer />
          </Grid.Row>
        </Accordion.Content>
      </Accordion>
      <ModalMessage open={modalOpen} modalToggleProps={modalToggleProps} message={errorMessage} />
    </>
  )

}

export default FormPropertyReg;
