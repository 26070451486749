import React from 'react';
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import {
  Container, Button, Card, Grid, Image,
  Icon, Form, Input, Pagination, Modal, Header, Table, Dropdown
} from 'semantic-ui-react'
import { Link, useHistory } from "react-router-dom";
//import { useMediaQuery } from 'react-responsive'; 
import ContextData from '../store/context-data';
//import Footer from './Footer';

const Dashboard = (props) => {
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  //  const isDesktopOrLaptop = useMediaQuery({
  //   query: '(min-width: 1224px)'
  //  })
  const ctx = useContext(ContextData);

  const [userData] = useState(sessionStorage.getItem("SessionUserLoginData") ? JSON.parse(sessionStorage.getItem("SessionUserLoginData")) : []);
  //console.log('userdata ......dash',userData.response);
  const [userType, setUserType] = useState('');
  const [dashboardData, setDashboardData] = useState('');
  const [message, setMessage] = useState('');
  const [orgData, setOrgData] = useState([]);
  const [searchEntered, setSearchEntered] = useState('');
  const [noofrows, setNoOfRows] = useState(24);
  const [currentpagenumber, setCurrentPageNumber] = useState(1);
  // const [totalpageno,setTotalPage] = useState([]);
  const [searchInit, setSearchInit] = useState();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalOrgName, setModalOrgName] = useState();
  const [modalOrgImage, setModalOrgImage] = useState('')
  const [remarksOrgId, setRemarksOrgId] = useState();
  const [usersData, setUsersData] = useState();
  const [remarksData, setRemarksData] = useState();
  const [enteredRemarks, setEnteredRemarks] = useState();
  // const [visible, setVisible] = useState(false);
  const [secondOpen, setSecondOpen] = useState(false);
  const [historyData, setHistoryData] = useState();
  const [remarksUdateMessage, setRemarksUdateMessage] = useState();
  const [userRights, setUserRights] = useState('');
  const [orgIdUsers, setOrgIdUsers] = useState('');


  //let orgName = ''
  //let orgImageurl  = ''  
  // let orgImageurl = ''
  // const [orgStyle,setOrgStyle] = useState(ctx.dashBoStyle);
  // const [orgPadding,setOrgPadding]=useState(ctx.orgPadding);
  const options = [{ key: '24', text: '24', value: 24 },
  { key: '48', text: '48', value: 48 },
  { key: '96', text: '96', value: 96 }
  ];

  // const [pageControlRights, setPageControlRights] = useState();
  const [productLink, setProductLink] = useState();
  const [getToken, setGetToken] = useState();
  const [Edit, setEdit] = useState()
  const [Create, setCreate] = useState();
  const [View, setView] = useState();
  const [AddProperty, setAddProperty] = useState();
  const [ViewUsers, setViewUsers] = useState();
  //const [loaderToggle,setLoaderToggle] = useState(false);
  const [charCount, setCharCount] = useState();
  const [updateToggle, setupdateToggle] = useState(false);
  const [updateUserHeader, setUpdateUserHeader] = useState('');




  let history = useHistory();
  const [orgOption, setOrgOption] = useState(1);

  const historyHandler = () => {
    setSecondOpen(true)
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetOrganizationRemarksHistory`,
      {
        params: {
          'OrganizationId': remarksOrgId,
          'NoOfRowsPerPage': 24,
          'CurrentPageNumber': 1
        },
        headers: { Authorization: `Bearer ${ctx.APIToken}` }
      },
    )
      .then((response) => {
        //console.log('getting remarks history.......,.,');
        //console.log(response.data)
        const data = response.data.response;
        setHistoryData(data);
      })
  }

  useEffect(() => {
    userData && setUserType(userData.response.userType);
    console.log('user data ...', userData.response);
  }, [userData])

  const remarksInputHandler = (e, data) => {
    setEnteredRemarks(data.value);
    //console.log(data.value);
    setRemarksUdateMessage('');
    var currentText = data.value;
    var characterCount = currentText.length;
    setCharCount(characterCount);
  }
  //  useEffect(()=>{
  //           if(remarksUdateMessage!=='')
  //                                 {
  //            axios.get(`${process.env.REACT_APP_BASE_API_URL}GetOrganizationRemarks`,
  //            { params: {
  //             'OrganizationId': remarksOrgId
  //            }, 
  //          
  //            headers: { Authorization: `Bearer ${ctx.APIToken}`}},  
  //           )
  //          .then((response)=>{
  //           // console.log('Remarks Data for Remarks - user update');
  //          //  console.log(response.data);
  //            const data = response.data.response; 
  //            if (  data && data.remarksDetails.remarks===''){
  //              setUpdateUserHeader('');
  //               setupdateToggle(false);}
  //            else{  setupdateToggle(true);
  //             data &&  data.remarksDetails && 
  //             setUpdateUserHeader(` Laste Update |${data.remarksDetails.lastUpdatedDateTime}
  //              | By ${data.remarksDetails.lastUpdatedUser}` ); 
  //               }
  //            setRemarksData(data); 
  //          }) 
  //  }},[remarksUdateMessage,ctx.APIToken,remarksOrgId]
  //  )          

  const remarksUpdateHandler = () => {

    axios.post(`${process.env.REACT_APP_BASE_API_URL}UpdateOrganizationRemarks`, {
      "OrganizationId": remarksOrgId,
      "Remarks": enteredRemarks,
    },
      {
        headers: { Authorization: `Bearer ${ctx.APIToken}` },
        "Content-Type": "application/json"
      })
      .then((response) => {
        //console.log(response.data);
        setRemarksUdateMessage('Updated Successfully')
      })
  }
  const addPropertyHandler = (orgId) => {
    localStorage.removeItem('editProperty');
    localStorage.removeItem('orgAddProperty');
    localStorage.removeItem('editData');
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetOrganizationDetails`,
      {
        params: {
          'OrganizationId': orgId
        }, headers: { Authorization: `Bearer ${ctx.APIToken}` }
      },
    )
      .then((response) => {
        // console.log(response.data);
        const data = response.data.response;
        localStorage.setItem('orgAddProperty', JSON.stringify(data));
        history.push('/NewProperty');
      })
    localStorage.removeItem("selectedModules");
    sessionStorage.removeItem('SessionOrgDetails');
    sessionStorage.removeItem('newProperty');
    localStorage.removeItem('defaultUsers');
    sessionStorage.removeItem('newModulesforedit');
    sessionStorage.removeItem('newModulesFeatures');
    sessionStorage.removeItem('newSubModules');
    sessionStorage.removeItem('sessionProperty');
  }

  const editHandler = (orgId) => {
    localStorage.removeItem('orgAddProperty');
    localStorage.removeItem('editData');
    localStorage.removeItem("selectedModules");
    sessionStorage.removeItem('SessionOrgDetails');
    sessionStorage.removeItem('newProperty');
    localStorage.removeItem('defaultUsers');
    sessionStorage.removeItem('newModulesforedit');
    sessionStorage.removeItem('newModulesFeatures');
    sessionStorage.removeItem('newSubModules');
    sessionStorage.removeItem('sessionProperty');
    localStorage.removeItem('editProperty');
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetOrganizationDetails`,
      {
        params: {
          'OrganizationId': orgId
        }, headers: { Authorization: `Bearer ${ctx.APIToken}` }
      },
    )
      .then((response) => {
        //  console.log('data missss ',response.data);
        const data = response.data.response;
        localStorage.setItem('editData', JSON.stringify(data));
        history.push('/NewOrganization');
      })
  }

  const remarksHandler = (orgId, orgName) => {
    setRemarksData(() => '');
    setRemarksUdateMessage('');
    setUpdateUserHeader(() => '');
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetOrganizationRemarks`,
      {
        params: {
          'OrganizationId': orgId
        },
        headers: { Authorization: `Bearer ${ctx.APIToken}` }
      },
    )
      .then((response) => {
        // console.log('Remarks Data for Remarks');
        // console.log(response.data);
        const data = response.data.response;
        setRemarksData(data);
        if (data.remarksDetails.remarks === '') {
          setUpdateUserHeader('');
          setupdateToggle(false);
        }
        else {
          setupdateToggle(true);
          data.remarksDetails &&
            setUpdateUserHeader(` Laste Update |${data.remarksDetails.lastUpdatedDateTime}
         | By ${data.remarksDetails.lastUpdatedUser}`);
        }
      })
    setModalOrgName(orgName);
    setRemarksOrgId(orgId);
    setModal(true);
  }

  const usersHandler = (orgIdv, orgName, orgImage) => {
    console.log(orgIdv, orgName, orgImage)
    setOrgIdUsers(orgIdv)
    localStorage.removeItem('userToken')
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetOrganizationUserList`,
      {
        params: {
          'OrganizationId': orgIdv
        },
        headers: { Authorization: `Bearer ${ctx.APIToken}` }
      },
    )
      .then((response) => {
        const data = response.data.response;
        setUsersData(data);
        // note there is very close similarities in the names userData and usersData (setUsersData) kindly rename or if you are ok
      })
    setModalOrgName(orgName);
    setModalOrgImage(orgImage);
    setOpen(true);

    // GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/GetUserManagementToken?OrganizationId=1000000003'
    // axios.get(`${process.env.REACT_APP_BASE_API_URL}GetUserManagementToken`,
    //  { params: {
    //   'OrganizationId': orgId
    // },  
    // headers: { Authorization: `Bearer ${ctx.APIToken}`}},  
    //)
    //.then((response)=>{ 
    //  //const data = response.data.response;
    //  //setUsersData(data);
    //  if (response.data.errorCode === 1) alert(response.data.message) 
    //  console.log(response.data) 
    //  if (response.data.errorCode === 0) {
    //   window.localStorage.setItem('userToken', JSON.stringify({token:response.data.response.token, orgName:orgName, orgImageurl:orgImageurl, userName:userData.response.userName, serverDate:userData.response.serverDate}))
    //  window.open('http://localhost:3000/', '_blank')
    //  window.open('https://dev.lucidits.com/LUCIDAPI/V1/', '_blank')
    //  var url = new URL(`http://foo.bar/?${x=1}&y=2`);
    // window.open(`http://localhost:8018/?token=${JSON.stringify({token:response.data.response.token,orgName:orgName,orgImageurl:orgImageurl})}`, '_blank') 
    //alert(localStorage.getItem('userToken')) 
    // console.log(JSON.parse(localStorage.getItem('userToken')))
    //window.open(`http://localhost:3001/?token=${JSON.stringify({token:response.data.response.token,orgName:orgName,orgImageurl:orgImageurl})}`, '_blank') 
    // const paramsObj = {token: response.data.response.token, orgName: orgName, orgImageurl: orgImageurl}
    // const searchParams = new URLSearchParams();
    // searchParams.append('token', paramsObj)
    // window.open(`http://localhost:3001/?${searchParams}`, '_blank') 

    // const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')
    //const encoded = encodeURI(`http://localhost:3001/?${(searchParams)}`);

    // take to the modal link 
    //    const params = {token:response.data.response.token,orgName:orgName,orgImageurl:orgImageurl,  userName:userData.response.userName, serverDate:userData.response.serverDate} 
    //   const searchParams = new URLSearchParams(params)
    // searchParams.append('token', queryString)
    // const encoded = encodeURI(`https://devum.lucidtest.in/?${(searchParams)}`);
    //  window.open(encoded, '_blank') 
    //  } 
    //   }) 
  }

  const userManagementHandler = () => {

    // GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/GetUserManagementToken?OrganizationId=1000000003'
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetUserManagementToken`,
      {
        params: {
          'OrganizationId': orgIdUsers
        },
        headers: { Authorization: `Bearer ${ctx.APIToken}` }
      },
    )
      .then((response) => {
        //const data = response.data.response;
        //setUsersData(data);
        if (response.data.errorCode === 1) alert(response.data.message)
        console.log(response.data)
        if (response.data.errorCode === 0) {
          window.localStorage.setItem('userToken', JSON.stringify({ token: response.data.response.token, orgName: modalOrgName, orgImageurl: modalOrgImage, userName: userData.response.userName, serverDate: userData.response.serverDate }))
          //  window.open('http://localhost:3000/', '_blank')
          //  window.open('https://dev.lucidits.com/LUCIDAPI/V1/', '_blank')
          //  var url = new URL(`http://foo.bar/?${x=1}&y=2`);
          // window.open(`http://localhost:8018/?token=${JSON.stringify({token:response.data.response.token,orgName:orgName,orgImageurl:orgImageurl})}`, '_blank') 
          //alert(localStorage.getItem('userToken')) 
          // console.log(JSON.parse(localStorage.getItem('userToken')))
          //window.open(`http://localhost:3001/?token=${JSON.stringify({token:response.data.response.token,orgName:orgName,orgImageurl:orgImageurl})}`, '_blank') 
          // const paramsObj = {token: response.data.response.token, orgName: orgName, orgImageurl: orgImageurl}
          // const searchParams = new URLSearchParams();
          // searchParams.append('token', paramsObj)
          // window.open(`http://localhost:3001/?${searchParams}`, '_blank') 

          // const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')
          //const encoded = encodeURI(`http://localhost:3001/?${(searchParams)}`); 

          const params = { token: response.data.response.token, orgName: modalOrgName, orgImageurl: modalOrgImage, userName: userData.response.userName, serverDate: userData.response.serverDate }
          console.log({ orgImageurl: modalOrgImage })
          const searchParams = new URLSearchParams(params)
          // local mode
          // const encoded = encodeURI(`http://localhost:3001/?${(searchParams)}`);

          // for development mode
          const encoded = encodeURI(`https://devum.lucidtest.in/?${(searchParams)}`);
          window.open(encoded, '_blank')
        }
      })
  }

  useEffect(() => {
    console.log(orgOption)
    axios.get(`${process.env.REACT_APP_BASE_API_URL}GetDashBoardDetails`, {
      params: {
        Status: orgOption
      },
      headers: { Authorization: `Bearer ${ctx.APIToken}` }
    }
    )
      .then((response) => {
        const data = response.data;
        // console.log(data)
        const PageControlArray = response.data.response.pageControlRights;
        setDashboardData(data);
        PageControlArray.forEach((element) => {
          // console.log(' user rights....', element)
          switch (element.controlName) {
            case 'Create':
              setCreate(element.enable)
              break;
            case 'Edit':
              setEdit(element.enable)
              break;
            case 'View':
              setView(element.enable)
              break;
            case 'Add Property':
              setAddProperty(element.enable)
              break;
            case 'View Users':
              setViewUsers(element.enable)
              break;
            case "Product Link":
              setProductLink(element.enable)
              break;
            case "Get Token":
              setGetToken(element.enable)
              break;
            default:
              return;
          }
        }
        )
        //    setPageControlRights(pagecontrols);
        setMessage(response.data.message)
        //  setPageControlRights(pagecontrols);
        setMessage(response.data.message)

      })
    if (userType === 2) {
      setCreate(true);
      setEdit(true);
      setView(true);
      setAddProperty(true);
      setViewUsers(true);
      setProductLink(true);
      setGetToken(true);
    }
  }, [orgOption, userType, ctx.APIToken]);


  useEffect(() => {

    const userRightsdta = [{ 'productLink': productLink },
    { 'getToken': getToken },
    { 'Edit': Edit },
    { 'Create': Create },
    { 'View': View },
    { 'AddProperty': AddProperty },
    { 'ViewUsers': ViewUsers },
    { 'productLink': productLink },
    { 'getToken': getToken },
    userType && { 'userType': userType }
    ]
    userRightsdta.length > 0 &&
      //console.log('dashboard user rights', userRightsdta)
      userRightsdta.length > 0 &&
      localStorage.setItem('userRights', JSON.stringify(userRightsdta))
    userRightsdta.length > 0 && setUserRights(userRightsdta)
    //console.log(productLink); 
    userRightsdta.length > 0 && props.rightsHandler(userRightsdta)
  }, [getToken, Edit, Create, View, AddProperty, ViewUsers, productLink, userType])


  const lucidPOSHandler = (orgid, link) => {

    axios.get(`${process.env.REACT_APP_LUCID_API_URL}GetLUCIDApplicationUrl`, {
      params: {
        'OrganizationId': orgid,
        'UrlType': link
      }, headers: { Authorization: `Bearer ${ctx.APIToken}` }
    }
    ).then((response) => {
      const url = response.data.response.url
      window.open(url)
    })
  }

  const searchHandler = () => {
    setSearchInit(searchEntered);
    setCurrentPageNumber(1);
    sessionStorage.setItem('searchItemDashbd', searchEntered);
  }
  useEffect(() => {
    if (sessionStorage.getItem('searchItemDashbd')) {
      setSearchEntered(sessionStorage.getItem('searchItemDashbd'))
      setSearchInit(sessionStorage.getItem('searchItemDashbd'))
      // console.log(sessionStorage.getItem('searchItemDashbd'))
    }
  }, [sessionStorage.getItem('searchItemDashbd')])

  const changePageHandler = (event, data) => {
    //console.log(data.activePage);
    setCurrentPageNumber(data.activePage);
  }

  useEffect(() => {
    //setLoaderToggle(true);
    // alert('me');
    //console.log('from useEffect ', sessionStorage.getItem('searchItemDashbd'))
    axios
      .post(
        `${process.env.REACT_APP_BASE_API_URL}GetOrganizationList`, {
        NoOfRowsPerPage: noofrows,
        CurrentPageNumber: currentpagenumber,
        SearchBy: {
          OrganizationName: sessionStorage.getItem('searchItemDashbd') || searchInit,
          Status: orgOption
        }
      },
        {
          headers: { Authorization: `Bearer ${ctx.APIToken}` },
          "Content-Type": "application/json"
        }
      )
      .then((response) => {
        //   setLoaderToggle(false);
        const data = response.data;
        setOrgData(data);

      }).catch((error) => {
        //console.log(error);
      })
  }, [setOrgData, searchInit, noofrows, currentpagenumber, ctx.APIToken, orgOption, sessionStorage.getItem('searchItemDashbd')]);
  useEffect(() => {
    dashboardData &&
      props.menus(dashboardData.response);
    //console.log('menus data passed from useeffect',dashboardData.response);
  }, [dashboardData, props])
  if (message === 'Success') {
    return (
      <>
        <Container>
          <Modal style={{ maxWidth: '30%' }}
            closeIcon
            dimmer='blurring'
            open={modal}
            //  trigger={modal}
            onClose={() => setModal(false)}
            onOpen={() => setModal(true)}
          >

            {
              <>
                <Header icon='archive' content={`${modalOrgName} - Remarks`} />
                <div style={{ textAlign: 'center', padding: '5px', paddingTop: '10px', fontSize: '12px', maxWidth: '400px' }}>
                  {updateToggle && updateUserHeader
                  }
                </div>
                <div style={{ fontSize: '12px', textAlign: 'right', paddingRight: '19px' }}>
                  <Link to=''
                    onClick={historyHandler}
                  > History</Link>
                </div>
                <Modal.Content  >
                  <Form>
                    <Form.TextArea
                      label='' placeholder='Remarks'
                      onChange={remarksInputHandler}
                      defaultValue={remarksData && remarksData.remarksDetails.remarks}
                      style={{ height: '200px' }}
                    />
                  </Form>
                  <div style={{ color: 'green', fontSize: '12px', textAlign: 'right' }}> {remarksUdateMessage}</div>
                  <div style={{ fontSize: '12px' }}>(Max Char {charCount}/1000)</div>
                </Modal.Content>
              </>
            }
            <Modal.Actions>
              <Button color='blue' onClick={remarksUpdateHandler}>
                <Icon name='save' /> Update
              </Button>

            </Modal.Actions>
            <Modal className='modal-history'
              onClose={() => setSecondOpen(false)}
              open={secondOpen}
              size='tiny'
            >
              <Modal.Header>History</Modal.Header>
              <Modal.Content>
                <div style={{ maxHeight: '260px', overflowY: 'scroll' }}>
                  {historyData && historyData.remarksHistory.map((item, idx) => {
                    return <> <div key={idx}
                      style={{ fontSize: '12px' }}
                    > <b>
                        {` ${item.updatedDateTime} , ${item.updatedUser}`}
                      </b>
                    </div>
                      <Form.TextArea disabled value={` ${item.remarks} `} style={{ width: '100%', marginBottom: '20px' }}>
                      </Form.TextArea>
                    </>
                  })
                  }
                </div>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  icon='check'
                  content='Close'
                  onClick={() => setSecondOpen(false)}
                />
              </Modal.Actions>
            </Modal>
          </Modal>
          
          <Modal style={{ maxWidth: '450px' }}
            closeIcon
            dimmer='blurring'
            open={open}
            //  trigger={modal}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
          >
            <Header icon='archive' style={{ paddingBottom: '5px' }}>
              {`${modalOrgName} - Users`}
              <div style={{ position: 'absolute', right: '5px', top: '5px', fontSize: '13px', textAlign: 'right', margin: '0px', padding: '0px' }}>
                <Button color='primary' onClick={userManagementHandler} style={{ marginBottom: '10px' }}>User Management</Button>
              </div>
            </Header>
            <Modal.Content >
              <Table celled>
                <Table.Header>
                  <Table.Row textAlign='center'>
                    <Table.HeaderCell>User Id</Table.HeaderCell>
                    <Table.HeaderCell>Password</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {usersData &&
                    usersData.users.map((user, idi) => {
                      return (
                        <>
                          <Table.Row key={idi}>
                            <Table.HeaderCell textAlign='center' colSpan={2}> {user.userLevel}</Table.HeaderCell>
                          </Table.Row>
                          {user.userList.map((user, idx) => {
                            return <>
                              <Table.Row key={idx}>
                                <Table.Cell>{user.userId}</Table.Cell>
                                <Table.Cell>{user.password}</Table.Cell>
                              </Table.Row>
                            </>
                          })}
                        </>
                      )
                    }
                    )}
                </Table.Body>
              </Table>
            </Modal.Content>
            <Modal.Actions>
            </Modal.Actions>
          </Modal>

          <Grid>
            <Container>
              <Grid.Row columns={1} className='row-container'>
                <Grid.Column className='row-org'
                >
                  <Grid.Column >
                    <Card.Group itemsPerRow={4} >
                      <Card raised key='nooforg'
                        className='cardhover'
                        style={
                          {
                            boxSizing: 'border-box',
                            minHeight: '70px',
                            width: '238px',
                            textAlign: 'center',
                            backgroundColor: '#9AE3F9',
                            color: 'black'
                          }}
                      >
                        <Card.Content style={{ padding: '0px' }}>
                          <div className='card-title'>
                            Organisations
                          </div>
                          <div className='title-count'>
                            {
                              dashboardData.response.noOfOrganizations
                            }
                          </div>
                        </Card.Content>
                      </Card>
                      <Card raised key='newOrg'
                        className='cardhover'
                        style={
                          {
                            boxSizing: 'border-box',
                            minHeight: '80px',
                            width: '238px',
                            textAlign: 'center',
                            backgroundColor: '#F99A9A',
                            color: 'black'
                          }}
                      >
                        <Card.Content style={{ padding: '0px' }}>
                          <div className='card-title'
                          >
                            New Organisations
                          </div>
                          <div className='title-count'
                          >
                            {
                              dashboardData.response.newOrganizations
                            }
                          </div>
                          <div style={{ padding: '0px 7px', right: '0px', bottom: '0px', position: 'absolute', fontSize: '10px' }}>
                            Last 30 Days
                          </div>
                        </Card.Content>
                      </Card>
                      <Card raised key='Property'
                        className='cardhover'
                        style={
                          {
                            boxSizing: 'border-box',
                            minHeight: '80px',
                            width: '238px',
                            textAlign: 'center',
                            backgroundColor: '#A6F99A',
                            color: 'black'
                          }}
                      >
                        <Card.Content style={{ padding: '0px' }}>
                          <div className='card-title'
                          >
                            Properties
                          </div>
                          <div className='title-count'
                          >
                            {
                              dashboardData.response.noOfProperties
                            }
                          </div>
                        </Card.Content>

                      </Card>
                      <Card raised key='newProp'
                        className='cardhover'
                        style={
                          {
                            boxSizing: 'border-box',
                            minHeight: '80px',
                            width: '238px',
                            textAlign: 'center',
                            backgroundColor: '#CF9AF9',
                            color: 'black'
                          }}>
                        <Card.Content style={{ padding: '0px' }}>
                          <div className='card-title'
                          >
                            New Properties
                          </div>
                          <div className='title-count'
                          >
                            {
                              dashboardData.response.newProperties
                            }
                          </div>
                          <div style={{ padding: '0px7px', right: '0px', bottom: '0px', position: 'absolute', fontSize: '10px' }}>
                            Last 30 Days
                          </div>
                        </Card.Content>
                      </Card>
                    </Card.Group>
                  </Grid.Column>

                  {   /* starts search header starts */
                  }

                  <Grid.Column width={16} style={{ marginTop: '10px' }} className='mob-view'>
                    <div style={{ float: 'left', minWidth: '420px' }}>
                      <Form.Group inline>
                        <Input
                          icon={searchEntered && <Icon name='delete' link onClick={() => {
                            setSearchEntered('');
                            setSearchInit('');
                            sessionStorage.removeItem('searchItemDashbd');
                          }} />}
                          style={{ width: '240px', marginRight: '5px' }}
                          value={searchEntered}
                          onKeyPress={(e) => {
                            // setSearchEntered(e.target.value);
                            //  console.log(e); 
                            if (e.key === 'Enter') {
                              searchHandler();
                              e.preventDefault();
                            }
                          }}
                          onChange={
                            (e) => {
                              setSearchEntered(e.target.value);
                              if (e.target.value === '')
                                setSearchInit('');
                              sessionStorage.removeItem('searchItemDashbd');
                            }
                          }
                          type="text" placeholder="Search by Organization Name"
                        />
                        <Button color='blue' onClick={searchHandler}>Search</Button>
                      </Form.Group>

                    </div>

                    <div style={{ display: 'inline', marginTop: '5px' }}>
                      <div style={{ float: 'left', width: '430px' }}>

                        <label>Select</label>

                        <Dropdown
                          // text='Select'
                          style={{
                            border: 'solid thin #DADBDD',
                            boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.2)',
                            borderRadius: '3px',
                            marginRight: '5px',
                            padding: '7px',
                            paddingBottom: '9px',
                            paddingTop: '7px',
                            textAlign: 'center'
                          }}
                          defaultValue={24}
                          placeholder='24'
                          options={options}
                          onChange={(e, data) => {
                            setNoOfRows(data.value);
                            setCurrentPageNumber(1);
                            window.scrollTo(0, 0);
                          }}
                        />
                        {orgData.response &&
                          <Pagination
                            size='small'
                            boundaryRange={null}
                            //defaultActivePage={1}
                            activePage={currentpagenumber}
                            color='blue'
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={orgData.response.paginationDetail.totalPages}
                            siblingRange={1}
                            totalPages={orgData.response.paginationDetail.totalPages}
                            onPageChange={changePageHandler}
                          >
                          </Pagination>}
                      </div>
                    </div>
                    {
                      (Create || userType === 2) &&
                      <Link to='/NewOrganization' style={{ marginLeft: '', paddingLeft: '0px' }}>
                        <Button color='blue' size="small" onClick={() => {
                          localStorage.removeItem("orgAddProperty");
                          localStorage.removeItem("selectedModules");
                          sessionStorage.removeItem('SessionOrgDetails');
                          sessionStorage.removeItem('newProperty');
                          localStorage.removeItem('defaultUsers');
                          sessionStorage.removeItem('newModulesforedit');
                          sessionStorage.removeItem('newModulesFeatures');
                          sessionStorage.removeItem('newSubModules');
                          localStorage.removeItem('editData');
                          sessionStorage.removeItem('sessionProperty');
                          localStorage.removeItem('editProperty');
                        }}> + New Organization</Button></Link>
                    }
                  </Grid.Column>

                  <div style={{ width: '100%', height: '50px', float: 'left' }}>
                    <Form>
                      <Form.Group inline key='orgname'>

                      </Form.Group>
                      <Form.Group inline key='orgstatus'>
                        <label className=''>  Organization </label>
                        <input
                          type="radio" id="active" name="org-status" value='1' checked={orgOption === 1}
                          onChange={(e) => {
                            setOrgOption(Number(e.target.value));
                            setCurrentPageNumber(1);
                            // console.log(e.target.value); 
                          }}
                        />
                        <label>Active</label>
                        <input type="radio" id="passive" name="org-status" value='0'
                          checked={orgOption === 0}
                          onChange={(e) => {
                            setOrgOption(Number(e.target.value));
                            // console.log(e.target.value);
                            setCurrentPageNumber(1);
                          }}
                        />
                        <label >Passive</label>
                        <input type="radio" id="both" name="org-status" value='2'
                          checked={orgOption === 2}
                          onChange={(e) => {
                            setOrgOption(Number(e.target.value));
                            // console.log(e.target.value);
                            setCurrentPageNumber(1);
                          }}
                        />
                        <label >Both</label>
                      </Form.Group>
                    </Form>

                  </div>


                  {
                    /*
                       starts search header ends
                     */
                  }
                </Grid.Column>
              </Grid.Row>
            </Container>


            <Grid.Row className='cardSection' style={{ marginLeft: '77px' }}>
              {
                userRights &&
                <ContextData.Provider value={{
                  userRights: userRights
                }}>
                  <Card.Group itemsPerRow={3} className='dash-card-links'>
                    {
                      orgData.response &&
                      orgData.response.organizationList.map((field, idx) => {
                        return (
                          <Card color='grey' key={idx} style={{ marginLeft: '20px', width: '310px' }}
                          >
                            <Card.Content
                              style={{ paddingBottom: '0px' }}
                            >
                              <div style={{ float: 'left' }}>
                                {(field.imageUrl && <Image
                                  rounded
                                  floated='left'
                                  style={{ width: '50px', paddingBottom: '0px' }}
                                  src={field.imageUrl}
                                />) || <Icon name='picture' color='grey' size='huge'
                                  style={{ float: 'left' }}
                                  />
                                }
                              </div>
                              <Card.Meta
                                style={field.statusDisplayName === 'Active' ? { color: 'green', position: 'absolute', right: '5px', top: '0' } : { color: 'red', position: 'absolute', right: '5px', top: '0' }}
                                floated='right'
                              >{field.statusDisplayName}</Card.Meta>
                              <div
                                style={{
                                  float: 'left', width: '60%', fontSize: '2vh',

                                }}
                              >
                                <div style={{ wordWrap: 'break-word', fontSize: '2vh' }}>
                                  <b>{field.organizationName}</b></div>
                                <div style={{ fontSize: '10px', lineHeight: '5px', marginBottom: '5px' }}>
                                  {field.organizationId}</div>
                                {field.city} - {field.state}
                              </div>
                              {(productLink || userType === 2) &&
                                <div style={{
                                  clear: 'left',
                                  marginBottom: '0px',
                                  paddingTop: '5px',
                                  fontSize: '2vh',
                                }}>
                                  <li
                                    onClick={() => lucidPOSHandler(field.organizationId, 1)}
                                    className="viewOrg-Remarks"
                                  >
                                    LUCIDPOS
                                  </li>
                                  <li
                                    onClick={() => lucidPOSHandler(field.organizationId, 2)}
                                    className="viewOrg-Remarks">
                                    LUCIDOnlineOrders
                                  </li>
                                </div>
                              }
                            </Card.Content>
                            <Card.Content extra
                              style={{
                                marginTop: '0px',
                                paddingBottom: '0px',
                                borderBottom: 'solid 1px',
                                borderBottomColor: 'rgb(221, 221, 221)',
                                float: 'left'
                              }}
                            >
                              <div >
                                {(ViewUsers || userType === 2) &&

                                  <Link to=""
                                    onClick={usersHandler.bind(this, field.organizationId, field.organizationName, field.imageUrl)}
                                    style={{ marginRight: '3%' }}
                                    key='Users'
                                  >
                                    <Icon name='users' color='blue' />
                                    <span style={{ fontSize: '' }}>Users</span>
                                  </Link>
                                }
                                <Link to="" onClick={remarksHandler.bind(this, field.organizationId, field.organizationName)}
                                  style={{
                                    marginRight: '3%',
                                    float: 'left'
                                  }}
                                  key='Remarks'
                                >
                                  <Icon name='write' color='blue' />
                                  <span style={{ fontSize: '' }}> Remarks </span>
                                </Link>

                                {(Edit || userType === 2) &&
                                  <Link to=""
                                    onClick={editHandler.bind(this, field.organizationId)}
                                    style={{ marginRight: '3%', float: 'left' }}
                                    key='Edit'
                                  >
                                    <Icon name='edit' color='red' />
                                    <span style={{ fontSize: '' }}>  Edit </span>
                                  </Link>
                                }
                                {(View || userType === 2) &&
                                  <Link to={{
                                    pathname: "/ViewOrg",
                                    state: { organizationId: field.organizationId }
                                  }}
                                    color='green'
                                    style={{ marginRight: '3%', }}
                                    key='View'
                                  >
                                    <Icon name='eye' color='green' />
                                    <span style={{ fontSize: '' }}> View </span>
                                  </Link>
                                }

                              </div>
                              <div style={{ float: 'left' }}>

                              </div>
                              <div>

                                {(AddProperty || userType === 2) &&
                                  <Link to='' color='red'
                                    onClick={addPropertyHandler.bind(this, field.organizationId)}
                                    style={{ marginRight: '3%' }}
                                    key='Addproperty'
                                  >
                                    <Icon name='add' color='yellow' />
                                    <span style={{ fontSize: '' }}> Add Property</span>
                                  </Link>
                                }
                              </div>
                            </Card.Content>
                            <Card.Content extra style={{ fontSize: '10px', color: 'black', borderTop: 'solid 1px', borderTopColor: 'rgb(221, 221, 221)', marginTop: '1px' }}>
                              <div style={{ float: 'left', marginRight: '10px', marginTop: '1px' }}>Created Date: {field.createdDate} </div>
                              <div style={{ marginTop: '1px', wordBreak: 'keep-all' }}> User ID: {field.createdBy}</div>
                            </Card.Content>
                          </Card>
                        )
                      })
                    }
                  </Card.Group>
                </ContextData.Provider>}
            </Grid.Row>
            <div style={{ height: '100px' }}>

            </div>

          </Grid>

        </Container>
      </>
    )
  }
  else { return <h3>Loading ...</h3> }
}

export default Dashboard;
