import { useState,useContext, useEffect} from "react";
import axios from "axios";
import { Container, Grid, Form, Input, Button, Dropdown } from "semantic-ui-react";
import { Link,useHistory } from "react-router-dom";
import ContextData from '../../store/context-data';

const AddNewArea = (props) =>{
    const ctx = useContext(ContextData);
    const [stateMaster, setStateMaster] = useState('');
    const [cityMaster,setCityMaster] = useState(''); 
    const [areaDetails,setAreaDetails] = useState('');
    const [countryName, setCountryName] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [stateName,setStateName] = useState('');
    const [stateCode,setStateCode] = useState('');
    const [cityName,setCityName] = useState('');
    const [propStatus,setPropStatus] = useState(1);
    const [areaCode, setAreaCode] = useState(0)
    const [areaName,setAreaName] = useState('');
    const [areaShortName,setAreaShortName] = useState('');
    const [cityCode,setCityCode] = useState('');
    const [spokenLanguageId,setSpokenLanguageId] = useState('');
    const [zipCode,setZipCode] = useState('');
    const [telephoneCode, setTelephoneCode] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude,setLongitude] = useState('');
    const [readOnlyToggle,setReadOnlyToggle] = useState(false);
    const [headerMessage,setHeaderMessage] = useState('Create New Area');
    let history = useHistory();  
   // console.log(props.regionData)
    //console.log(props.countryData)
//  const regionOptions = props.regionData.regionList.map((list, idr)=>{ return {
//      key: idr,
//      text: list.regionName,
//      value:list.regionId
//  }
//   })
//  const currencyOptions = props.countryData.countryList.map((list, idr)=>{ return {
//      key: 1,
//      text: 'currency',
//      value:'s'
//  }
//  })
//console.log('country data', props.countryData)
const countryList = props.countryData &&  props.countryData.countryList.map((item, cid)=>{
  return  { 
     key: cid,
     text: item.countryName,
     value: item.countryCode,
  }
 }
 )

 

const languageOptions = props.countryData &&  props.countryData.languageList.map((list, idr)=>{ return {
  key: idr,
  text: list.languageName,
  value:list.languageId,
  disabled: readOnlyToggle
  }
})

const cityList = cityMaster && cityMaster.cityList.map((item, cid)=>{
  return  { 
     key: cid,
     text: item.cityName,
     value: item.cityCode,
  }
 }
 )
 const stateList = stateMaster && stateMaster.stateList.map((state,idc)=> { 
  return  {
  key: idc,
  text: state.stateName,
  value: state.stateCode,
}})

     useEffect (() =>{ 
            if(props.state){
            //  console.log('city props-state',props.state )
              const data = props.state
             !countryName &&  setCountryName(data.countryName);
             !stateName && setStateName(data.stateName);
             (!stateName || !cityName) &&  setCityName(data.cityName);
             !stateName && setCityCode(data.cityCode);
             (!cityName || !areaName) && setAreaName(data.areaName);

             if(props.button === 'view') {
              setReadOnlyToggle(true);
              setHeaderMessage('View Area');    
         }

           if(props.button === 'edit' ||  props.button === 'add') {
             setReadOnlyToggle(false);
             props.button === 'edit' && setHeaderMessage('Edit Area');
             props.button === 'add' && setHeaderMessage('Add Area');
           
         }
           
         if(props.countryData){  
           !countryName &&
           props.countryData.countryList.forEach(cn => {
          //console.log(cn) 
             if(cn.countryName === data.countryName) {
              setCountryCode(cn.countryCode); 
             }       
          }); 
          if(stateMaster){ 
            (props.button === 'edit' || props.button === 'add') &&
            stateMaster.stateList.forEach(cn => {
         //  console.log(cn) 
              if(cn.stateName === data.stateName) {
               setStateCode(cn.stateCode); 
              }       
           }); } 
     }     
     }
     },[props.state, props.countryData, stateMaster])

    //'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/GetAreaDetails?AreaCode=1'
     useEffect(()=>{
      if(ctx.APIToken && props.state) {
      
        axios
        .get(
         `${process.env.REACT_APP_BASE_API_URL}Master/GetAreaDetails`,{
          params:{
            ...(props.state && {AreaCode:props.state.areaCode})
          },
          headers: { Authorization: `Bearer ${ctx.APIToken}`}
         }
        )
       .then((response) => {
      //   setLoaderToggle(false);
       setAreaDetails(response.data.response); 
       
     //console.log('spoken language id -----',response.data.response.areaDetails.spokenLanguageId)
    
       }).catch((error)=>{
         //console.log(error);
       }) 
      }
      },[props.state])

      useEffect(()=>{
        if(areaDetails){
          setSpokenLanguageId(areaDetails.areaDetails.spokenLanguageId);
          setAreaCode(areaDetails.areaDetails.areaCode);
          setCityName(areaDetails.areaDetails.cityName);
          setCityCode(areaDetails.areaDetails.cityCode);
          setPropStatus(areaDetails.areaDetails.status);
        setCountryName(areaDetails.areaDetails.countryName);
       setCountryCode(areaDetails.areaDetails.countryCode); 
        setAreaShortName(areaDetails.areaDetails.shortName);  
       setTelephoneCode(areaDetails.areaDetails.telephoneCode);
       setZipCode(areaDetails.areaDetails.zipCode);
      setLatitude(areaDetails.areaDetails.latitude);
      setLongitude(areaDetails.areaDetails.longitude); 
      //console.log('area details',areaDetails.areaDetails); 
        }

      },[areaDetails])

      // State List
  useEffect(()=>{
    ctx.APIToken &&
    axios
    .get(
     `${process.env.REACT_APP_BASE_API_URL}Master/GetStateMasterList`,{
       params:{
        Status:2,
        CurrentPageNumber:1,
        NoOfRowsPerPage:50,
        ...(countryCode && {CountryCode:countryCode}),
        //StateName:stateName
       }, 
      headers: { Authorization: `Bearer ${ctx.APIToken}`}
     }
    )
   .then((response) => {
  //   setLoaderToggle(false);
    const data = response.data.response;
    //console.log('state list',data)
     setStateMaster(data);
     setCityMaster('');
   }).catch((error)=>{
     //console.log(error);
   }) 
  },[countryCode])
  // for City List
  useEffect(()=>{
    ctx.APIToken &&
    axios
    .get(
     `${process.env.REACT_APP_BASE_API_URL}Master/GetCityMasterList`,{
       params:{
         ...(stateCode && {StateCode:stateCode})
       },
      headers: { Authorization: `Bearer ${ctx.APIToken}`}
     }
    )
    .then((response) => {
    //   setLoaderToggle(false);
      const data = response.data.response;
     //console.log('City master List ',data)
     setCityMaster(data);  
    }).catch((error)=>{
     //console.log(error);
    }) 
   },[stateCode])
 
    //https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Master/SaveArea
    const saveHandler = () =>{
      const upload =    {
       "AreaCode": areaCode,
      "AreaName": areaName,
      "ShortName": areaShortName,
      "CityCode": cityCode,
      "SpokenLanguageId": spokenLanguageId,
      "ZipCode": zipCode,
      "TelephoneCode": telephoneCode,
      "Latitude": latitude,
      "Longitude": longitude,
      "Status": propStatus,
     }
    // console.log('uoload ',upload);
      axios.post(`${process.env.REACT_APP_BASE_API_URL}Master/SaveArea`,
      {
        "AreaCode": areaCode,
      "AreaName": areaName,
      "ShortName": areaShortName,
      "CityCode": cityCode,
      "SpokenLanguageId": spokenLanguageId,
      "ZipCode": zipCode,
      "TelephoneCode": telephoneCode,
      "Latitude": latitude,
      "Longitude": longitude,
      "Status": propStatus,
     },
       {headers: { Authorization: `Bearer ${ctx.APIToken}`}},  
     )
    .then((response)=>{ 
      if(response.data.message === 'Success')
      history.goBack();
         else alert(response.data.message)
    })  
  }
    return( 
        <Container>
            <Grid>
            <Grid.Row columns={1} >
                     <Grid.Column textAlign="center" style={{marginTop:'80px', paddingTop:'10px'}}>
                         <h3>{headerMessage}</h3>
                     </Grid.Column> 
            </Grid.Row>
            <Grid.Row>
                 <Grid.Column width={10}>
                     <Form inline>
                     <Form.Field inline >  
                         <label style={{width:'105px'}}> Country Name *</label>
                         {readOnlyToggle && <Input 
                         readOnly
                         style={{width:'550px'}}
                         value={countryName}
                         /> ||
                         <Dropdown 
                         placeholder='Country' 
                         selectOnBlur={false}
                         search  
                         options={countryList}
                         selection 
                         value={countryCode} 
                         onChange={(e,data)=>setCountryCode(data.value)}
                         /> 

                         }
                     </Form.Field>

                     </Form>
                 </Grid.Column>
                 <Grid.Column style={{ paddingTop:'5px'}} width={5}>
                     <Form>
                     <Form.Group inline>
                 <label  style={{marginRight:'20px'}}>Status *</label> 
                { readOnlyToggle &&
                <input type="radio" id="active" name="prop-status" value="1" checked ={propStatus===1} 
                   /> ||
                   <input type="radio" id="active" name="prop-status" value="1" checked ={propStatus===1}
                   onChange={(e)=>{setPropStatus(Number(e.target.value)); //console.log(e.target.value);
                  }}
                   /> 
                   }
                 <label>Active</label>
                 { readOnlyToggle &&
                 <input type="radio" id="passive" name="prop-status" value="0" 
                    checked ={propStatus===0} 
                 /> ||
                 <input type="radio" id="passive" name="prop-status" value="0" 
                 checked ={propStatus===0}
                 onChange={(e)=>{setPropStatus(Number(e.target.value)); 
                  // console.log(e.target.value)
                  }}
              />
                 }
                 <label>Passive</label>
                </Form.Group>
                     </Form>
                
                 </Grid.Column>
               </Grid.Row>
               <Grid.Row>
                   <Grid.Column width={5} >
                   <Form inline>
                          <Form.Field inline>  
                              <label style={{width:'105px'}}>State Name * </label>
                             { readOnlyToggle && <Input
                              readOnly 
                              value={stateName}
                              style={{width:'197px'}}/>
                              ||
                              <Dropdown 
                         placeholder='State' 
                         selectOnBlur={false}
                         search  
                         options={stateList}
                         selection 
                         value={stateCode} 
                         onChange={(e,data)=>setStateCode(data.value)}
                         /> 
                         }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={5}>
                   <Form inline>
                          <Form.Field inline>  
                              <label style={{width:'100px'}}>City Name * </label>
                           { readOnlyToggle &&  <Input 
                              readOnly
                               value={cityName}
                              style={{width:'197px'}}/> ||
                               <Dropdown 
                         placeholder='City' 
                         selectOnBlur={false}
                         search  
                         options={cityList}
                         selection 
                         value={cityCode} 
                         onChange={(e,data)=>setCityCode(data.value)}
                         /> }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                 </Grid.Row> 
               <Grid.Row>
                   <Grid.Column width={5} >
                   <Form inline>
                          <Form.Field inline>  
                              <label style={{width:'105px'}}>Area Name * </label>
                             { readOnlyToggle &&  <Input 
                               readOnly
                              value={areaName} 
                              style={{width:'197px'}}/> ||
                              <Input  
                             value={areaName}
                             onChange={(e,data)=>setAreaName(data.value)}
                             style={{width:'197px'}}/>
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={5}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{width:'100px'}}>Short Name</label>
                              { readOnlyToggle &&<Input
                              readOnly
                              style={{width:'197px'}}
                              value={areaShortName} 
                              /> ||
                              <Input
                              style={{width:'197px'}}
                              value={areaShortName}
                              onChange={(e,data)=>setAreaShortName(data.value)}
                              />
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                 </Grid.Row> 
                 <Grid.Row>
                   <Grid.Column width={5}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{width:'105px'}}>Spoken Language</label>
                              <Dropdown 
                              placeholder='Language' 
                              selectOnBlur={false}
                              search  
                              options={languageOptions}
                              selection 
                              value={spokenLanguageId} 
                              onChange={(e,data)=>setSpokenLanguageId(data.value)}
                              /> 
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={5}>
                     
                   </Grid.Column>  
                 </Grid.Row> 
                 
                 <Grid.Row>
                   <Grid.Column width={5}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{width:'105px'}}>Zip Code</label>
                              {  readOnlyToggle && <Input 
                              readOnly
                                value={zipCode}
                              style={{width:'197px'}}
                              
                              /> ||
                              <Input 
                                value={zipCode}
                              style={{width:'197px'}}
                              onChange={(e,data)=>setZipCode(data.value)}
                              /> }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={5}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{width:'100px'}}>Telephone Code</label>
                              {readOnlyToggle && <Input 
                              readOnly
                              value={telephoneCode}
                              style={{width:'197px'}} 
                              /> ||
                              <Input 
                              value={telephoneCode}
                              style={{width:'197px'}}
                              onChange={(e,data)=>setTelephoneCode(data.value)}
                              />}
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                 </Grid.Row> 
                 <Grid.Row>
                   <Grid.Column width={5}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{width:'105px'}}>Latitude </label>
                              { readOnlyToggle && <Input
                              readOnly
                               value={latitude}
                               style={{width:'197px'}} 
                               /> || <Input
                               value={latitude}
                               style={{width:'197px'}}
                               onChange={(e,data)=>setLatitude(data.value)}
                               /> }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                   <Grid.Column width={6}>
                   <Form inline>
                          <Form.Field inline >  
                              <label style={{width:'100px'}}>Longitude</label>
                              { readOnlyToggle && <Input 
                              readOnly
                              value={longitude}
                              style={{width:'197px'}}
                                
                              /> || 
                              <Input 
                              value={longitude}
                              style={{width:'197px'}}
                              onChange={(e,data)=>setLongitude(data.value)}
                              /> 
                              }
                          </Form.Field> 
                     </Form>
                   </Grid.Column>  
                 </Grid.Row> 
                 <Grid.Row>
                     <Grid.Column width={3} floated="left">
                       <Button onClick={()=>{history.goBack();}}> Back</Button>
                     </Grid.Column>
                     <Grid.Column width={3} floated="right">
                      {!readOnlyToggle && <Button onClick={saveHandler}> Save</Button>}
                     </Grid.Column>
                 </Grid.Row>
            </Grid>
        </Container>
    )
}

export default AddNewArea;