import { useState,useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import {Form,Input, Grid,Container,Button} from 'semantic-ui-react';
import ContextData from "../store/context-data";
import { useHistory} from 'react-router-dom';
import generator  from "generate-password";

const DefaultUsers = (props) =>{ 
    const ctx = useContext(ContextData);
    const [orgAddProperty] = useState(localStorage.getItem('orgAddProperty') ? JSON.parse(localStorage.getItem('orgAddProperty')):'');
    const [editData] = useState(localStorage.getItem('editData') ? JSON.parse(localStorage.getItem('editData')):'')
    const [editProperty] = useState(localStorage.getItem('editProperty') ? JSON.parse(localStorage.getItem('editProperty')):'');

    const [localStrProp] = useState(sessionStorage.getItem('newProperty')?JSON.parse(sessionStorage.getItem('newProperty')):'');
    const [defaultUsers] =  useState((localStorage.getItem('defaultUsers') && JSON.parse( localStorage.getItem('defaultUsers')))||'');
    const [propertyName] = useState( (localStrProp) && localStrProp.PropertyDetails.ObjFormProperty.PropertyName)
    const sessionOrgDetails = sessionStorage.getItem('SessionOrgDetails') ? JSON.parse(sessionStorage.getItem('SessionOrgDetails')):'';
    const [orgName,setOrgName] = useState((sessionOrgDetails && sessionOrgDetails.OrganizationDetails.OrganizationName) || (orgAddProperty && orgAddProperty.organizationDetails.organizationName) ||'');
    const[suffix,setSuffix] = useState(); 
    const[lucidAdminPassword,setLucidAdminPassword] =useState();
    const[adminPassword,setAdminPassword] =useState();
    const[userPassword,setUserPassword] =useState();
   // console.log(defaultUsers);
     
   //const [password,setPassword] = useState();

    // console.log('password generator ', password);
    let history = useHistory();
    //console.log('Default Users from local storage', defaultUsers);

    const [filteredSuffix,setFilteredSuffix] = useState(); 
    const [filteredPassword, setFilteredPassword] = useState();

//console.log(filteredPassword);
  const [user1] = useState(filteredSuffix && {userId:filteredSuffix[0], Password:filteredPassword[0]})
  const [user2] = useState(filteredSuffix && {userId:filteredSuffix[1], Password:filteredPassword[1]})
  const [user3] = useState(filteredSuffix && {userId:filteredSuffix[2], Password:filteredPassword[2]})
    //const localDefa
    const goBack = () =>{
       
         history.goBack();
         setTimeout(()=>   window.scrollTo(0, 0), 100)
    
      } 
      useEffect(()=>{
        (defaultUsers && !orgAddProperty && !editProperty) &&
         setFilteredSuffix(defaultUsers.map((users,id)=>{ 
          var x = users.UserId;
          return x.split('@').pop();  
         }).filter((item, id)=> setSuffix(item) ));
         defaultUsers &&
         setFilteredPassword(defaultUsers && defaultUsers.map((users,id)=>{  
         return users.Password;  
         }).filter((item)=>item) 
         )
         if(editProperty){
           setOrgName(editProperty.organizationDetails.organizationName);
         }

     },[defaultUsers,orgAddProperty, editProperty])

     useEffect(()=>{
      !suffix &&
      setLucidAdminPassword(generator.generate({
        length: 10,
        numbers: true,
        lowercase:true,
        uppercase:true,
        strict:true,
       // symbols: true,
        exclude:'^,;,"'
      }));
      !suffix &&
      setAdminPassword(generator.generate({
        length: 10,
        numbers: true,
        lowercase:true,
        uppercase:true,
        strict:true,
       // symbols: true,
        exclude:'^,",;'
      }));
      suffix &&
      setUserPassword(`${suffix}123`);

     },[suffix]);
 
 
    const formHandler = () =>{
          if(!editData && !editProperty) {
          const DefaultUsers=[
           !orgAddProperty &&  {  
              "UserId":suffix?`lucidadmin@${suffix}`:user1.userId ,
              "Password":lucidAdminPassword? lucidAdminPassword:user1.Password 
           },
           !orgAddProperty &&   { 
            "UserId": suffix?`admin@${suffix}`:user2.userId,
            "Password": adminPassword?adminPassword:user2.Password 
           },
           {
            "UserId":suffix?`user@${suffix}`:user3.userId,
            "Password": userPassword?userPassword:user2.Password 
           }, 
         ];

       props.defaultUsersHandler(DefaultUsers);
       localStorage.setItem('defaultUsers', JSON.stringify(DefaultUsers));  
      }
      
      if(editProperty) {
        const DefaultUsers=[ 
          {
           "UserId":suffix?`user@${suffix}`:user3.userId,
           "Password": userPassword?userPassword:user2.Password 
          }, 
       ];
     props.defaultUsersHandler(DefaultUsers);
     localStorage.setItem('defaultUsers', JSON.stringify(DefaultUsers));  

      }


    }
    
    const formHandlerAddProp = () =>{
    
    const DefaultUsers=[ 
       {
        "UserId":suffix?`user@${suffix}`:user3.userId,
        "Password": userPassword?userPassword:user2.Password 
       }, 
  ];
  props.defaultUsersHandler(DefaultUsers);
  localStorage.setItem('defaultUsers', JSON.stringify(DefaultUsers));  

}


    return( 
      
      <>   <Container>
           <Grid style={{marginTop:'100px'}}  stackable> 
        
             <Grid.Row textAlign="center">  
              <h3> Default Users</h3> 
            </Grid.Row>  
               
            <Grid.Row columns={1}> 
                   <Grid.Column width={10}>
                   <Form>
                   <Form.Group inline >
                   <Form.Field width={3}>
                   <label>Organisation Name </label>
                   </Form.Field>
                     <Form.Field  width={10}> 
                       <Input 
                         control={Input} 
                         defaultValue={orgName} 
                     
                         readOnly 
                       />
                     </Form.Field>
                     </Form.Group> 

                     <Form.Group inline >
                       <Form.Field width={3} >
                   <label >Property Name * </label>
                       </Form.Field>
                     <Form.Field  width={10}> 
                       <Input 
                         control={Input} 
                         defaultValue={ctx.propertyName || propertyName} 
                       
                         readOnly 
                       />
                     </Form.Field>
                     </Form.Group>  
                 
                    </Form>    
                    </Grid.Column>
                   
            </Grid.Row> 
                 
            <Grid.Row columns={2}>

                    <Grid.Column width={5}>{
                                        /*First Column*/ 
                                    }         
                   <Form>
                     <Form.Group inline>
                       <label style={{width:'79px'}}></label>
                       <Form.Input
                         placeholder="[User ID Suffix]"
                         onChange={ (e)=>{
                                   setSuffix(e.target.value);}
                                  
                                  }
                        
                       />  
                      </Form.Group> { (!orgAddProperty && !editProperty) &&
                      <Form.Group inline>
                       <label>  lucidadmin@</label>
                       <Form.Input
                         readOnly
                        // value={suffix}
                         defaultValue={ (suffix && suffix) || (user1 && user1.userId)} 
                       />  
                      </Form.Group>  }
                      { (!orgAddProperty && !editProperty) &&
                      <Form.Group inline>
                       <label style={{marginRight:'40px'}}>  admin@</label>
                       <Form.Input 
                         readOnly
                         value={suffix}
                         defaultValue= {user2 && user2.userId} 
                       />  
                      </Form.Group> 
                      }
                      <Form.Group inline>
                       <label style={{marginRight:'55px'}}>  user@</label>
                       <Form.Input
                         readOnly
                         value={suffix}
                         defaultValue= {user3 && user3.userId} 
                       />  
                      </Form.Group>   
                   </Form> 
                    </Grid.Column> 
                    <Grid.Column width={7}>{
                                        /*Second Column*/ 
                                    }
                       <Form>   
                        

                         <Form.Group style={{marginTop:'30px'}}>
                       {(!orgAddProperty && !editProperty) &&
                       <Form.Input
                        label='Password'
                        onChange={(e)=>setLucidAdminPassword(e.target.value)}
                        defaultValue={(lucidAdminPassword && lucidAdminPassword ) || (user1 && user1.Password)} 
                       />  
                       }
                        
                        </Form.Group>
                        
                        <Form.Group>
                          { (!orgAddProperty && !editProperty) &&
                       <Form.Input 
                        onChange={(e)=>setAdminPassword(e.target.value)}
                        defaultValue={(adminPassword && adminPassword ) || (user2 && user2.Password)} 
                      /> 
                      }
                        </Form.Group>
                        {  editProperty && <Grid.Row><div style={{height:'23px', width:'180px'}}></div></Grid.Row>}
                        <Form.Group>
                       
                       <Form.Input 
                        defaultValue={(userPassword && userPassword ) || (user3 && user3.Password)}
                           onChange={(e)=>setUserPassword(e.target.value)}
                      />
                     
                      </Form.Group>
                      </Form>          

                    </Grid.Column>
                  </Grid.Row> 
                   <Grid.Row >
                    <Grid.Column width={4}>
                     <Button 
                     color='blue'
                     onClick={goBack}
                     > Go Back</Button>
                    </Grid.Column>
                   <Grid.Column width={4} floated='right'>
                   { (defaultUsers && !orgAddProperty && (<Link to='/LicensePreview'> <Button color='blue' onClick={formHandler}>Next </Button></Link>)) || 
                    (editData && <Link to='/LicensePreview'> <Button color='blue'>Next </Button></Link>) ||
                    ((suffix && !orgAddProperty) && (<Link to='/LicensePreview'> <Button color='blue' onClick={formHandler}>Next </Button></Link>)) ||
                    (orgAddProperty && <Link to='/LicensePreview'> <Button color='blue' onClick={formHandlerAddProp}>Next </Button></Link>)
                    } 

                   </Grid.Column>
                  
               </Grid.Row> 
               </Grid>  
               <div style={{height:'100px'}}>

               </div>
               </Container>
      </>
    )
   }

   export default DefaultUsers;