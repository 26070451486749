import { useState, useEffect,useContext } from "react";
import axios from "axios";
import { Container, Form, Grid, Pagination,Button, Dropdown,Table, TableHeader, TableHeaderCell } from "semantic-ui-react";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import ContextData from '../../store/context-data';

const PropModuleReport = () =>{
    const ctx = useContext(ContextData);
    const [userRightsDashboard, setUserRightsDashboard] = useState(sessionStorage.getItem('userRights')?JSON.parse(sessionStorage.getItem('userRights')):[])
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [orgOption, setOrgOption] = useState(1);
    const [currentpagenumber,setCurrentPageNumber] =useState(1);
    const [noofrows,setNoOfRows] =useState(50);
    const [orgData, setOrgData] = useState('');
    const [pageData, setPageData] = useState();
    const [countryData,setCountryData] = useState();
    const [selectedCountries, setSelectedCountries] = useState('');
    const [statesData, setStatesData] = useState();
    const [selectedStates, setSelectedStates] = useState('');
    const [citiessData, setCitiesData] = useState();
    const [selectedCities, setSelectedCities] = useState('');
    const [moduleData,setModulesData] = useState();
    const [selectedModules, setSelectedModules] = useState([]);
    const [inputValid,setInputValid] = useState(true);
    const [viewReportToggle, setViewReportToggle] = useState(false);
    const [inputTouched, setInputTouched] = useState(false);
    const [alert, setAlert] = useState('')
    const [SendEmail,setSendEmail] = useState();
    const [View, setView] = useState();
    const [Export, setExport] = useState();
    const [userType,setUserType] = useState();

  
    useEffect(()=>{
      pageData && pageData.response.pageControlRights.forEach(item =>{
     //    console.log('hey me ', item)
        
         item.controlName === 'Export' && setExport(item.visible)
         item.controlName === 'View' && setView(item.visible)
         item.controlName === 'Send Email' && setSendEmail(item.visible)
      })
      userRightsDashboard && userRightsDashboard.forEach(item=>{
         item.userType && setUserType(item)
      }) 
      
    },[pageData])
    
     const countryOptions = countryData && countryData.countries.map((item,idr)=>{
       return(
       {
         key: idr,
         text:item.countryName,
         value:item.countryCode,
       }
       )
     })
  
    const stateOptions = statesData && statesData.states.map((item,idr)=>{
      return(
      {
        key: idr,
        text:item.stateName,
        value:item.stateCode,
      }
      )
    })
    
    const cityOptions = citiessData && citiessData.cities.map((item,idr)=>{
      return(
      {
        key: idr,
        text:item.cityName,
        value:item.cityCode,
      }
      )
    })

    const moduleOptions = pageData && pageData.response.moduleList.map((item,idr)=>{
        return(
        {
          key: idr,
          text:item.moduleName,
          value:item.moduleCode,
        }
        )
      })

    //  curl --location --request GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Reports/GetPropertyModuleDetails?FromDate=01-Jan-2022&ToDate=06-Jan-2022&Status=1&CurrentPageNumber=1&NoOfRowsPerPage=20&CountryCodes=1,2&StateCodes=1,10&CityCodes=1,2,3&ModuleCodes=POS,OODR'
   //  useEffect(()=>{  
   //        
   //       axios.get(  `${process.env.REACT_APP_LUCID_API_URL}GetPropertyModuleDetails`,{ 
   //       headers: { Authorization: `Bearer ${ctx.APIToken}`}, 
   //       }).then((response) => {
   //        //   setLoaderToggle(false);
   //          const data = response.data;
   //          console.log(data)
   //           setModulesData(data);  
   //         }).catch((error)=>{
   //           //console.log(error);
   //         })     
   //    },[])
   
  
     
      useEffect(()=>{ 
       
       selectedCountries && 
        axios.get(  `${process.env.REACT_APP_LUCID_API_URL}GetStateList`,{
        params:{CountryCodes:selectedCountries && selectedCountries.toString()}, 
        headers: { Authorization: `Bearer ${ctx.APIToken}`}, 
        }).then((response) => {
         //   setLoaderToggle(false);
           const data = response.data;
          // console.log(data)
            setStatesData(data);  
          }).catch((error)=>{
            //console.log(error);
          })    
          setSelectedStates('') 
     },[selectedCountries])

     useEffect(()=>{ 
       
      selectedStates && 
     axios.get(  `${process.env.REACT_APP_LUCID_API_URL}GetCityList`,{
     params:{StateCodes:selectedStates && selectedStates.toString()}, 
     headers: { Authorization: `Bearer ${ctx.APIToken}`}, 
     }).then((response) => {
      //   setLoaderToggle(false);
        const data = response.data;
        //console.log(data)
         setCitiesData(data);  
       }).catch((error)=>{
         //console.log(error);
       })
       if(!selectedStates){
        setCitiesData('');
        setSelectedCities('')
        setSelectedStates('')
       
      }       
  },[selectedStates])
    
    useEffect(()=>{
      //console.log('i mehre ')
      axios.get(  `${process.env.REACT_APP_LUCID_API_URL}GetCountryList`,{
        headers: { Authorization: `Bearer ${ctx.APIToken}`}, 
        }).then((response) => {
         //   setLoaderToggle(false);
           const data = response.data;
           //console.log(data)
            setCountryData(data);  
          }).catch((error)=>{
            //console.log(error);
          })     
    },[])
//curl --location --request GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Reports/GetPropertyModuleDetailsPageDetails'
    useEffect(()=>{ 
           axios.get( `${process.env.REACT_APP_BASE_API_URL}Reports/GetPropertyModuleDetailsPageDetails`,{
           headers: { Authorization: `Bearer ${ctx.APIToken}`}, 
           }).then((response) => {
            //   setLoaderToggle(false);
              const data = response.data;
              //console.log(data)
               setPageData(data);  
             }).catch((error)=>{
               //console.log(error);
             })     
    },[])

    const clearHandler = () =>{ 
      setOrgData(''); 
      setSelectedModules([])
    }
//curl --location --request GET 'https://dev.lucidits.com/LUCIDLicenseManagementAPI/V1/Reports/GetPropertyModuleDetails?FromDate=01-Jan-2022&ToDate=06-Jan-2022&Status=1&CurrentPageNumber=1&NoOfRowsPerPage=20&CountryCodes=1,2&StateCodes=1,10&CityCodes=1,2,3&ModuleCodes=POS,OODR'
    const viewReportHandler = () =>{
        
      if(inputTouched && selectedModules.length === 0 || selectedModules.length === 0) 
      setAlert('Please select Modules');
  
      selectedModules.length != 0 &&
      axios
      .get(
       `${process.env.REACT_APP_BASE_API_URL}Reports/GetPropertyModuleDetails`,{ 
        params:{
          ...(startDate && {FromDate: startDate}) ,
          ...(endDate && {ToDate: endDate}),
          Status:orgOption,
          CurrentPageNumber:currentpagenumber,
          NoOfRowsPerPage:noofrows,
          ...(selectedCountries && {CountryCodes:selectedCountries.toString()}), 
          ...(selectedStates && {StateCodes:selectedStates.toString()}),
          ...(selectedCities && {CityCodes:selectedCities.toString()}),
          ...(selectedModules && {ModuleCodes:selectedModules.toString()})
        }, 
        headers: { Authorization: `Bearer ${ctx.APIToken}`},
       "Content-Type": "application/json"
       }
      )
     .then((response) => {
    //   setLoaderToggle(false);
      const data = response.data;
      //console.log(data)
       setOrgData(data);  
     }).catch((error)=>{
       //console.log(error);
     }) 
     setCurrentPageNumber(1);
     setViewReportToggle(true);
    }

    useEffect(()=>{
      viewReportToggle &&
      axios
      .get(
       `${process.env.REACT_APP_BASE_API_URL}Reports/GetPropertyModuleDetails`,{ 
        params:{
          ...(startDate && {FromDate: startDate}) ,
          ...(endDate && {ToDate: endDate}),
          Status:orgOption,
          CurrentPageNumber:currentpagenumber,
          NoOfRowsPerPage:noofrows,
          ...(selectedCountries && {CountryCodes:selectedCountries.toString()}), 
          ...(selectedStates && {StateCodes:selectedStates.toString()}),
          ...(selectedCities && {CityCodes:selectedCities.toString()}),
          ...(selectedModules && {ModuleCodes:selectedModules.toString()})
        }, 
        headers: { Authorization: `Bearer ${ctx.APIToken}`},
       "Content-Type": "application/json"
       }
      )
     .then((response) => {
    //   setLoaderToggle(false);
      const data = response.data;
      //console.log(data)
       setOrgData(data);  
     }).catch((error)=>{
       //console.log(error);
     }) 
    },[currentpagenumber])

    return (
         <Container>
             <Grid >
                 <Grid.Row columns={1} >
                     <Grid.Column textAlign="center" style={{marginTop:'70px', paddingTop:'10px'}}>
                         <h3>Property Module Detail Report</h3>
                         
                     </Grid.Column> 
                 </Grid.Row>
                 <Grid style={{border:'solid thin #F4EFEE', margin:'5px 0px', paddingBottom:'10px'}} className="reports">
                 <Grid.Row columns={4} >
                    <Grid.Column width={2}>
                        <div  style={{float:'left', marginTop:'5px', paddingRight:'5px' }}>  Created Date  </div>
                    </Grid.Column>
                    <Grid.Column width={5}
                    >
                    <div  style={{ float:'left',width:'110px'}}>
                             <Form>
                             
                             <DatePicker wrapperClassName="datePicker" selected={startDate} onChange={(date) => setStartDate(date)} />
                             </Form>
                        
                        </div>
                         <div  style={{float:'left', marginTop:'5px', paddingRight:'5px' , marginLeft:'5px'}}> To </div>
                         <div  style={{float:'left', width:'110px'}}>
                         <Form>
                         <DatePicker wrapperClassName="datePicker" selected={endDate} onChange={(date) => setEndDate(date)} />
                          </Form>
                        </div>
                    </Grid.Column> 
                    
                    <Grid.Column width={2} style={{paddingTop:'5px',paddingLeft:'50px'}} floated="center">
                    <Form.Group inline key='orgstatus'>
                 <label className=''>  Status </label>
                   </Form.Group>
                    </Grid.Column> 
                    <Grid.Column width={5} style={{ paddingTop:'5px'}} floated="left" >
                    <Form> 
                <Form.Group inline key='orgstatus'> 
                 <input 
                  type="radio" id="active" name="org-status" value='1' checked ={orgOption===1}
                   onChange={(e)=>{ 
                          setOrgOption(Number(e.target.value));
                         // console.log(e.target.value);
                        
                        }}
                    />
                 <label>Active</label>
                 <input type="radio" id="passive" name="org-status" value='0'
                    checked ={orgOption===0}
                    onChange={(e)=>{
                      setOrgOption(Number(e.target.value)); 
                        // console.log(e.target.value);   
                        }}
                   />
                   <label >Passive</label>
                   <input type="radio" id="both" name="org-status" value='2'
                    checked ={orgOption===2}
                    onChange={(e)=>{
                      setOrgOption(Number(e.target.value)); 
                        // console.log(e.target.value);
                         
                        }}
                 />
                 <label >Both</label> 
                </Form.Group> 
              </Form>
                    </Grid.Column>
                 </Grid.Row>
                 <Grid.Row colums={4}>
                   <Grid.Column
                   width={2} 
                   style={{paddingTop:'10px'}}>
                   Country 
                   </Grid.Column> 

                   <Grid.Column  width={5} 
                    >
                   <div>
                   <Dropdown
                       placeholder='Country'
                       fluid
                       multiple
                       search
                       selection
                       options={(countryOptions && countryOptions) || null}
                       onChange={(e,data)=>{ setSelectedCountries(data.value) 
                        if(data.value.length ===0)
                        {
                          setSelectedCities('');
                          setSelectedStates(''); 
                         } 
                      }}
                     /> 
                   </div>  
                   </Grid.Column>

                   <Grid.Column floated="center"
                   width={2} 
                   style={{paddingTop:'10px',paddingLeft:'50px' }}>
                   State 
                   </Grid.Column> 
                 
                   <Grid.Column width={6}>
                   <Dropdown
                       placeholder='State'
                       fluid
                       multiple
                       search
                       selection 
                       options={(stateOptions && stateOptions) || null}
                       onChange={(e,data)=>{ 
                         setSelectedStates(data.value);
                         if(data.value.length ===0)
                         {
                           setSelectedCities(''); 
                          } 
                        }
                      }
                     /> 
                   </Grid.Column> 
                 </Grid.Row>
                 <Grid.Row colums={2}> 
                 <Grid.Column
                   width={2} 
                   style={{paddingTop:'10px'}}>
                   City 
                   </Grid.Column> 
                   <Grid.Column 
                    width={5}>
                   <div>
                   <Dropdown
                       placeholder='City'
                       fluid
                       multiple
                       search
                       selection 
                       options={(cityOptions && cityOptions) || null}
                       onChange={(e,data)=> setSelectedCities(data.value)} 
                     />
                   </div>    
                    </Grid.Column>  
                    <Grid.Column floated="center"
                   width={2} 
                   style={{paddingTop:'10px',paddingLeft:'50px' }}>
                   Module * 
                   </Grid.Column> 
                   <Grid.Column 
                    width={6}
                   
                    >
                   <div>
                   <Dropdown
                       placeholder='Modules'
                       fluid
                       multiple
                       search
                       value={selectedModules}
                       selection 
                       options={(moduleOptions && moduleOptions) || null}
                       onChange={(e,data)=> {
                          setSelectedModules(()=>data.value); 
                          //console.log(data.value);
                          setInputTouched(true);
                          setAlert('');
                        }
                      } 
                     // onBlur={ (e,data)=> {data.value === undefined && setInputValid(false) || setInputValid(true)}}
                     />
                     <p style={inputValid===true?{color:'red', display:'none'}:{color:'red', display:'block'}}> Please select module</p>
                   </div>    
                    </Grid.Column> 
                 </Grid.Row>

                 <Grid.Row columns={2}>
                        <Grid.Column width={2} style={{ paddingTop:'10px'}}>
                       
                            Count <span style={{padding:'5px'}}> {(orgData.errorCode === 0 && selectedModules) && orgData.response.paginationDetail.noOfRowsPerPage }</span>
                 </Grid.Column>
                 <Grid.Column width={2} floated="left">          
               { orgData && orgData.response &&
            <Pagination 
               size='small' 
               boundaryRange={null}
               //defaultActivePage={1}
               activePage={currentpagenumber}
               color='blue'
               ellipsisItem={null}
               firstItem={null}
               lastItem={orgData.response.paginationDetail.totalPages}
               siblingRange={1}
               totalPages={orgData.response.paginationDetail.totalPages}
               onPageChange={(e,data)=>setCurrentPageNumber(data.activePage)}
             >  
             </Pagination>} 
                        </Grid.Column>
                        <Grid.Column width={7}floated="right">
                          <Button onClick={clearHandler} color='blue'>Clear</Button>
                         {(View || userType === 2) && <Button onClick={viewReportHandler}color='blue'>View Report</Button> ||
                          <Button 
                          color='blue'
                          disabled >View Report</Button>
                         } 
                          
                           <span style={{padding:'5px', fontWeight:'bold'}}> {(orgData.errorCode === 0 && selectedModules) && `Total Records : ` + orgData.response.paginationDetail.totalRecords }</span>
                           <p style={{color:'red', fontWeight:'bold'}}>{(selectedModules.length===0 && alert) && alert}</p>
                         </Grid.Column>
                        
                    </Grid.Row>
                </Grid>
                    <Grid.Row style={{marginBottom:'50px'}}>
                        <Table celled>
                        <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell >Sl.No</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Organization Name</Table.HeaderCell> 
                    <Table.HeaderCell width={2}>Property Name</Table.HeaderCell> 
                    <Table.HeaderCell>Address</Table.HeaderCell> 
                    <Table.HeaderCell width={2}>Created On</Table.HeaderCell> 
                    <Table.HeaderCell width={3}>Created By</Table.HeaderCell> 
                    <Table.HeaderCell>Status</Table.HeaderCell>  
                  </Table.Row>
                  </Table.Header>
                  <Table.Body>
   { (orgData.message==='Success' && orgData) && orgData.response.propertyList.map((item,idr)=>{
         
        return(
          <Table.Row key={idr}>
         <Table.Cell textAlign="center">{item.sNo}</Table.Cell>
         <Table.Cell>{item.organizationName}</Table.Cell>
         <Table.Cell>{item.propertyName}</Table.Cell>
         <Table.Cell width={3}>{item.addressLine1} {item.addressLine2} {item.addressLine3}</Table.Cell>
         <Table.Cell  textAlign="center">{item.createdOn}</Table.Cell>
         <Table.Cell  textAlign="center">{item.createdBy}</Table.Cell>
         <Table.Cell  textAlign="center">{item.status}</Table.Cell>
        </Table.Row>
        )
   })
   } 
 </Table.Body>                               
                        </Table>
                    </Grid.Row>
             </Grid>
         </Container>
    )

}

export default PropModuleReport;