import React, { useEffect } from 'react'
import { Button, Modal } from 'semantic-ui-react'

function modalReducer(state, action) {
  switch (action.type) {
    case 'close':
      return { open: false }
    case 'open':
      return { open: true, message: action.message }
    default:
      throw new Error('Unsupported action...')
  }
}

const ModalMessage = (props) => {
  const [state, dispatch] = React.useReducer(modalReducer, {
    open: props.open,
    message: props.message,
  })
  const { open, message } = state

  useEffect(() => {
    dispatch({ type: props.open ? 'open' : 'close', message: props.message })
  }, [props.open, props.message])

  return (
    <>
      <Modal
        size='mini'
        open={open}
        onClose={() => {
          dispatch({ type: 'close' })
          props.modalToggleProps()
        }}
      >
        <Modal.Content>
          {message}
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={() => {
            dispatch({ type: 'close' });
            props.modalToggleProps();
          }}>
            ok
          </Button>
        </Modal.Actions>
      </Modal >
    </>
  )
}

export default ModalMessage
